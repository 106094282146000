import { useState } from "react";
import { Api } from "../../services/Api.js";

import "./TelaLogin.css";
import Button from '@mui/material/Button';

export default function TelaLogin({ isOpen, setOpenTelaLogin, toggleteEstadoLogin}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function tratativaToggleteEstadoLogin(){
    setOpenTelaLogin();
    setEmail("")
    setPassword("")
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLoginClick();
    }
  };

  async function handleLoginClick() {
    if (email !== "" && password !== "") {
      const loginData = { email: email, password: password };
      try {
        const response = await Api.loginPost(loginData);
        console.log(response)
        sessionStorage.setItem("infoUsuarioToken", response.data.token);

        if (response.statusCode === undefined) {
          toggleteEstadoLogin()
          setOpenTelaLogin();
          setEmail("")
          setPassword("")
          } else {
            alert("Email ou senha incorreto.");
          }

      } catch (error) {
        console.error("Erro durante o login:", error);
      }
    } else {
      alert("Preencha todos os campos.")
  }

  }

  if(isOpen){
    return (
      <div className="container">
        <div className="container-login">
          <div className="wrap-login">
            <div className="close">
            <button className="close-icon" onClick={tratativaToggleteEstadoLogin}>
                X
              </button>
            </div>
            <form className="login-form">
              <span className="login-form-title">Bem Vindo!</span>

              <div className="wrap-input">
                <input
                  className={email !== "" ? "has-val input" : "input"}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyPress={(event) => handleKeyPress(event)}
                />
                <span className="focus-input" data-placeholder="Email"></span>
              </div>

              <div className="wrap-input">
                <input
                  className={password !== "" ? "has-val input" : "input"}
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={(event) => handleKeyPress(event)}
                />
                <span
                  className="focus-input"
                  data-placeholder="Password"
                ></span>
              </div>

              <div className="container-login-form-btn">
                <Button className="login-form-btn" variant="contained" onClick={handleLoginClick} sx={{ marginTop: '25px' }}>Login</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
  else {
    return null;
  }
}