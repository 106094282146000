import React, { useContext, useEffect, useState } from "react";

import { Box, Button, Card, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { InputComponent, SelectComponent } from './Inputs';
import { MapContextAdv } from "../Context/FuncoesMapa";
import './ColunaDados.css';
import { InputContext } from "../Context/ContextInputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

export function ColunaDados(props) {
    const [loading, setLoading] = useState(true);
    const [dataAdversidade, setDataAdversidade] = useState("");
    const [formData, setFormData] = useState({});
    const { colorsTestadas, setTestadas, testadas } = useContext(MapContextAdv)

    const {
        handleChangeNumero,
        handleChangeSelect,
        handleChangeRadioGroup,
        validateForm,
        selectValue,
        setNumero,
        numero,
        valueRadioGroup,
        errors
    } = useContext(InputContext)

    useEffect(() => {
        async function fetchDataAndSetState() {
            try {
                setLoading(true)
                const response = props.responseAdversidade;
                setDataAdversidade(response);
                setNumero(response.endereco.numero);
                setTestadas(response.testadas)
                setLoading(false)
            } catch (error) {
                console.error(error);
            }
        }

        fetchDataAndSetState();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {

            setFormData({
                numero: numero,
                tipo: selectValue,
                logradouro: valueRadioGroup,
                distritoCod: dataAdversidade?.lote?.distritoCod,
                setorCod: dataAdversidade?.lote?.setorCod,
                quadraCod: dataAdversidade?.lote?.quadraCod,
                loteCod: dataAdversidade?.lote?.loteCod,
                cpfCnpj: dataAdversidade?.proprietarios[0]?.cpfCnpj,
                nome: dataAdversidade?.endereco?.nome,
                bairro: dataAdversidade?.endereco?.bairro
            })
            console.log('Form data:', {
                numero: numero,
                tipo: selectValue,
                logradouro: parseInt(valueRadioGroup),
                distritoCod: dataAdversidade?.lote?.distritoCod,
                setorCod: dataAdversidade?.lote?.setorCod,
                quadraCod: dataAdversidade?.lote?.quadraCod,
                loteCod: dataAdversidade?.lote?.loteCod,
                cpfCnpj: dataAdversidade?.proprietarios[0]?.cpfCnpj,
                nome: dataAdversidade?.endereco?.nome,
                bairro: dataAdversidade?.endereco?.bairro
            });
        }
    };

    return (
        <div className="AdvEndDados-flex-column">
            <Card variant="outlined" style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '20px', alignItems: 'center' }}>
                <h5 className="AdvEndDados-h5">IDENTIFICAÇÃO DE ADVERSIDADE DE ENDEREÇO</h5>

                <Divider style={{ margin: '5px' }} />

                <form className="AdvEndDados-form">
                    <Box sx={{ border: '1px solid black', padding: '10px', flex: 1 }}>
                        <div className="AdvEndDados-flex-1">
                            <h5 className="AdvEndDados-margin-h5">Nº DE INSCRIÇÃO REDUZIDO: {dataAdversidade?.lote?.id} </h5>

                            <div className="AdvEndDados-form-row">
                                <InputComponent
                                    label="Distrito"
                                    id="Distrito"
                                    value={loading ? 'Carregando...' : dataAdversidade?.lote?.distritoCod}
                                    required
                                    readOnly
                                />
                                <InputComponent
                                    label="Setor"
                                    id="Setor"
                                    value={loading ? 'Carregando...' : dataAdversidade?.lote?.setorCod}
                                    required
                                    readOnly
                                />
                                <InputComponent
                                    label="Quadra"
                                    id="Quadra"
                                    value={loading ? 'Carregando...' : dataAdversidade?.lote?.quadraCod}
                                    required
                                    readOnly
                                />
                                <InputComponent
                                    label="Lote"
                                    id="Lote"
                                    value={loading ? 'Carregando...' : dataAdversidade?.lote?.loteCod}
                                    required
                                    readOnly
                                />
                            </div>

                            <div className="AdvEndDados-form-row">
                                <div style={{ flex: '1' }} className="AdvEndDados-form-group">
                                    <InputComponent
                                        label="Proprietário"
                                        id="Quadra"
                                        value={loading ? 'Carregando...' : dataAdversidade?.proprietarios[0]?.nome}
                                        required
                                        readOnly
                                    />
                                </div>
                                <div style={{ flex: '0.4' }} className="AdvEndDados-form-group">
                                    <InputComponent
                                        label="CPF/CNPJ"
                                        id="cpfCnpj"
                                        value={loading ? 'Carregando...' : dataAdversidade?.proprietarios[0]?.cpfCnpj}
                                        required
                                        readOnly
                                    />
                                </div>
                            </div>

                            <div style={{ flex: '0.4' }} className="AdvEndDados-form-group">
                                <InputComponent
                                    label="Logradouro Atual"
                                    id="Codigo"
                                    value={loading ? 'Carregando...' : dataAdversidade?.endereco?.nome}
                                    required
                                    readOnly
                                />
                            </div>

                            <div className="AdvEndDados-form-row">

                                <div style={{ flex: '0.4' }} className="AdvEndDados-form-group">
                                    <InputComponent
                                        label="Bairro"
                                        id="Bairro"
                                        value={loading ? 'Carregando...' : dataAdversidade?.endereco?.bairro}
                                        required
                                        readOnly
                                    />
                                </div>


                                <div style={{ flex: '0.4' }} className="AdvEndDados-form-group">
                                    <SelectComponent
                                        label="É lote encravado"
                                        id="select"
                                        value={selectValue}
                                        required
                                        onChange={handleChangeSelect}
                                        options={[
                                            { value: 'N', label: 'Não' },
                                            { value: 'S', label: 'Sim' },
                                        ]}
                                    />
                                    {errors.select && <span className="AdvEndDados-SpanInput">{errors.select}</span>}
                                </div>
                                <div style={{ flex: '0.4' }} className="AdvEndDados-form-group">
                                    <InputComponent
                                        label="Número"
                                        id="Número"
                                        value={numero}
                                        onChange={handleChangeNumero}
                                    />
                                    {errors.numero && <span className="AdvEndDados-SpanInput">{errors.numero}</span>}
                                </div>
                            </div>
                        </div>



                    </Box>

                    <FormControl style={{ marginLeft: 8 }}>
                        <FormLabel id="demo-controlled-radio-buttons-group">Selecione o novo logradouro para cadastro:</FormLabel>

                        <div className="AdvEndDados-flex-container">

                            <RadioGroup
                                style={{ display: 'flex', flexDirection: 'column', alignItems: 'tart' }}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={valueRadioGroup}
                                onClick={handleChangeRadioGroup}
                            >
                                {testadas.map((item, index) => {
                                    return (
                                        <div className="AdvEndDados-flex-row-center" key={item.id}>
                                            <div style={{ background: colorsTestadas[index] }} className="AdvEndDados-color-box"></div>
                                            <FormControlLabel
                                                value={item.id}
                                                control={<Radio />}
                                                label={`Testada id: ${item.id} - Logradouro ${item.logradouronome}`}
                                            />
                                        </div>
                                    );
                                })}
                            </RadioGroup>

                        </div>
                        {errors.valueRadioGroup && <span className="AdvEndDados-SpanInput">{errors.valueRadioGroup}</span>}
                    </FormControl>


                </form>
                <div className="advEnd-form-row">
                    <Button className="advEnd-button" onClick={handleSubmit} variant="contained" >
                        <FontAwesomeIcon icon={faFloppyDisk} />Salvar
                    </Button>
                </div>
            </Card >
        </div >
    )
}
