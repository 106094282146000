const InputComponent = ({ label, id, value, onChange, required = false, readOnly, errors }) => {
  return (
    <div className="ModalMesmoLote-form-group">
      <label htmlFor={id} className="ModalMesmoLote-label">
        {label}:
      </label>
      <input
        className="ModalMesmoLote-fixed"
        style={!required ? { background: 'white' } : {}}
        id={id}
        value={value}
        onChange={onChange}
        required={required}
        readOnly={readOnly}
      />
      {errors && (
        <span style={{ position: 'relative', textAlign: 'start', fontSize: '0.7rem', color: 'red' }}>{errors}</span>
      )}
    </div>
  );
};

const SelectComponent = ({ label, id, value, onChange, required = false, options, errors }) => {
  return (
    <div className="ModalMesmoLote-form-group">
      <label htmlFor={id} className="ModalMesmoLote-label">
        {label}:
      </label>
      <select
        className="ModalMesmoLote-select"
        id={id}
        value={value}
        onChange={onChange}
        required={required}
      >
        <option value="">Selecione:</option>
        {Array.isArray(options) ? (
          options.map((option, index) => (
            <option value={option.value} key={index}>{option.label}</option>
          ))
        ) : (
          <option value="">Nenhum opção disponível</option>
        )}
      </select>
      {errors && (
        <span style={{ position: 'relative', textAlign: 'start', fontSize: '0.7rem', color: 'red' }}>{errors}</span>
      )}
    </div>
  );
};

export { InputComponent, SelectComponent };

const TextAreaComponents = {
  InputComponent,
  SelectComponent,
};

export default TextAreaComponents;

