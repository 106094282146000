import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { faCheck, faEye, faEyeSlash, faFloppyDisk, faSpinner } from "@fortawesome/free-solid-svg-icons";

import '../ModalEditarUsuario/ModalEditarUsuario.css'
import { Api } from "../../../services/Api";

function ModalEditarUsuario({ onClose, atualizar, editUser }) {
    const [showPassword, setShowPassword] = useState(false);
    const [editarUsuario, setEditarUsuario] = useState({
        id: editUser.id,
        name: editUser.name,
        email: editUser.email,
        cpf: editUser.cpf,
        dataNasc: editUser.dataNasc || '',
        role: editUser.role
    });

    const options = [
        { value: "admin", label: "Administrador" },
        { value: "Assistente", label: "Assistente" },
        { value: "collector", label: "Coletor" },
    ];



    const [searching, setSearching] = useState(false);
    const [resultReturned, setResultReturned] = useState(false);


    const InputChange = (event) => {
        const name = event.target.name;
        const valor = event.target.value;
        if (name === 'cpf') {
            const valorFormatado = formataCpf(valor);
            setEditarUsuario(valores => ({ ...valores, [name]: valorFormatado }));
        } else {
            setEditarUsuario(valores => ({ ...valores, [name]: valor }));
        }
    };


    const submitForm = async (event) => {
        event.preventDefault();

        try {
            setSearching(true);
            if (editarUsuario.password.length < 6) {
                alert("A senha deve possuir no mínimo 6 caracteres ")
                setSearching(false);
                return;
            }
            if (editarUsuario.password !== editarUsuario.confirmPassword) {
                alert("As senhas não coincidem");
                setSearching(false);
                return;
            }
            const response = await Api.editUsers(editarUsuario);
            setResultReturned(true);
            setSearching(false);
            atualizar();
        } catch (error) {

        }
    };
    const formataCpf = (valor) => {
        valor = valor.replace(/\D/g, '');
        let valorFormatado = '';
        for (let i = 0; i < valor.length; i++) {
            if (i === 3 || i === 6) {
                valorFormatado += '.';
            }
            if (i === 9) {
                valorFormatado += '-';
            }
            valorFormatado += valor[i];
            if (i >= 10) {
                break;
            }
        }
        return valorFormatado;
    };

    const trocarPassword = () => {
        setShowPassword(!showPassword);
    };


    return (

        <div className="modalEditarUsuario" onClick={onClose}>
            <div className="modalEditarUsuario-content" onClick={(e) => e.stopPropagation()}>
                <div className="modalEditarUsuario-close-bnt">
                    <Button
                        aria-label="Fechar"
                        style={{ padding: 0, minWidth: 0 }}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </Button>
                </div>
                <h2 className="modalEditarUsuario-titulo">Editar Usuário</h2>
                <form onSubmit={submitForm}>

                    <div className="modalEditarUsuario-form-group">
                        <label htmlFor="Nome" className="modalEditarUsuario-label">
                            Nome:
                        </label>
                        <input
                            className="modalEditarUsuario-input"
                            type="text"
                            name="name"
                            value={editarUsuario.name}
                            onChange={InputChange}

                            autoComplete="off"
                        />
                    </div>

                    <div className="modalEditarUsuario-form-row">
                        <div className="modalEditarUsuario-form-group">
                            <label htmlFor="Email" className="modalEditarUsuario-label">
                                Email:
                            </label>
                            <input
                                className="modalEditarUsuario-input"
                                type="text"
                                name="email"
                                value={editarUsuario.email}
                                onChange={InputChange}

                                autoComplete="off"
                            />
                        </div>
                        <div className="modalEditarUsuario-form-group">
                            <label htmlFor="dataNascimento" className="modalEditarUsuario-label">
                                Data de Nascimento:
                            </label>
                            <input
                                className="modalEditarUsuario-input"
                                type="date"
                                name="dataNasc"
                                value={editarUsuario.dataNasc}
                                onChange={InputChange}

                                autoComplete="off"
                            />
                        </div>
                    </div>

                    <div className="modalEditarUsuario-form-row">
                        <div className="modalEditarUsuario-form-group">
                            <label htmlFor="cpf" className="modalEditarUsuario-label">
                                CPF:
                            </label>
                            <input
                                className="modalEditarUsuario-input"
                                type="text"
                                name="cpf"
                                value={editarUsuario.cpf}
                                onChange={InputChange}

                                autoComplete="off"
                            />
                        </div>
                        <div className="modalEditarUsuario-form-group">
                            <label htmlFor="TipoUsuario" className="modalEditarUsuario-label">
                                Tipo de Usuário:
                            </label>
                            <select
                                className="modalEditarUsuario-select"
                                name="role"
                                value={editarUsuario.role}
                                onChange={InputChange}

                            >
                                {options.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="modalEditarUsuario-form-row">
                        <div className="modalEditarUsuario-form-group">
                            <label htmlFor="novaSenha" className="modalEditarUsuario-label">
                                Nova Senha:
                            </label>
                            <input
                                className="modalEditarUsuario-input"
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                value={editarUsuario.password}
                                onChange={InputChange}

                                autoComplete="off"
                            />
                        </div>
                        <button type="button" onClick={trocarPassword} className="modalEditarUsuario-btn-password">
                            {showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                        </button>
                        <div className="modalEditarUsuario-form-group">
                            <label htmlFor="confirmPassword" className="modalEditarUsuario-label">
                                Confirmar Senha:
                            </label>
                            <input
                                className="modalEditarUsuario-input"
                                type={showPassword ? 'text' : 'password'}
                                name="confirmPassword"
                                value={editarUsuario.confirmPassword}
                                onChange={InputChange}

                                autoComplete="off"
                            />
                        </div>
                    </div>


                    <div className="modalEditarUsuario-form-row">
                        <Button
                            style={{ width: "100%", marginTop: "10px" }}
                            variant="contained"
                            onClick={submitForm}
                        >
                            {searching ? (
                                <>
                                    <FontAwesomeIcon
                                        icon={faSpinner} spin
                                        style={{ marginRight: "0.5em" }}
                                    />
                                    Carregando...
                                </>
                            ) : (
                                resultReturned ? (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            style={{ marginRight: "0.5em" }}
                                        />
                                        Salvo
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faFloppyDisk}
                                            style={{ marginRight: "0.5em" }}
                                        />
                                        Salvar
                                    </>
                                )
                            )}
                        </Button>
                    </div>

                </form>
            </div>
        </div>

    );
};

export default ModalEditarUsuario;
