import React from 'react';
import { Box, TextField, MenuItem } from '@mui/material';

const options = {
    nivel: ["Ao Nível", "Abaixo", "Acima", "Irregular"],
    ocupacao: [
        "Edificado",
        "Vago",
        "Construção em Andamento",
        "Construção Paralizada",
        "Ruínas",
        "Em Reforma",
        "Em Demolição",
        "Praça",
        "Construção Paralisada"
    ],
    patrimonio: ["Particular", "Federal", "Estadual", "Municipal", "Religioso", "Outros"],
    situacaoLote: [
        "Esquina/+ de 1 frente",
        "Meio de Quadra",
        "Encravado",
        "Gleba",
        "Vila/Condomínio",
        "Sítio Rec."
    ],
    muroPasseio: ["Muro e Passeio", "Só Muro", "Só Passeio", "Inexistente"],
    topografia: ["Plano", "Aclive", "Declive", "Irregular", "Difícil Aproveitamento"],
    pedologia: ["Não Atribuído", "Firme", "Inundável"]
};

const TabLote = () => {
    return (
        <Box display="flex" flexDirection="column" gap={2} >
            <TextField select label="Ocupação" fullWidth>
                {options.ocupacao.map(option => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
            <TextField select label="Nível" fullWidth>
                {options.nivel.map(option => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>

            <TextField select label="Situação do Lote" fullWidth>
                {options.situacaoLote.map(option => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
            <TextField select label="Muro/Passeio" fullWidth>
                {options.muroPasseio.map(option => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    );
};

export default TabLote;
