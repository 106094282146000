import "./App.css";

import { useEffect, useState, useContext } from "react";

import Header from "./Components/Header/Header";
import MapRender from "./Components/MapRender/MapRender";
import TelaLogin from "./Components/TelaLogin/TelaLogin";
import AboutButton from "./Components/AboutButton/AboutButton";
import FloatingButton from "./Components/FloatingButton/FloatingButton";
import { ContextMapProvider, ContextMap } from "./ContextMap/ContextMap";
import Conteudo from "./Components/Conteudo/Conteudo";

import satelite from './assets/img/Satelite.png';
import carto from './assets/img/openstreet.png';
import orto from './assets/img/orto.png';
import google from './assets/img/logo_google.png';



function App() {
  const [openTelaLogin, setOpenTelaLogin] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [estadoLogin, setEstadoLogin] = useState("");

  function toggletelaLogin() {
    setOpenTelaLogin(!openTelaLogin);
  }

  function toggletelaSidebar() {
    setShowSidebar(!showSidebar);
  }


  function nullEstadoLogin() {

    setEstadoLogin(sessionStorage.setItem("infoUsuarioToken", ""))
    setEstadoLogin(sessionStorage.getItem("infoUsuarioToken"))
  }

  function toggleteEstadoLogin() {

    setEstadoLogin(sessionStorage.getItem("infoUsuarioToken"))
  }

  useEffect(() => {

    toggleteEstadoLogin();
  }, [estadoLogin])

  return (
    <div className="App">
      <ContextMapProvider>
        <Header setOpenTelaLogin={toggletelaLogin} toggletelaSidebar={toggletelaSidebar} estadoLogin={estadoLogin} nullEstadoLogin={nullEstadoLogin} />
        {!openTelaLogin && <div style={{ marginLeft: (!showSidebar && estadoLogin) ? '250px' : '0px', transition: 'margin-left 1s ease' }}> <Conteudo estadoLogin={estadoLogin} /> </div>}

        {!openTelaLogin && !estadoLogin && <div className="container-AboutButton" ><AboutButton /></div>}
        <TelaLogin isOpen={openTelaLogin} setOpenTelaLogin={toggletelaLogin} toggleteEstadoLogin={toggleteEstadoLogin} />
      </ContextMapProvider>
    </div>
  );
}

export default App;