import React, { useEffect, useState } from 'react';
import { Modal, Box, Button, IconButton, Typography, Tab, Tabs } from '@mui/material';
import TabLote from './Tabs/TabLote';
import TabUnidadeImobiliaria from './Tabs/TabUnidadeImobiliaria';
import TabMapa from './Tabs/TabMapa';
import { Close } from '@material-ui/icons';
import { Api } from '../../../../services/Api';
import { TabAreaCoberta } from './Tabs/TabAreaCoberta';

export const ModalLote = ({ open, onClose, returnLoteColeta }) => {
    const [legenda, setLegenda] = useState([]);
    const [storage, setStorage] = useState(null);
    const [storageUnidades, setStorageUnidades] = useState([]);
    const [storageApiLote, setStorageApiLote] = useState(null);

    const requestLote = async () => {
        try {

            if (returnLoteColeta) {
                const objetoLote = [{ coordenadas: returnLoteColeta.ID.geom, id: {} }];
                const responseLoteForId = await Api.getLoteForId(returnLoteColeta.ID.id);
                setStorageApiLote(responseLoteForId);
                setStorage(objetoLote);
                const arrayUnidades = responseLoteForId.data.unidades.map((item) => item.unidadeImobiliaria);
                setStorageUnidades(arrayUnidades);
            }

        } catch { console.log('Erro na requsição de Lote por Id') }
    };

    useEffect(() => {
        if (returnLoteColeta) { requestLote() }
    }, [returnLoteColeta]); // Executa apenas quando returnLoteColeta estiver disponível

    const getCoberturas = (item) => { setLegenda(item[0]) };



    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}
                style={{
                    height: '90%', // Defina a altura fixa aqui
                    overflowY: 'auto', // Habilita a rolagem vertical
                    flexGrow: 1,
                    padding: 10,
                    paddingLeft: 0,
                    paddingBottom: 0,
                    marginTop: 0
                }}
            >
                {value === index && (
                    <Box>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    width: '70rem',
                    height: '80vh',
                    bgcolor: 'background.paper',
                    py: 2,
                    m: 'auto',
                    mt: 10,
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    position: 'relative' // Necessário para posicionar o botão de fechar
                }}
            >
                {/* Ícone de fechar no canto superior direito */}
                <div style={{ overflowY: 'auto', display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
                    <div style={{ display: 'flex', width: '100%', height: '100%', paddingInline: 20 }}>
                        {storage && <TabMapa geometriaLoteColeta={storage} responseLoteForId={storageApiLote} getCoberturas={getCoberturas} />}
                    </div>
                    <div style={{ width: '100%', height: '100%' }}>
                        <IconButton
                            onClick={onClose}
                            sx={{
                                position: 'absolute',
                                top: 10,
                                right: 5,
                                color: '#1769aa',
                                zIndex: 2
                            }}
                        >
                            <Close />
                        </IconButton>
                        <div style={{ height: '60vh' }}>
                            <Tabs value={value} onChange={handleChange} >
                                <Tab label="Lote" {...a11yProps(0)} />
                                <Tab label="Unidade Imobiliária" {...a11yProps(1)} />
                                {legenda.length > 0 && < Tab label="Coberturas" {...a11yProps(2)} />}
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <TabLote />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                {storageUnidades.map(item => <TabUnidadeImobiliaria unidadedeDados={item} />)}
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                                    {legenda.map((item, index) => <TabAreaCoberta key={index} legenda={item} />)}
                                </div>
                            </TabPanel>
                        </div>
                        <Button sx={{ position: 'absolute', width: 35, paddingX: 6, bottom: 10, right: 5 }} variant='contained'>Salvar</Button>
                    </div>
                </div>

            </Box>
        </Modal>
    );
};
