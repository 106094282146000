import React, { useState, useEffect, useRef, useContext } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFloppyDisk,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  FormControl,
  FormGroup,
  FormControlLabel,
  Paper,
  Switch,
  Tabs,
  Tab,
  Radio,
  RadioGroup,
  FormLabel,
  TextField,
  Button,
  Divider,
  Box,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

import { Feature } from "ol";
import { Point } from "ol/geom";

import GeoJSON from "ol/format/GeoJSON.js";
import Map from "ol/Map.js";
import View from "ol/View.js";
import { Fill, Stroke, Style } from "ol/style.js";
import { OSM, Vector as VectorSource } from "ol/source.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import proj4 from "proj4";

import { Api } from "../../../services/Api.js";
import { ContextMap } from "../../../ContextMap/ContextMap";
import "./ModalRemembramento.css";

const ModalRemembramento = ({ idAdversidade }) => {
  const [informacoesAdv, setInformacoesAdv] = useState("");
  const [informacoesAdvReduzido, setInformacoesAdvReduzido] = useState("");

  const [selectedValue, setSelectedValue] = useState("");
  const [selectedValueCodigo, setSelectedValueCodigo] = useState("");
  const [valueTabs, setValueTabs] = useState(0);
  const [valueRadioGroup, setValueRadioGroup] = useState("");

  const [isTabDisabled, setIsTabDisabled] = useState(true); //habilitar  e desabilidar o tab

  const [currentZoom, setCurrentZoom] = useState(5);
  const [logradouros, setLogradouro] = useState("");
  const [dadosRemembramento, setDadosRemembramento] = useState("");

  const [coordenadasPoligono, setCoordenadasPoligono] = useState([
    [507511.096510162, 7568780.598330974],
    [507518.823996995, 7568788.760727163],
    [507523.718691678, 7568797.971094619],
    [507530.018919432, 7568793.229685036],
    [507528.599032377, 7568789.29975676],
    [507529.417528115, 7568786.891102029],
    [507525.102074188, 7568783.64620372],
    [507521.015141249, 7568778.592657473],
    [507512.897200136, 7568775.259393719],
    [507511.096510162, 7568780.598330974],
  ]);
  const [estadoLote, setEstadoLote] = useState(true);
  const [estadoCobertura, setEstadoCobertura] = useState(true);
  const [estadoLoteVizinhos, setEstadoLoteVizinho] = useState(true);
  const { alteraOpcao, opcao } = useContext(ContextMap);

  const [coordenadasPoligonoADV, setCoordenadasPoligonoADV] = useState([]);

  const [pontoCentralPoligono, setpontoCentralPoligono] = useState([
    507511.096510162, 7568780.598330974,
  ]);
  let combinedVectorSource;
  let geojsonObject = [];

  const [stateTab, setStateTab] = useState({
    coberturas: true,
    lotes: true,
    lotesVizinhos: true,
  });

  const opcaoClicada = async (opcaoSelecionada) => {
    await alteraOpcao("");
    alteraOpcao(opcaoSelecionada);
  };

  const handleSubmit = (e) => {};

  //Para a chamada da função para sair
  const onModalClick = (e) => {
    e.stopPropagation();
  };
  const handleChange = (event) => {
    console.log(event.target.name);
    switch (event.target.name) {
      case "lotes":
        if (event.target.checked === false) {
          setEstadoLote(false);
        } else {
          setEstadoLote(true);
        }
        break;

      case "coberturas":
        if (event.target.checked === false) {
          setEstadoCobertura(false);
        } else {
          setEstadoCobertura(true);
        }
        break;

      case "lotesVizinhos":
        if (event.target.checked === false) {
          setEstadoLoteVizinho(false);
        } else {
          setEstadoLoteVizinho(true);
        }
        break;
    }

    setStateTab({
      ...stateTab,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  const handleChangeRadioGroup = (event) => {
    if (event.target.value === "casoRemembramento") {
      setValueTabs(1);
      setIsTabDisabled(false);
    } else {
      setIsTabDisabled(true);
      setValueTabs(0); // Define o valor da aba como 0 para "Mapa"
    }
    if (valueRadioGroup === event.target.value) {
      setValueTabs(0);
      setIsTabDisabled(true);
      setValueRadioGroup("casoNãoRemembramento");
    } else setValueRadioGroup(event.target.value);
  };

  const requestCoodernadasAdv = async (informacoesApi) => {
    const promises = informacoesApi.lotesVizinhos.map(async (lote) => {
      const response = await Api.getMapaCodigo(lote.id);
      if (response.data && response.data.lote) {
        const informacoesApi2 = response.data.lote.geom.coordinates;
        const aux = informacoesApi2.flatMap((coordenada) => {
          return coordenada;
        });
        return aux;
      } else {
        return [];
      }
    });

    // Use Promise.race() to get the first resolved promise
    const firstResult = await Promise.race(promises);

    const allAux = await Promise.all(promises);
    const combinedAux = [].concat(...allAux.flat());
    console.log(combinedAux);
    configurarCoordenadaCentral();
    configurarCoordenada(combinedAux);

    configurarCoordenadaCentral(firstResult[0][0]);
  };

  const fetchData = async () => {
    try {
      const data = await Api.getAdversidadesId(idAdversidade);
      const informacoesApi = data.data;
      console.log(informacoesApi);
      const valorDinamico = informacoesApi.lotesInfo
        .map((item) => item.reduzido)
        .join(", ");
      configurarCoordenada2(informacoesApi.adversidade.geom.coordinates[0][0]);
      setInformacoesAdvReduzido(valorDinamico);
      requestCoodernadasAdv(informacoesApi);
      setDadosRemembramento(informacoesApi);
    } catch (error) {
      console.log("Deu erro:", error);
    }
  };

  useEffect(() => {
    setInformacoesAdv(informacoesAdv);
    fetchData();
  }, []);

  const configurarCoordenada = (coordenadasPoligono) => {
    const utmProjection =
      "+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs";
    const latLngProjection = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";

    const latLngCoordinatesArray = coordenadasPoligono.map((coordenadas) => {
      return proj4(utmProjection, latLngProjection, coordenadas);
    });
    setCoordenadasPoligono(latLngCoordinatesArray);
  };
  const configurarCoordenada2 = (coordenadasPoligono) => {
    const utmProjection =
      "+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs";
    const latLngProjection = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";

    const latLngCoordinatesArray = coordenadasPoligono.map((coordenadas) => {
      return proj4(utmProjection, latLngProjection, coordenadas);
    });
    setCoordenadasPoligonoADV(latLngCoordinatesArray);
  };

  const combinedFeatures = [];

  const mapRef = useRef(null);

  const configurarCoordenadaCentral = (pointCentral) => {
    const utmProjection =
      "+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs";
    const latLngProjection = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";

    if (pointCentral === undefined) {
      setpontoCentralPoligono([]);
    } else {
      const coordenadasLimpo = pointCentral;
      const latLngCoordinates = proj4(
        utmProjection,
        latLngProjection,
        coordenadasLimpo
      );
      setpontoCentralPoligono(latLngCoordinates);
    }
  };

  useEffect(() => {
    geojsonObject = [
      {
        type: "FeatureCollection",
        crs: {
          type: "name",
          properties: {
            name: "EPSG:4326",
          },
        },
        features: [
          {
            type: "Feature",
            geometry: {
              type: "MultiPolygon",
              coordinates: [[coordenadasPoligono]],
            },
          },
        ],
      },
    ];

    geojsonObject.push({
      type: "FeatureCollection",
      crs: {
        type: "name",
        properties: {
          name: "EPSG:4326",
        },
      },
      features: [
        {
          type: "Feature",
          geometry: {
            type: "MultiPolygon",
            coordinates: [[coordenadasPoligonoADV]],
          },
        },
      ],
    });

    geojsonObject.forEach((geojson, index) => {
      const vectorSource = new VectorSource({
        features: new GeoJSON().readFeatures(geojson),
      });

      const features = vectorSource.getFeatures();

      // Adiciona os recursos ao array combinado
      combinedFeatures.push(...features);
    });

    combinedVectorSource = new VectorSource({
      features: combinedFeatures,
    });

    //PARA REMOVER ALGUM VETOR
    const featureToRemove = combinedVectorSource.getFeatureById("feature-id");
    combinedVectorSource.removeFeature(featureToRemove);

    const styleFunction = (feature, resolution) => {
      let strokeConfigLote = estadoLote
        ? "rgba(0, 0, 0, 0.7)"
        : "rgba(229, 115, 115, 0.0)";
      let strokeConfigLoteVizinho = estadoLoteVizinhos
        ? "rgba(0, 0, 0, 0.7)"
        : "rgba(0, 0, 0, 0.0)";

      let colorConfigLote = estadoLote
        ? "rgba(229, 115, 115, 0.7)"
        : "rgba(229, 115, 115, 0.0)";
      let colorConfigLoteVizinho = estadoLoteVizinhos
        ? "rgba(144, 202, 249, 0.3)"
        : "rgba(144, 202, 249, 0)";

      let colors = [colorConfigLoteVizinho, colorConfigLote];
      let colorsStroke = [strokeConfigLoteVizinho, strokeConfigLote];

      const index = combinedVectorSource.getFeatures().indexOf(feature);
      const color = colors[index % colors.length];
      const colorStroke = colorsStroke[index % colorsStroke.length];

      const aux = new Style({
        stroke: new Stroke({
          color: colorStroke,
          width: 1,
        }),
        fill: new Fill({
          color: color,
        }),
      });

      return aux;
    };

    // Use the styleFunction in the vectorLayer
    const vectorLayer = new VectorLayer({
      source: combinedVectorSource,
      style: styleFunction,
    });

    const newMap = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        vectorLayer,
      ],
      view: new View({
        center: [pontoCentralPoligono[0], pontoCentralPoligono[1]],
        zoom: 19,
        projection: "EPSG:4326",
      }),
    });
    return () => {
      newMap.dispose();
    };
  }, [
    pontoCentralPoligono,
    currentZoom,
    valueTabs,
    estadoLote,
    estadoLoteVizinhos,
  ]);

  return (
    <div className="ModalRemembramento-modal">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          className="ModalRemembramento-modal-content"
          onClick={onModalClick}
        >
          <div className="ModalRemembramento-close-bnt">
            <Button
              aria-label="Fechar"
              // onClick={opcaoClicada("home")}
            >
              <HomeIcon />
            </Button>
          </div>
          <div className="containers">
            {/* Coluna 1 */}
            <Card variant="outlined">
              <div style={{ margin: "20px" }}>
                <h5 className="titulos-remembramento">
                  DADO DO LOTE REMEBRADO:
                </h5>
                <form onSubmit={handleSubmit}>
                  <div className="dados-remembramento">
                    <div className="ModalRemembramento-form-row">
                      <div className="ModalRemembramento-form-group">
                        <label
                          htmlFor="setor"
                          className="ModalRemembramento-label"
                        >
                          Setor:
                        </label>
                        <input
                          className="ModalRemembramento-fixed"
                          id="Setor"
                          value={dadosRemembramento.setor}
                          onChange={(e) => setSelectedValue(e.target.value)}
                          required
                          readOnly
                        />
                      </div>
                      <div className="ModalRemembramento-form-group">
                        <label
                          htmlFor="quadra"
                          className="ModalRemembramento-label"
                        >
                          Quadra:
                        </label>
                        <input
                          className="ModalRemembramento-fixed"
                          id="Quadra"
                          value={dadosRemembramento.quadra}
                          required
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="ModalRemembramento-form-group">
                      <label
                        htmlFor="quadra"
                        className="ModalRemembramento-label"
                      >
                        N inscrição reduzido:
                      </label>
                      <input
                        className="ModalRemembramento-fixed"
                        id="Quadra"
                        value={informacoesAdvReduzido}
                        required
                        readOnly
                      />
                    </div>
                  </div>

                  <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">
                      Escolha a opção que melhor se aplica a situação do lote.
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={valueRadioGroup}
                      onClick={handleChangeRadioGroup}
                    >
                      <FormControlLabel
                        value="casoRemembramento"
                        control={<Radio />}
                        label="SIM, é um caso de remembramento"
                      />
                      <FormControlLabel
                        value="casoNaoRemembramento"
                        control={<Radio />}
                        label="NÃO, não é um caso de remembramento"
                      />
                    </RadioGroup>
                  </FormControl>

                  <TextField
                    style={{ width: "100%", marginTop: "25px" }}
                    id="outlined-multiline-static"
                    label="Observações"
                    multiline
                    rows={3}
                    defaultValue=""
                  />
                </form>
              </div>
            </Card>

            {/* colunas 2 */}

            <div>
              <Card variant="outlined">
                <Paper
                  style={{ width: "100%", marginBottom: "15px" }}
                  variant="outlined"
                  square
                  elevation={3}
                >
                  <Tabs value={valueTabs} onChange={handleChangeTabs} centered>
                    <Tab label="Mapa" />
                    <Tab label="Dados do Lote" disabled={isTabDisabled} />
                  </Tabs>
                </Paper>

                <div style={{ margin: "10px" }}>
                  {/* MAPA */}
                  {!valueTabs ? (
                    <div>
                      <h5 className="titulos-remembramento">
                        GEOMETRIAS IDENTIFICADA NA VETORIZAÇÃO:
                      </h5>
                      <FormControl component="fieldset" variant="standard">
                        <FormGroup
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            margin: "10px",
                            padding: "5px",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                checked={stateTab.coberturas}
                                onChange={handleChange}
                                name="coberturas"
                              />
                            }
                            label="Mostrar coberturas"
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                checked={stateTab.lotes}
                                onChange={handleChange}
                                name="lotes"
                              />
                            }
                            label="Mostrar lotes"
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                checked={stateTab.lotesVizinhos}
                                onChange={handleChange}
                                name="lotesVizinhos"
                              />
                            }
                            label="Mostrar lotes vizinhos"
                          />
                        </FormGroup>
                      </FormControl>
                      <Box
                        sx={{
                          display: "flex",
                          margin: 0.7,
                          marginLeft: 5,
                          zIndex: 1,
                          alignItems: "center",
                          width: "fit-content",
                          border: (theme) =>
                            `2px solid ${theme.palette.divider}`,
                          borderRadius: 1,
                          bgcolor: "background.paper",
                          color: "text.secondary",
                          "& svg": { m: 0.5 },
                          "& hr": { mx: 0.5 },
                        }}
                      >
                        <div
                          style={{
                            background: "#e57373",
                            height: "20px",
                            width: "20px",
                            margin: "15px",
                          }}
                        ></div>
                        <h5 style={{ margin: "15px", marginLeft: "0px" }}>
                          Lote
                        </h5>
                        <Divider orientation="vertical" flexItem />

                        <div
                          style={{
                            background: "#90caf9",
                            height: "20px",
                            width: "20px",
                            margin: "15px",
                          }}
                        ></div>
                        <h5 style={{ margin: "15px", marginLeft: "0px" }}>
                          Lotes vizinhos
                        </h5>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flex: "1",
                          backgroundColor: "#AAD3DF",
                        }}
                      >
                        <div ref={mapRef} className="mapa-remembramento" />
                      </div>
                    </div>
                  ) : (
                    <div className="container-dadosLote-remembramento">
                      {/* ÁREA DE INPUTS DADOS DO LOTE */}
                      <h5 className="titulos-remembramento">DADOS DO LOTE:</h5>

                      <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                        <form onSubmit={handleSubmit}>
                          <div className="ModalRemembramento-form-row">
                            <div className="ModalRemembramento-form-group">
                              <label
                                htmlFor="codigo"
                                className="ModalRemembramento-label"
                              >
                                Código:
                              </label>
                              <input
                                className="ModalRemembramento-input"
                                type="text"
                                id="Codigo"
                                value={selectedValueCodigo}
                                onChange={(e) =>
                                  setSelectedValueCodigo(e.target.value)
                                }
                                required
                              />
                            </div>

                            <div className="ModalRemembramento-form-group">
                              <label
                                htmlFor="selector"
                                className="ModalRemembramento-label"
                              >
                                Lote:
                              </label>
                              <select
                                className="ModalRemembramento-select"
                                id="select"
                                // value={postesIluminacao}
                                // onChange={(e) => setPostesIluminacao(e.target.value)}
                                required
                              >
                                <option value="">Selecione:</option>
                                {dadosRemembramento.lotes?.map((row, index) => (
                                  <option value="" key={index}>
                                    {" "}
                                    {row}{" "}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="ModalRemembramento-form-group">
                              <label
                                htmlFor="setor"
                                className="ModalRemembramento-label"
                              >
                                Setor:
                              </label>
                              <input
                                className="ModalRemembramento-fixed"
                                id="Setor"
                                value={dadosRemembramento.setor}
                                onChange={(e) =>
                                  setSelectedValue(e.target.value)
                                }
                                required
                                readOnly
                              />
                            </div>

                            <div className="ModalRemembramento-form-group">
                              <label
                                htmlFor="quadra"
                                className="ModalRemembramento-label"
                              >
                                Quadra:
                              </label>
                              <input
                                className="ModalRemembramento-fixed"
                                id="Quadra"
                                value={dadosRemembramento.quadra}
                                required
                                readOnly
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          style={{
                            width: "50%",
                            marginTop: "10px",
                            marginBottom: "15px",
                          }}
                          variant="contained"
                        >
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            style={{ marginRight: "0.5em" }}
                          />
                          Consultar Lote
                        </Button>
                      </div>

                      <div className="dadosLote-remembramento">
                        <div className="bloco-remembramento">
                          <div className="ModalRemembramento-form-group">
                            <label
                              htmlFor="codigo"
                              className="ModalRemembramento-label"
                            >
                              Nº de Unidade(s):
                            </label>
                            <input
                              className="ModalRemembramento-input"
                              type="text"
                              id="Codigo"
                              value={selectedValueCodigo}
                              onChange={(e) =>
                                setSelectedValueCodigo(e.target.value)
                              }
                              required
                            />
                          </div>

                          <div className="ModalRemembramento-form-group">
                            <label
                              htmlFor="selector"
                              className="ModalRemembramento-label"
                            >
                              Logradouro:
                            </label>
                            <select
                              className="ModalRemembramento-select"
                              id="select"
                              value={logradouros}
                              onChange={(e) => setLogradouro(e.target.value)}
                              required
                            >
                              <option value="">Selecione:</option>
                              {informacoesAdv.logradouros?.map((row, index) => (
                                <option value={row.nome} key={index}>
                                  {" "}
                                  {row.nome}{" "}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="bloco-remembramento">
                          <div className="ModalRemembramento-form-group">
                            <label
                              htmlFor="codigo"
                              className="ModalRemembramento-label"
                            >
                              Número:
                            </label>
                            <input
                              className="ModalRemembramento-input"
                              type="text"
                              id="Codigo"
                              value={selectedValueCodigo}
                              onChange={(e) =>
                                setSelectedValueCodigo(e.target.value)
                              }
                              required
                            />
                          </div>

                          <div className="ModalRemembramento-form-group">
                            <label
                              htmlFor="selector"
                              className="ModalRemembramento-label"
                            >
                              Bairro:
                            </label>
                            <input
                              className="ModalRemembramento-input"
                              type="text"
                              id="Codigo"
                              value={selectedValueCodigo}
                              onChange={(e) =>
                                setSelectedValueCodigo(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <Divider
                        style={{
                          marginTop: "30px",
                          marginBottom: "20px",
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      />

                      <h5 className="titulos-remembramento">
                        DADOS DO PROPRIETÁRIO:
                      </h5>
                      <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                        <div className="ModalRemembramento-form-row">
                          <div
                            style={{ flex: "0.4" }}
                            className="ModalRemembramento-form-group"
                          >
                            <label
                              htmlFor="selector"
                              className="ModalRemembramento-label"
                            >
                              Tipo:
                            </label>
                            <select
                              className="ModalRemembramento-select"
                              id="select"
                              // value={postesIluminacao}
                              // onChange={(e) => setPostesIluminacao(e.target.value)}
                              required
                            >
                              <option value="">Selecione:</option>
                              <option value="">Pessoa Física</option>
                              <option value="">Pessoa Jurídica</option>
                            </select>
                          </div>

                          <div
                            style={{ flex: "1" }}
                            className="ModalRemembramento-form-group"
                          >
                            <label
                              htmlFor="codigo"
                              className="ModalRemembramento-label"
                            >
                              CPF/CNPJ:
                            </label>
                            <input
                              className="ModalRemembramento-input"
                              type="text"
                              id="Codigo"
                              value={selectedValueCodigo}
                              onChange={(e) =>
                                setSelectedValueCodigo(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          style={{ width: "50%", marginTop: "10px" }}
                          variant="contained"
                        >
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            style={{ marginRight: "0.5em" }}
                          />
                          Consultar Proprietário
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </Card>
            </div>
          </div>

          <div className="ModalRemembramento-form-row">
            <Button style={{ width: "100%" }} variant="contained">
              <FontAwesomeIcon
                icon={faFloppyDisk}
                style={{ marginRight: "0.5em" }}
              />
              Salvar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalRemembramento;
