import React, { useContext, useEffect, useState } from "react";
import './AdvEndereco.css';
import { ColunaMapa } from "./Components/ColunaMapa";
import { MapProviderAdv } from "./Context/FuncoesMapa";
import { ColunaDados } from "./Components/ColunaDados";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Api } from '../../../services/Api';
import { ContextAdv } from '../ContextAdv';
import { ContextProviderInput } from "./Context/ContextInputs";

const AdvEndereco = () => {
    const [responseAdversidade, setResponseAdversidade] = useState("");
    const { idAdv } = useContext(ContextAdv);

    const fetchData = async () => {
        const response = await Api.getAdversidadesId(idAdv)
        setResponseAdversidade(response.data)
        return response.data
    }

    useEffect(() => {
        async function fetchDataAndSetState() {
            try {
                const response = await fetchData();
                setResponseAdversidade(response);
            } catch (error) {
                console.error(error);
            }
        }
        fetchDataAndSetState();
    }, []);

    return (
        <ContextProviderInput>
            <MapProviderAdv>
                <div className="advEnd-container">
                    {responseAdversidade ? (
                        <div className="advEnd-content" style={{ marginBottom: '100px' }}>
                            <ColunaMapa responseAdversidade={responseAdversidade} />
                            <ColunaDados responseAdversidade={responseAdversidade} />
                        </div>
                    ) : (
                        <div className="advEnd-loading">
                            <FontAwesomeIcon size='5x' icon={faSpinner} spin />
                        </div>
                    )}
                </div>
            </MapProviderAdv>
        </ContextProviderInput>
    );
};

export default AdvEndereco;