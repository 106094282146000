import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Divider } from '@mui/material';

import { calcularCentroide, configurarCoordenadaCentral } from '../../../../../Map/Hooks/FormaterCoordinates';
import ManegerVectorLayer from '../../../../../Map/Component/VectorManager';
import { Api } from '../../../../../services/Api';
const beiralOptions = ["Atípico", "Cobertura", "Inexistente", "Padrão"];

const TabMapa = ({ geometriaLoteColeta, responseLoteForId, getCoberturas }) => {
    const mapManger = useRef(null);
    const vectorManeger = useRef();

    const editColor = (item) => {
        if (item.flag === 1) { return ("rgb(19,100,181, 0.8)") }
        if (item.flag === 2) { return ("rgba(114,178,137, 0.8)") }
        else { return ("rgb(229, 115, 115, 0.8)") }
    }

    useEffect(() => {
        const fetch = async () => {
            try {
                const response = await Api.getMarchaUrbana();
                const resultResponse = response.data.manchaUrbana.ponto_central.coordinates
                const coordendaCentral = configurarCoordenadaCentral(resultResponse)
                if (!vectorManeger.current) {
                    vectorManeger.current = new ManegerVectorLayer(coordendaCentral);
                    vectorManeger.current.setMapElement(mapManger.current);

                    const arrayIterarUnidades = Array.from({ length: responseLoteForId.data.unidades.length }, (_, index) => index);

                    const coberturas = arrayIterarUnidades.map((_, index) => {
                        if (responseLoteForId.data.unidades[index].areaCoberta.length > 0) {
                            const unidade = responseLoteForId.data.unidades[index].areaCoberta.map((item) => {
                                return { coordenadas: [item.geom.coordinates], id: { id: item.id, cod: item.loteCod } }
                            });
                            return unidade
                        } return []
                    })
                    const coberturasUnidas = coberturas.flat();
                    let testadas = []

                    if (responseLoteForId.data.testada.length > 0) {
                        testadas = responseLoteForId.data.testada.map((item) => {
                            return { coordenadas: [item.geom.coordinates], id: item.id }
                        });
                    }
                    const testadasUnidas = testadas.flat();

                    vectorManeger.current.setCenterElement(configurarCoordenadaCentral(calcularCentroide(geometriaLoteColeta[0].coordenadas[0][0])))
                    vectorManeger.current.setZoomElement(17)
                    vectorManeger.current.iniciarVectorLayer('Lote', geometriaLoteColeta, null, () => "rgba(160,157,164, 0.8)")
                    vectorManeger.current.iniciarVectorLayer('Testada', testadasUnidas, null, () => 'black');
                    vectorManeger.current.iniciarVectorLayer('Cobertura', coberturasUnidas, null);
                    const dadosCobertura = vectorManeger.current.getLayersMap('Cobertura').map(item => item.DADOS)
                    getCoberturas(dadosCobertura);
                }
            } catch (error) { console.log('Erro ao encontrar informações do lote') }
        }
        fetch()
    }, [responseLoteForId, geometriaLoteColeta]);

    return (<div style={{ display: 'flex', width: '100%', height: '100%' }} ref={mapManger} />)
};

export default TabMapa;
