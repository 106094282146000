import React, { useEffect, useRef, useContext, useState } from 'react';
import 'ol/ol.css';
import proj4 from "proj4";
import GeoJSON from 'ol/format/GeoJSON.js';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import { Fill, Stroke, Style } from 'ol/style.js';
import { OSM, Vector as VectorSource } from 'ol/source.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import XYZ from 'ol/source/XYZ.js';

// import { GoogleMap, LoadScript } from '@react-google-maps/api';

import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import { ContextMap } from '../../ContextMap/ContextMap';
import ModalInfo from './ModalInfo/ModalInfo';
import { Api } from '../../services/Api';

import satelite from '../../assets/img/Satelite.png';
import carto from '../../assets/img/openstreet.png';
import orto from '../../assets/img/orto.png';
import google from '../../assets/img/logo_google.png';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const url = process.env.REACT_APP_API_GEO;




// CONTAINER DE CARTO, SATELITE E ORTO
function ContainerMapas() {

  const styleComponent = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1072
  }
  const { alterarTipoMapa, adicionarObjeto, removerObjeto, tipoMapa } = useContext(ContextMap);
  const [searching, setSearching] = useState(false);

  const handleOrtoClick = () => {
    // Define searching como true para mostrar a mensagem de pesquisa
    alterarTipoMapa(true);
    setSearching(true);

    setTimeout(() => {
      setSearching(false);
    }, 7000);
  };

  return (
    <>
      {searching && (
        (
          <div style={styleComponent}>
            <FontAwesomeIcon icon={faSpinner} spin size='3x' />
          </div>
        )
      )}


      <div className="row-button-satelite">
        <div className="div-button-satelite" onClick={() => { alterarTipoMapa(false) }}>
          <img className="botao" src={satelite} alt="satélite" />
          <label className="label-satelite" htmlFor="satélite">
            Satélite
          </label>
        </div>

        <div className="div-button-satelite" onClick={() => {
          alterarTipoMapa(true)
          removerObjeto('Ortofoto')
        }}>
          <img className="botao" src={carto} alt="carto" />
          <label className="label-satelite" htmlFor="carto">
            Carto
          </label>
        </div>

        <div className="div-button-satelite" onClick={() => {
          adicionarObjeto({ id: 'Ortofoto', label: 'Ortofoto', url: 'SaoLourenco', layer: 'SaoLourenco:satelinovo', ativo: true })
          handleOrtoClick();
        }}>
          <img className="botao" src={orto} alt="orto" style={{ marginRight: '0.6vw' }} />
          <label className="label-satelite" htmlFor="orto">
            Orto
          </label>
        </div>

      </div>
      {tipoMapa ? null :
        (
          <>
            < img style={{ width: '3rem', bottom: 0, position: 'fixed', cursor: 'default' }} src={google} />
            <a href="https://www.openstreetmap.org/copyright" className="logo-OSM" target="_blank">
              © OpenStreetMap contributors.
            </a>
          </>
        )}

    </>
  );
}


function MapRender() {
  const mapRef = useRef(null);
  const [mapInstance, setMapInstance] = useState(null);
  const { listaCamadas, objMapa, tipoMapa } = useContext(ContextMap);
  const [currentZoom, setCurrentZoom] = useState(15); // Zoom Openlayers
  const [zoomLevel, setZoomLevel] = useState(15); // Nível de zoom GOOGLE MAPS

  const [coordenadas, setCoordenadas] = useState([-45.0517, -22.1175]);
  const [coordenadasGoogle, setCoordenadasGoogle] = useState([-45.0517, -22.1175]);
  const [primeiroRender, setPrimeiroRender] = useState(false);

  const [coordenadasPoligono, setCoordenadasPoligono] = useState(objMapa.coordenadas);
  const [coordenadasLimpo, SetCoordenadasLimpo] = useState("");
  const [modalAberto, setModalAberto] = useState(false);
  const [infoImovel, setInfoImovel] = useState("");


  async function getCoord() {
    const valor = {
      coordA: coordenadasLimpo[0],
      coordB: coordenadasLimpo[1]
    }
    try {
      const response = await Api.getCoord(valor);
      setInfoImovel(response);
      setModalAberto(true);
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (coordenadasLimpo.length === 2) {
      getCoord();
    }
  }, [coordenadasLimpo]);


  useEffect(() => {
    if (objMapa.coordenadasCentral !== "") {
      setCoordenadas(objMapa.coordenadasCentral);
      setCurrentZoom(objMapa.zoom);
      configurarCoordenada(objMapa.coordenadas);
      SetCoordenadasLimpo(objMapa.coordenadasCentralSemConversao);
    }
  }, [objMapa.coordenadasCentral]);

  const styles = {
    'MultiPolygon': new Style({
      stroke: new Stroke({
        color: 'rgb(255, 188, 73)',
        width: 1,
      }),
      fill: new Fill({
        color: 'rgb(255, 188, 73, 0.4)',
      }),
    }),
    'selected': new Style({
      stroke: new Stroke({
        color: 'rgb(255, 188, 73)',
        width: 2,
      }),
      fill: new Fill({
        color: 'rgb(255, 188, 73, 0.4)',
      }),
    }),
  };
  const styleFunction = function (feature) {
    return styles[feature.getGeometry().getType()];
  };
  const configurarCoordenada = (coordenadasPoligono) => {
    const utmProjection = '+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
    const latLngProjection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

    const latLngCoordinatesArray = coordenadasPoligono.map((coordenadas) => {
      return proj4(utmProjection, latLngProjection, coordenadas);
    });
    setCoordenadasPoligono(latLngCoordinatesArray);
  };



  const geojsonObject = {
    'type': 'FeatureCollection',
    'crs': {
      'type': 'name',
      'properties': {
        'name': 'EPSG:4326',
      },
    },
    'features': [
      {
        'type': 'Feature',
        'geometry': {
          'type': 'MultiPolygon',
          'coordinates': [
            [
              coordenadasPoligono
            ],
          ],
        },
      },
    ],
  };


  const vectorSource = new VectorSource({
    features: new GeoJSON().readFeatures(geojsonObject),
  });


  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: styleFunction,
  });
  let selected = null;


  const urlMap = 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}';


  useEffect(() => {
    const newMap = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: tipoMapa ? new OSM() : new XYZ({
            url: urlMap,
          }),
        }),

      ],
      view: new View({
        center: (primeiroRender === true) ? [coordenadasGoogle[0], coordenadasGoogle[1]] : [coordenadasGoogle[0], coordenadasGoogle[1]],
        zoom: currentZoom != 15 ? currentZoom : zoomLevel,
        projection: 'EPSG:4326',
      }),
    });

    newMap.getView().on('change:resolution', () => {
      const newZoom = newMap.getView().getZoom();
      setZoomLevel(newZoom);
    });

    newMap.on('moveend', () => {
      const centroDoMapa = newMap.getView().getCenter();
      setCoordenadasGoogle(centroDoMapa)
    });

    newMap.on('singleclick', (e) => {
      const clickedFeature = newMap.forEachFeatureAtPixel(e.pixel, (f) => f);
      if (selected && selected !== clickedFeature) {
        selected.setStyle(undefined);
        selected = null;
      }
      if (clickedFeature) {
        selected = clickedFeature;
        selected.setStyle(styles.selected);
        getCoord();
      }
    });

    if (coordenadasLimpo.length === 2) {
      newMap.getView().animate({
        center: [coordenadas[0], coordenadas[1]],
        zoom: 15,
      });
    }

    setMapInstance(newMap);


    return () => {
      newMap.setTarget(null);
      newMap.dispose();
    };
  }, [coordenadas, currentZoom, tipoMapa]);


  useEffect(() => {
    if (mapInstance) {
      if (listaCamadas && listaCamadas.length > 0) {
        const imageLayers = listaCamadas.map((camada) => (
          new ImageLayer({
            source: new ImageWMS({
              url: `${url}${camada.url}/wms`,
              params: {
                LAYERS: camada.layer,
                FORMAT: 'image/png',
              },
              ratio: 1,
              serverType: 'geoserver',
            }),
          })
        ));

        mapInstance.getLayers().clear();
        mapInstance.getLayers().extend([
          new TileLayer({
            source: tipoMapa ? new OSM() : new XYZ({
              url: urlMap,
            }),
          }),
          ...imageLayers, vectorLayer
        ]);

        return () => {
          mapInstance.getLayers().clear();
          imageLayers.forEach((layer) => {
            layer.getSource().dispose();
          });
        };
      } else {
        mapInstance.getLayers().clear();
        mapInstance.getLayers().extend([
          new TileLayer({
            source: tipoMapa ? new OSM() : new XYZ({
              url: urlMap,
            }),
          }),
          vectorLayer,
        ]);
      }

    }

  }, [mapInstance, listaCamadas, tipoMapa]);

  return (
    <div>
      {/* <div style={{background: 'red', position:'fixed', height: '30px', width:'30px', top: 505, right: 500, zIndex: '3'}}> */}
      <div className='floating-button-mapa' style={{ position: "absolute" }}>
        <ContainerMapas />
      </div>
      {/* </div> */}

      <div style={{ height: '91vh', position: 'fixed', zIndex: '2', width: '100%', background: 'white' }}>
        <div ref={mapRef} style={{ width: '100%', height: '91vh', margin: '0 auto' }} />
        {modalAberto && (
          <section style={{ position: 'relative' }}>
            <ModalInfo onClose={() => setModalAberto(false)} infoImovel={infoImovel} />
          </section>
        )}
      </div>
    </div>
  );
};

export default MapRender;