import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Fab, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, TextField, TablePagination } from '@mui/material';
import "./AlterarProprietario.css";
import ModalCadastro from "./ModalCadastro/ModalCadastro";
import { Api } from "../../services/Api.js";
import { faMagnifyingGlass, faSpinner } from "@fortawesome/free-solid-svg-icons";


const AlterarProprietario = () => {
  const [initialData, setInitialData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [addProprietario, setAddProprietario] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTerm_CPF_CNPJ, setSearchTerm_CPF_CNPJ] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [showAddProprietario, setShowAddProprietario] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [searching, setSearching] = useState(false); //Adiciona o procurando durante a procura da API



  //Configura a lista de acordo com o tamanho da tela
  useEffect(() => {
    if (window.innerHeight > 750) {
      const numberPerPage = 12;
      setRowsPerPage(numberPerPage)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setSearching(true);
        const data = await Api.proprietariosCadastrados(localStorage.getItem("infoTotalProprietarios"));
        // Remover elementos com nome nulo
        const filteredData = data.data.result.items.filter((proprietario) => proprietario.nome !== null);
        // Função de comparação personalizada para ignorar espaços no nome
        const compareNames = (a, b) => {
          const nameA = a.nome ? a.nome.replace(/\s/g, '') : '';
          const nameB = b.nome ? b.nome.replace(/\s/g, '') : '';
          return nameA.localeCompare(nameB);
        };

        setInitialData(filteredData.sort(compareNames));
      } catch (error) {
        console.log('Deu erro:', error);
      }
      setSearching(false);
    };

    fetchData();
  }, [addProprietario]);

  useEffect(() => {
    if (searchTerm === '' && searchTerm_CPF_CNPJ === '') {
      const filteredProprietarios = initialData
      setFilteredData(filteredProprietarios);
    }
  }, [initialData, searchTerm, searchTerm_CPF_CNPJ]);

  //Atualiza a lista com o Proprietário adiconado
  function AtualizaListaProprietários() {
    const totalProprietarios = parseInt(localStorage.getItem("infoTotalProprietarios")) + 1;
    localStorage.setItem("infoTotalProprietarios", totalProprietarios);
    setAddProprietario(!addProprietario);
  }

  function activeFiltered() {
    let filteredProprietarios = initialData.filter((proprietario) => {
      const nomeProprietario = proprietario.nome ? proprietario.nome.toLowerCase() : ""; //Passa para caixa baixa
      const cpfCnpjPorprietario = proprietario.cpfCnpj ? proprietario.cpfCnpj.toLowerCase() : ""; //Passa para caixa baixa
      return nomeProprietario.startsWith(searchTerm.toLowerCase()) && cpfCnpjPorprietario.startsWith(searchTerm_CPF_CNPJ.toLowerCase()); //Retorna os 2 filtros juntos
    });
    setFilteredData(filteredProprietarios);
  }

  //Enter envia
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      activeFiltered();
    }
  };

  const toggleAddProprietario = () => {
    setShowAddProprietario(!showAddProprietario);
  };

  const handleChangePage = (_, newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchProprietario = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0);
  };

  const handleSearch_CPF_CNPJ = (e) => {
    setSearchTerm_CPF_CNPJ(e.target.value);
    setCurrentPage(0);
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '90vh' }}>
      <div style={{ display: 'flex', width: '88%', gap: '15px' }}>
        <TextField
          size="small"
          label="Pesquisar Proprietário"
          value={searchTerm}
          onChange={handleSearchProprietario}
          sx={{ marginTop: '30px', width: '100%', backgroundColor: 'white' }}
          onKeyPress={(event) => handleKeyPress(event)}
        />

        <TextField
          size="small"
          label="Pesquisar CPF/CNPJ"
          value={searchTerm_CPF_CNPJ}
          onChange={handleSearch_CPF_CNPJ}
          sx={{ marginTop: '30px', width: '100%', backgroundColor: 'white' }}
          onKeyPress={(event) => handleKeyPress(event)}
        />
      </div>

      <div style={{ margin: '30px', width: '88%', height: '100vh' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead >
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2' }}>Proprietário</TableCell>
                <TableCell sx={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2' }}>CPF/CNPJ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                .map((proprietario, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ fontSize: '11px', maxWidth: '130px' }}>{proprietario.nome}</TableCell>
                    <TableCell sx={{ fontSize: '11px', maxWidth: '30px' }}>{proprietario.cpfCnpj}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer component={Paper} >

          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
          />
        </TableContainer>
      </div>

      <div className="barra-direita-alterarproprietario">
        <Fab sx={{ margin: '5px', marginBottom: '-10px', fontSize: '20px' }} color="primary" onClick={activeFiltered}>
          {searching ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          )}
        </Fab>
        <Fab onClick={toggleAddProprietario} sx={{ margin: '5px', fontSize: '20px' }} color="primary">
          <FontAwesomeIcon icon={faPlus} />
        </Fab>
      </div>

      {showAddProprietario && (
        <section style={{ position: 'relative' }}>
          <ModalCadastro onClose={toggleAddProprietario} AtualizaListaProprietários={AtualizaListaProprietários} />
        </section>
      )}
    </div>
  );
};

export default AlterarProprietario;