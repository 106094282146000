import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Button, TextField, Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import './ImprimirBic.css';
import { Api } from "../../services/Api";

function ImprimirBic() {
  const [numInscricao, setNumInscricao] = useState("");
  const [numInscricaoReduzida, setNumInscricaoReduzida] = useState("");
  const [searching, setSearching] = useState(false);
  const [resultReturned, setResultReturned] = useState(false);
  const [respostaApi, setRespostaApi] = useState("");

  async function submit() {
    if (numInscricao !== "" && numInscricao.length === 22 || numInscricaoReduzida !== "") {
      setSearching(true);
      const valor = {
        codigoImovel: numInscricaoReduzida || undefined,
        inscricaoImovel: numInscricao || undefined
      };

      const response = await Api.numeroInscricaoPost(valor);
      if (!response.error) {
        setRespostaApi(response);
        setResultReturned(true);
      } else {
        alert("O número solicitado não existe");
      }
      setSearching(false);
    }
  }

  // Enter Envia
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      submit();
    }
  };

  const formataNumInscricao = (valor) => {
    valor = valor.replace(/\D/g, '');
    let valorFormatado = '';
    for (let i = 0; i < valor.length; i++) {
      if (i === 2 || i === 4 || i === 7 || i === 11 || i === 14) {
        valorFormatado += '.';
      }
      valorFormatado += valor[i];
      if (i >= 16) {
        break;
      }
    }
    return valorFormatado;
  };

  const numInscricaoChange = (event) => {
    const numero = event.target.value;
    const valorFormatado = formataNumInscricao(numero);
    setNumInscricao(valorFormatado);
  };

  return (
    <div className="ImprimirBic-main-container">
      <div className="ImprimirBic-container-imovel" style={{ padding: 10 }}>
        <div className="ImprimirBic-conteudo-imovel">
          <TextField
            className="ImprimirBic-input-imovel"
            label="N° de inscrição"
            variant="outlined"
            size="small"
            value={numInscricao}
            onChange={numInscricaoChange}
            sx={{ margin: '5px', backgroundColor: "white" }}
            onKeyPress={(event) => handleKeyPress(event)}
            autoComplete="off"
          />
          <TextField
            className="ImprimirBic-input-imovel"
            label="N° de inscrição reduzido"
            variant="outlined"
            size="small"
            value={numInscricaoReduzida}
            onChange={(e) => setNumInscricaoReduzida(e.target.value)}
            sx={{ margin: '5px', backgroundColor: "white" }}
            onKeyPress={(event) => handleKeyPress(event)}
            autoComplete="off"
          />
        </div>

        {resultReturned ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2', width: '100px' }}>Nº de inscrição</TableCell>
                  <TableCell style={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2', width: '100px' }}>Logradouro</TableCell>
                  <TableCell style={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2', width: '100px' }}>Bairro</TableCell>
                  <TableCell style={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2', width: '100px' }}>Reduzido</TableCell>
                  <TableCell style={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2', width: '100px' }}>Número</TableCell>
                  <TableCell style={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2', width: '100px' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ minWidth: '30px', maxWidth: '100px' }}>01.03.024.0721.001.001</TableCell>
                  <TableCell sx={{ minWidth: '30px', maxWidth: '100px' }}>Av. Antonio Ferreira da Silva</TableCell>
                  <TableCell sx={{ minWidth: '30px', maxWidth: '100px' }}>Caxambu Velho</TableCell>
                  <TableCell sx={{ minWidth: '30px', maxWidth: '100px' }}>3030</TableCell>
                  <TableCell sx={{ minWidth: '30px', maxWidth: '100px' }}>521</TableCell>
                  <TableCell sx={{ minWidth: '30px', maxWidth: '100px' }}>
                    <Button sx={{ height: '35px', border: '1px solid #1976D2' }}>
                      <FileDownloadIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className="ImprimirBic-conteudo-lista" style={{ height: '50px', padding: 0 }}></div>
        )}
      </div>
        <div className="ImprimirBic-barra-direita">
          <Fab sx={{ margin: '5px', marginBottom: '-10px', fontSize: '20px' }} color="primary" onClick={submit}>
            {searching ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            )}
          </Fab>
        </div>
    </div>
  );
}

export default ImprimirBic;
