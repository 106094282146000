import { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import "./GerenciarColetas.css";
import MapaColetas from "./components/Mapa/MapaColetas.jsx";

const GerenciarColetas = () => {
  const [value, setValue] = useState(0);
  const [searching, setSearching] = useState(false);
  const [estadoModal, setEstadoModal] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function submit() {
    setSearching(true);
    setSearching(false);
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function toggleEstadoModal() {
    setEstadoModal(!estadoModal)
  }

  return (
    <div style={{ height: '91vh' }}>
      {/* <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Geral" {...a11yProps(0)} />
          </Tabs>
        </Box>
      </Box> */}

      <div style={{ height: '100%' }}>
        {(value === 0) && <MapaColetas toggleEstadoModal={toggleEstadoModal} />}
      </div>
    </div>
  );
};

export default GerenciarColetas;
