import React from 'react';
import { Box, TextField, MenuItem, Typography } from '@mui/material';

const options = {
    utilizacao: [
        "Residencial",
        "Comercial",
        "Serviços",
        "Industrial",
        "Religioso",
        "Pública",
        "Mista",
        "Agropecuária",
        "Terreno sem Uso",
        "Outros"
    ],
    tipo: [
        "Casa/Sobrando",
        "Apartamento",
        "Sala/Loja",
        "Fábrica/Indústria",
        "Galpão",
        "Telheiros",
        "Misto",
        "Outros"
    ],
    alinhamento: ["Frente Alinhada", "Frente Recuada", "Fundos"],
    posicao: ["Isolada", "Geminada", "Conjugada", "Cond. Vertical", "Cond. Horizontal"],
    classificacao: ["Rústica", "Popular", "Média", "Boa", "Luxo"],
    estrutura: ["Madeira", "Alvenaria", "Concreto", "Metálico"],
    cobertura: [
        "Fibrocimento",
        "Cerâmica",
        "Laje",
        "Metálica",
        "Palha/Zinco",
        "Especial"
    ],
    esquadrias: ["Madeira", "Ferro", "Alumínio", "Especial"],
    paredes: [
        "Nenhuma",
        "Madeira",
        "Taipa",
        "Alvenaria",
        "Concreto",
        "Especial"
    ],
    revestExterno: [
        "Sem Acabamento",
        "Argamassa",
        "Pintura",
        "Aparente",
        "Cerâmico",
        "Pedra/Granito",
        "Especial"
    ],
    acesso: ["Permitido", "Não Permitido", "Imóvel Vazio"],
    pisoExterno: ["Terra", "Cimento", "Cerâmica", "Pedra/Granito"],
    conservacao: ["Má", "Regular", "Boa", "Ótimo/Novo"],
    instSanitaria: [
        "Não Atribuído",
        "Interna Completa",
        "Interna Simples",
        "Mais De Uma Interna"
    ],
    instEletrica: [
        "Não Atribuído",
        "Embutida",
        "Aparente",
        "Semi-embutida"
    ]
};


const TabUnidadeImobiliaria = ({ unidadedeDados }) => {
    return (
        <Box display="flex" flexDirection="column" gap={2} >
            <div>
                <h4>Unidade Imobiliária: {unidadedeDados.unidadeCod}</h4>
                <Box display="flex" flexDirection="row" gap={1}>
                    <Typography
                        gutterBottom
                        sx={{
                            color: 'text.secondary',
                            fontSize: 12,
                            marginBottom: 0, // Definindo diretamente o margin-bottom no sx
                        }}
                    >
                        Id/Reduzido: {unidadedeDados.id}
                    </Typography>
                    <Typography
                        gutterBottom
                        sx={{
                            color: 'text.secondary',
                            fontSize: 12,
                            marginBottom: 0, // Definindo diretamente o margin-bottom no sx
                        }}
                    >
                        /
                    </Typography>
                    <Typography
                        gutterBottom
                        sx={{
                            color: 'text.secondary',
                            fontSize: 12,
                            marginBottom: 0, // Definindo diretamente o margin-bottom no sx
                        }}
                    >
                        UnidadeCod: {unidadedeDados.loteCod}
                    </Typography>
                </Box>

            </div>
            <Box display="flex" flexDirection="row" gap={2}>
                <TextField select label="Utilização" sx={{ flexBasis: '100%' }}>
                    {options.utilizacao.map(option => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField select label="Tipo" sx={{ flexBasis: '100%' }}>
                    {options.tipo.map(option => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>

            </Box>

            <Box display="flex" flexDirection="row" gap={2}>
                <TextField select label="Alinhamento" fullWidth>
                    {options.alinhamento.map(option => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField select label="Posição" fullWidth>
                    {options.posicao.map(option => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>

            <Box display="flex" flexDirection="row" gap={2}>
                <TextField select label="Classificação" fullWidth>
                    {options.classificacao.map(option => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField select label="Piso Externo" fullWidth>
                    {options.pisoExterno.map(option => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>

        </Box>
    );
};

export default TabUnidadeImobiliaria;
