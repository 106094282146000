import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPen, faPlus, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import '../GerenciaUsuarios/GerenciarUsuarios.css'
import { Api } from "../../services/Api";
import { Button, Fab, FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import ModalEditarUsuario from "./ModalEditarUsuario/ModaEditarUsuario";
import ModalNovoUsuario from "./ModalNovoUsuario/ModalNovoUsuario";


function GerenciarUsuarios() {
    const [modalAbertoEdit, setModalAbertoEdit] = useState(false);
    const [modalAbertoNew, setModalAbertoNew] = useState(false);


    const [nome, setNome] = useState("");
    const [cpf, setCpf] = useState("");
    const [tipoUsuario, setTipoUsuario] = useState("");
    const [searching, setSearching] = useState(false);
    const [usuariosLista, setUsuariosLista] = useState([]);
    const [usuariosFiltrados, setUsuariosFiltrados] = useState([]);
    const [editUser, setEditUser] = useState("");

    useEffect(() => async () => {
        getUsers();
    }, []);

    async function getUsers() {
        try {
            setSearching(true);
            const response = await Api.getUsers(19);
            setUsuariosLista(response.data.result.items);
            setUsuariosFiltrados(response.data.result.items);
        } catch (error) {
            console.log(error);
        }
        setSearching(false);
    };

    async function deleteUsers(id) {
        try {
            const response = await Api.deleteUsers(id);
            const totalUsuarios = parseInt(localStorage.getItem("infoTotalUsuarios")) - 1;
            localStorage.setItem("infoTotalUsuarios", totalUsuarios);
            getUsers();
        } catch (error) {

        }
    };

    const atualizar = () => {
        getUsers();
    };

    async function getUsersId(id) {

        const response = await Api.getUsersId(id);
        if (!response.error) {
            setEditUser(response.data[0]);
            setModalAbertoEdit(true);
        }

    };

    function filter() {
        const tipoFiltrados = usuariosLista.filter((usuario) =>
            (tipoUsuario === "" || usuario.role === tipoUsuario)
        );
        const nomeFiltrados = tipoFiltrados.filter((usuario) =>
            usuario.name.toLowerCase().startsWith(nome.toLowerCase())
        );
        const cpfFiltrados = nomeFiltrados.filter((usuario) =>
            usuario.cpf.includes(cpf)
        );
        let usuariosFiltrados = [];
        if (cpfFiltrados.length > 0) {
            usuariosFiltrados = cpfFiltrados;
        } else if (nomeFiltrados.length > 0) {
            usuariosFiltrados = nomeFiltrados;
        } else {
            usuariosFiltrados = tipoFiltrados;
        }
        setUsuariosFiltrados(usuariosFiltrados);
    };


    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            filter();
        }
    };
    const formataCpf = (valor) => {
        valor = valor.replace(/\D/g, '');
        let valorFormatado = '';
        for (let i = 0; i < valor.length; i++) {
            if (i === 3 || i === 6) {
                valorFormatado += '.';
            }
            if (i === 9) {
                valorFormatado += '-';
            }
            valorFormatado += valor[i];
            if (i >= 10) {
                break;
            }
        }
        return valorFormatado;
    };
    const cpfChange = (event) => {
        const numero = event.target.value;
        const valorFormatado = formataCpf(numero);
        setCpf(valorFormatado);
    };
    const selectChange = (event) => {
        const valor = event.target.value;
        setTipoUsuario(valor);
    };

    return (
        <div className="main-container-gerenciarUsuarios">
            <div className="container-gerenciarUsuarios" style={{ padding: 10 }}>
                <div className="conteudo-gerenciarUsuarios">
                    <TextField
                        className="input-gerenciarUsuarios"
                        label="Nome"
                        variant="outlined"
                        size="small"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        sx={{ margin: '5px', backgroundColor: "white" }}
                        onKeyPress={(event) => handleKeyPress(event)}
                        autoComplete="off"
                    />
                </div>

                <div className="conteudo-gerenciarUsuarios">
                    <FormControl sx={{ width: '100%', margin: '5px', backgroundColor: "white" }} size="small">
                        <InputLabel>Tipo de Usuário</InputLabel>
                        <Select
                            label="Tipo de Usuário"
                            value={tipoUsuario}
                            onChange={selectChange}
                        >
                            <MenuItem value="">Tipo de Usuário...</MenuItem>
                            <MenuItem value="admin">Administrador</MenuItem>
                            <MenuItem value="Assistente">Assistente</MenuItem>
                            <MenuItem value="collector">Coletor</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        className="input-gerenciarUsuarios"
                        label="CPF"
                        variant="outlined"
                        size="small"
                        value={cpf}
                        onChange={cpfChange}
                        sx={{ margin: '5px', backgroundColor: "white" }}
                        onKeyPress={(event) => handleKeyPress(event)}
                        autoComplete="off"
                    />
                </div>

                <div className="conteudo-lista-gerenciarUsuarios" >
                    <TableContainer component={Paper} sx={{ height: '100%' }}>
                        <Table>
                            <TableHead >
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2', width: '80px' }}>Tipo</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2', width: '80px' }}>Nome</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2', width: '80px' }}>CPF</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2', width: '80px' }}>Email</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2', width: '80px' }}></TableCell>
                                    <TableCell style={{ fontWeight: 'bold', borderBottom: '2.5px solid #1976D2', width: '80px' }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usuariosFiltrados.map((usuario, index) => (
                                    <TableRow key={index} >
                                        <TableCell sx={{ minWidth: '30px', maxWidth: '80px' }}>{usuario.role}</TableCell>
                                        <TableCell sx={{ minWidth: '30px', maxWidth: '80px' }}>{usuario.name}</TableCell>
                                        <TableCell sx={{ minWidth: '30px', maxWidth: '80px' }}>{usuario.cpf}</TableCell>
                                        <TableCell sx={{ minWidth: '30px', maxWidth: '80px' }}>{usuario.email}</TableCell>
                                        <TableCell sx={{ minWidth: '30px', maxWidth: '80px' }}></TableCell>
                                        <TableCell sx={{ minWidth: '30px', maxWidth: '80px' }}>
                                            {usuario.role === "admin" ? (
                                                <Button style={{ minWidth: '10px', marginLeft: '55.6px', borderColor: 'red', color: 'red' }} variant="outlined" onClick={() => deleteUsers(usuario.id)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </Button>
                                            ) : (
                                                <>
                                                    <Button style={{ minWidth: '10px' }} variant="outlined" onClick={() => getUsersId(usuario.id)}>
                                                        <FontAwesomeIcon icon={faPen} />
                                                    </Button>
                                                    <Button style={{ minWidth: '10px', marginLeft: '10px', borderColor: 'red', color: 'red' }} variant="outlined" onClick={() => deleteUsers(usuario.id)}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>

            <div className="barra-direita-gerenciarUsuarios">
                <Fab sx={{ margin: '5px', marginBottom: '-10px', fontSize: '20px' }} color="primary" onClick={filter}>
                    {searching ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    )}
                </Fab>
                <Fab sx={{ margin: '5px', marginBottom: '-10px', fontSize: '20px' }} color="primary" onClick={() => setModalAbertoNew(true)} >
                    <FontAwesomeIcon icon={faPlus} />
                </Fab>
            </div>
            {modalAbertoEdit && (
                <section style={{ position: 'relative' }}>
                    <ModalEditarUsuario atualizar={atualizar} onClose={() => setModalAbertoEdit(false)} editUser={editUser} />
                </section>
            )}
            {modalAbertoNew && (
                <section style={{ position: 'relative' }}>
                    <ModalNovoUsuario atualizar={atualizar} onClose={() => setModalAbertoNew(false)} />
                </section>
            )}

        </div>
    );
} export default GerenciarUsuarios;