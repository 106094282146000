import React, { useContext, useEffect, useState } from "react";

import { Box, Button, Card, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { InputComponent, SelectComponent } from './Inputs';
import { MapContextAdv } from "../Context/FuncoesMapa";
import './ColunaDados.css';
import { InputContext } from "../Context/ContextInputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { InputCod } from "./InputCod";
import { InputDados } from "./InputDados";

export function ColunaDados(props) {
    const [loading, setLoading] = useState(true);
    const [dataAdversidade, setDataAdversidade] = useState("");
    const [formData, setFormData] = useState({});
    const { colorsTestadas, setTestadas, testadas, coberturas, setCoberturas } = useContext(MapContextAdv)

    const {
        handleChangeNumero,
        handleChangeSelect,
        handleChangeRadioGroup,
        validateForm,
        selectValue,
        setNumero,
        numero,
        valueRadioGroup,
        errors,
        handleSubmitData,
        setTestadasIds
    } = useContext(InputContext)

    useEffect(() => {
        async function fetchDataAndSetState() {
            console.log(props.responseAdversidade);
            
            try {
                setLoading(true)
                const response = props.responseAdversidade;
                setDataAdversidade(response);
                // setNumero(response.endereco.numero);
                const logradouroApi = response.testadas.map(logNome => { return logNome });

                console.log(response);
                setTestadas(logradouroApi);
                setTestadasIds(response.testadasIds)
                setCoberturas(response.coberturas);
                setLoading(false)
            } catch (error) {
                console.error(error);
            }
        }

        fetchDataAndSetState();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {

            setFormData({
                numero: numero,
                tipo: selectValue,
                logradouro: valueRadioGroup,
                distritoCod: dataAdversidade?.lote?.distritoCod,
                setorCod: dataAdversidade?.lote?.setorCod,
                quadraCod: dataAdversidade?.lote?.quadraCod,
                loteCod: dataAdversidade?.lote?.loteCod,
                cpfCnpj: dataAdversidade?.proprietarios[0]?.cpfCnpj,
                nome: dataAdversidade?.endereco?.nome,
                bairro: dataAdversidade?.endereco?.bairro
            })
            console.log('Form data:', {
                numero: numero,
                tipo: selectValue,
                logradouro: parseInt(valueRadioGroup),
                distritoCod: dataAdversidade?.lote?.distritoCod,
                setorCod: dataAdversidade?.lote?.setorCod,
                quadraCod: dataAdversidade?.lote?.quadraCod,
                loteCod: dataAdversidade?.lote?.loteCod,
                cpfCnpj: dataAdversidade?.proprietarios[0]?.cpfCnpj,
                nome: dataAdversidade?.endereco?.nome,
                bairro: dataAdversidade?.endereco?.bairro
            });
        }
    };

    return (
        <div className="colunaDados-flex-column">
            <Card variant="outlined" style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '20px', alignItems: 'center', padding: '5px' }}>
                <h5 className="AdvEndDados-h5">LOTE SEM REGISTRO NO CADASTRO DA PREFEITURA</h5>

                <Divider style={{ margin: '5px' }} />

                <InputCod dataAdversidade={dataAdversidade} />
                <InputDados dataAdversidade={dataAdversidade} />
                <div className="colunaDados-form-row">
                    <Button className="colunaDados-button" onClick={() => handleSubmitData(dataAdversidade.adversidadeId)} variant="contained" >
                        <FontAwesomeIcon icon={faFloppyDisk} />Salvar
                    </Button>
                </div>
            </Card>
        </div >
    )
}
