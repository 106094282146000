import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; import { Api } from "../../../../services/Api.js";
import '../ModalEdificacao/ModalEdificacao.css';

function ModalEdificacao({ onClose }) {
    const options = [
        { value: "", label: "None" },
        { value: "Áreas públicas", label: "Áreas públicas" },
        { value: "Associações", label: "Associações" },
        { value: "Banco", label: "Banco" },
        { value: "Cemitério", label: "Cemitério" },
        { value: "Clube de serviço/social", label: "Clube de serviço/social" },
        { value: "Delegacia", label: "Delegacia" },
        { value: "Educação", label: "Educação" },
        { value: "Hotel/Pousada", label: "Hotel/Pousada" },
        { value: "Indústria", label: "Indústria" },
        { value: "Instalações da administração pública", label: "Instalações da administração pública" },
        { value: "Instalação militar", label: "Instalação militar" },
        { value: "Loja maçônica", label: "Loja maçônica" },
        { value: "Religioso", label: "Religioso" },
        { value: "Saúde", label: "Saúde" },
        { value: "Sindicato", label: "Sindicato" },
    ];

    const [objPontoInteresse, setObjPontoInteresse] = useState({
        nome: "",
        tipo: "",

    });

    const [searching, setSearching] = useState(false);
    const [resultReturned, setResultReturned] = useState(false);


    const InputChange = (event) => {
        const name = event.target.name;
        const valor = event.target.value;
        setObjPontoInteresse(valores => ({ ...valores, [name]: valor }));
    };

    const submitForm = async (event) => {
        event.preventDefault();
    };


    return (
        <div className="modalEdificacao" onClick={onClose}>
            <div className="modalEdificacao-content" onClick={(e) => e.stopPropagation()}>
                <div className="modalEdificacao-close-bnt">
                    <Button
                        aria-label="Fechar"
                        style={{ padding: 0, minWidth: 0 }}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </Button>
                </div>
                <h2 className="modalEdificacao-titulo">Ponto de Interesse</h2>
                <form onSubmit={submitForm}>

                    <div className="modalEdificacao-form-group">
                        <label htmlFor="pontoInteresse" className="modalEdificacao-label">
                            Ponto de Interesse:
                        </label>
                        <select
                            className="modalEdificacao-select"
                            name="tipo"
                            value={objPontoInteresse.tipo}
                            onChange={InputChange}
                            required
                        >
                            {options.map((option) => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="Nome" className="modalEdificacao-label">
                            Nome:
                        </label>
                        <input
                            className="modalEdificacao-input"
                            type="text"
                            name="nome"
                            value={objPontoInteresse.nome}
                            onChange={InputChange}
                            required
                            autoComplete="off"
                        />
                    </div>

                    <div className="form-row">
                        <Button
                            style={{ width: "100%", marginTop: "10px" }}
                            variant="contained"
                        >
                            <FontAwesomeIcon
                                icon={faFloppyDisk}
                                style={{ marginRight: "0.5em" }}
                            />
                            Salvar
                        </Button>
                    </div>

                </form>
            </div>
        </div>

    );
};

export default ModalEdificacao;
