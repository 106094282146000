import React, { useContext, useEffect, useState } from "react";

import { Box, Button, Card, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { InputComponent, SelectComponent } from './Inputs';
import { MapContextAdv } from "../Context/FuncoesMapa";
import './InputDados.css';
import { InputContext } from "../Context/ContextInputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

export function InputDados(props) {
    const [loading, setLoading] = useState(true);
    const [dataAdversidade, setDataAdversidade] = useState("");
    const [optionsTestada, setOptionsTestada] = useState([{}]);
    const {
        colorsTestadas,
        setTestadas,
        testadas,
        coberturas,
        setCoberturas,
        colorsCoberturas,
        arrayLoteCorbeturas,
        coordenadasPoligonoTestadas
    } = useContext(MapContextAdv)

    const {
        numUnidades,
        setNumUnidades,
        numCodigo,
        setNumCodigo,
        selectedValueCobertura,
        setSelectedValueCobertura,
        handleChangeCobertura,
        handleChangeSelectLogradouro,
        handleChangeSelectTipo,
        selectValueLogradouro,
        selectValueTipo,
        formData,
        setFormData,
        AddUnidades,
        handleChangeData,
        errosValidacao
    } = useContext(InputContext)

    useEffect(() => {
        let array2D
        if (typeof AddUnidades === 'number' && AddUnidades > 0 && Number.isInteger(AddUnidades)) {

            array2D = new Array(parseInt(AddUnidades)).fill(null).map((item, index) => {

                const aux2 = new Array(coberturas.length).fill(null).map((_, indexCobertura) => {

                    const obj = {
                        cobertura: coberturas[indexCobertura].id,
                        checked: false,
                        id: indexCobertura,
                        idUnidades: indexCobertura,
                    };
                    return obj;
                })

                return aux2;
            });
        }
        responseDadosTestada()
        setSelectedValueCobertura(array2D);
    }, [AddUnidades]);

    const responseDadosTestada = () => {
        const logradouroTestada = testadas.map((dadosTestada, index) => {
            return { value: dadosTestada.logradouronome, label: dadosTestada.logradouronome }
        })
        setOptionsTestada(logradouroTestada)
    }

    return (
        <>
            {Array.from({ length: parseInt(AddUnidades) }, (_, indexNumUnidades) => (
                <form key={indexNumUnidades} className="InputDados-form">

                    <div className="InputDados-flex-1">
                        <h5 className="InputDados-margin-h5">Unidade {indexNumUnidades + 1}</h5>

                        <div className="InputDados-form-row">
                            <SelectComponent
                                label="Logradouro"
                                id="logradouro"
                                value={formData[indexNumUnidades]?.logradouro || ""}
                                onChange={(e) => handleChangeData(indexNumUnidades, e)}
                                options={optionsTestada}
                                errors={errosValidacao[indexNumUnidades]?.errors?.logradouro}
                            />

                            <InputComponent
                                label="Área"
                                id="area"
                                value={formData[indexNumUnidades]?.area || ""}
                                onChange={(e) => handleChangeData(indexNumUnidades, e)}
                                errors={errosValidacao[indexNumUnidades]?.errors?.area}
                            />
                        </div>
                        <div className="InputDados-form-row">
                            <InputComponent
                                label="N° de Inscrição reduzido"
                                id="numReduzido"
                                value={formData[indexNumUnidades]?.numReduzido || ""}
                                onChange={(e) => handleChangeData(indexNumUnidades, e)}
                                errors={errosValidacao[indexNumUnidades]?.errors?.numReduzido}
                            />
                            <InputComponent
                                label="Número"
                                id="numero"
                                value={formData[indexNumUnidades]?.numero || ""}
                                onChange={(e) => handleChangeData(indexNumUnidades, e)}
                                errors={errosValidacao[indexNumUnidades]?.errors?.numero}
                            />
                            <InputComponent
                                label="Complemento"
                                id="complemento"
                                value={formData[indexNumUnidades]?.complemento || ""}
                                onChange={(e) => handleChangeData(indexNumUnidades, e)}
                            />
                        </div>
                        <div className="InputDados-form-row">
                            <InputComponent
                                label="Bairro"
                                id="bairro"
                                value={formData[indexNumUnidades]?.bairro || ""}
                                onChange={(e) => handleChangeData(indexNumUnidades, e)}
                                errors={errosValidacao[indexNumUnidades]?.errors?.bairro}
                            />
                            <InputComponent
                                label="Apartamento"
                                id="apartamento"
                                value={formData[indexNumUnidades]?.apartamento || ""}
                                onChange={(e) => handleChangeData(indexNumUnidades, e)}
                            />
                            <InputComponent
                                label="Loteamento"
                                id="loteamento"
                                value={formData[indexNumUnidades]?.loteamento || ""}
                                onChange={(e) => handleChangeData(indexNumUnidades, e)}
                                errors={errosValidacao[indexNumUnidades]?.errors?.loteamento}
                            />
                        </div>

                        <Divider />

                        <h5 className="InputDados-margin-h5">Dados do Proprietário</h5>
                        <div className="InputDados-form-row">

                            <SelectComponent
                                label="Tipo"
                                id="tipo"
                                value={formData[indexNumUnidades]?.tipo || ""}
                                onChange={(e) => handleChangeData(indexNumUnidades, e)}
                                options=
                                {[
                                    { value: 'Pessoa Física', label: 'Pessoa Física' },
                                    { value: 'Pessoa Jurídica', label: 'Pessoa Jurídica' }
                                ]}
                                errors={errosValidacao[indexNumUnidades]?.errors?.proprietarioTipo}
                            />

                            <InputComponent
                                label="CPF/CNPJ"
                                id="cpfCnpj"
                                value={formData[indexNumUnidades]?.cpfCnpj || ""}
                                onChange={(e) => handleChangeData(indexNumUnidades, e)}
                                errors={errosValidacao[indexNumUnidades]?.errors?.proprietarioCpfCnpj}
                            />
                        </div>
                        <div className="InputDados-form-row">
                            <InputComponent
                                label="Nome"
                                id="nome"
                                value={formData[indexNumUnidades]?.nome || ""}
                                onChange={(e) => handleChangeData(indexNumUnidades, e)}
                                errors={errosValidacao[indexNumUnidades]?.errors?.proprietarioNome}
                            />

                            <InputComponent
                                label="Endereço"
                                id="endereco"
                                value={formData[indexNumUnidades]?.endereco || ""}
                                onChange={(e) => handleChangeData(indexNumUnidades, e)}
                                errors={errosValidacao[indexNumUnidades]?.errors?.proprietarioEndereco}
                            />
                        </div>
                    </div>

                    <Box
                        key={indexNumUnidades}
                        sx={{
                            width: '100%',
                            maxWidth: '300px',
                            padding: '10px',
                            marginTop: '20px'
                        }}
                    >
                        <Grid item xs={12} md={3}>
                            {coberturas.map((coberturaMap, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        width: '100%',
                                        border: (theme) => `1px solid ${theme.palette.divider}`,
                                        borderRadius: 1,
                                        bgcolor: 'background.paper',
                                        color: 'text.secondary',
                                        display: 'flex',
                                        alignItems: 'center',
                                        position: 'relative',
                                        flexDirection: 'column',
                                        maxWidth: '250px'
                                    }}
                                >
                                    <div className="advEnd-vectorization-info-box">
                                        <RadioGroup>
                                            <FormControlLabel
                                                checked={selectedValueCobertura?.[indexNumUnidades]?.[index].checked}
                                                control={<Radio />}
                                                label=""
                                                sx={{ height: '0', margin: '10px', marginLeft: '0' }}
                                                onClick={() => handleChangeCobertura(index, indexNumUnidades)}
                                            />
                                        </RadioGroup>
                                        <h5 style={{ margin: '15px', marginLeft: '45px' }}>Cobertura {coberturaMap.id}</h5>
                                        <div style={{ background: colorsCoberturas[index], height: '20px', width: '20px', margin: '15px', marginLeft: '0' }} />
                                    </div>
                                </Box>
                            ))}
                        </Grid>
                    </Box>

                </form>
            ))}
        </>
    )
}
