import Select from "ol/interaction/Select.js";
import { click } from "ol/events/condition.js";
import VectorLayerManeger from "./ConfigLayers";

class LayerObserver {
  constructor(manager) {
    this.manager = manager;
    this.vector = null;
    this.layer = null;
    this.toggleColor = null

    this.select = null;
    this.manager.addObserver(this);
  }

  setColor(color) {
    this.toggleColor = color
  }

  getLayers(type) {
    const filterLayerForType = this.manager.layers.filter((item) => {
      
      return item.TYPE === type;
    });
    return filterLayerForType;
  }

  getLayersRemoved(type) {
    const filterLayerForType = this.manager.layersRemovidos.filter((item) => {
      return item.TYPE === type;
    });
    return filterLayerForType;
  }

  addInteracitionVector(funcao, type) {
    if (funcao) {
      // Crie a nova interação com as propriedades personalizadas
      this.select = new Select({
        condition: click,
        style: this.selected,
        layers: [this.layer],
      });

      this.select.TYPE = type;

      this.select.on("select", (e) => {
        if (e.selected.length > 0) {
          e.color = e.selected[0].values_.COLOR;
          e.id = e.selected[0].values_.ID;
          funcao(e.selected[0].values_);
        }
      });
    }
  }

  activeInteractionVector(type) {
    const allInteractionsRemovidas = this.manager.interactionsRemovidas;
    const newLayers = allInteractionsRemovidas.filter((interaction) => {
      if (interaction.TYPE && interaction.TYPE === type) {
        this.manager.map.addInteraction(interaction);
        return true;
      }

      return false;
    });
    this.manager.interactionsRemovidas.push(newLayers);
  }

  removeInteractionVector(type) {
    const allInteractions = this.manager.map.getInteractions().array_;
    allInteractions.forEach((interaction) => {
      if (interaction.TYPE && interaction.TYPE === type) {
        this.manager.interactionsRemovidas.push(interaction);
        this.manager.map.removeInteraction(interaction);
      }
    });
  }

  addVectorLayer(type) {
    const layerAdd = this.getLayersRemoved(type);

    if (layerAdd) {
      layerAdd.forEach((item) => {
        this.manager.layers.push(item);
        const newLayersRemovidos = this.manager.layersRemovidos.filter(
          (layer) => layer.TYPE !== item.TYPE
        );
        this.manager.layersRemovidos = newLayersRemovidos;
        this.manager.map.addLayer(item);
      });
    } else {
      console.log("Layer não encontrada para adicionar layer:", type); // <-- Diagnóstico se a camada não for encontrada
    }
  }

  removeVectorLayer(type) {
    const layerRemove = this.getLayers(type);

    if (layerRemove) {
      layerRemove.forEach((item) => {
        this.manager.layersRemovidos.push(item);
        const newLayers = this.manager.layers.filter(
          (layer) => layer.TYPE !== item.TYPE
        );
        this.manager.map.removeLayer(item);
        this.manager.layers = newLayers;
      });
    } else {
      console.log("Layer não encontrada para retirar layer:", type); // <-- Diagnóstico se a camada não for encontrada
    }
  }

  newVector(type, datas, funcao, functionEdit) {
    this.vector = new VectorLayerManeger(type, datas, funcao, functionEdit);
    this.vector.configColor(this.toggleColor)
    this.vector.initLayer();


    const layer = this.vector.getLayer();
    let interaction;
    if (funcao) {
      interaction = this.vector.getInteraction();
    }
    this.manager.addLayer(layer);
    if (funcao) {
      this.manager.map.addInteraction(interaction);
    }
  }
}

export default LayerObserver;
