import { createContext, useState } from 'react';

const InputContext = createContext();

const ContextProviderInput = ({ children }) => {
    const [numero, setNumero] = useState('');
    const [selectValue, setSelectValue] = useState('');
    const [valueRadioGroup, setValueRadioGroup] = useState('');
    const [errors, setErrors] = useState({
        numero: '',
        select: '',
        valueRadioGroup: ''
    });

    const handleChangeNumero = (e) => {
        const { value } = e.target;
        if (/^\d*$/.test(value)) { // Permite apenas números
            setNumero(value);
            setErrors((prevErrors) => ({ ...prevErrors, numero: '' }));
        }
    };

    const handleChangeSelect = (e) => {
        setSelectValue(e.target.value);
        setErrors((prevErrors) => ({ ...prevErrors, select: '' }));
    };

    const handleChangeRadioGroup = (event) => {
        if (valueRadioGroup === event.target.value) {
            setValueRadioGroup('');
        }
        else
            setValueRadioGroup(event.target.value);
    };

    const validateForm = () => {
        let valid = true;
        let newErrors = {};

        if (!numero) {
            newErrors.numero = 'Esse campo é obrigatório!';
            valid = false;
        }

        if (!selectValue) {
            newErrors.select = 'Esse campo é obrigatório!';
            valid = false;
        }

        if (!valueRadioGroup) {
            newErrors.valueRadioGroup = 'Selecione um logradouro!';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    return (
        <InputContext.Provider
            value={{
                errors, 
                setErrors, 
                handleChangeNumero, 
                handleChangeSelect, 
                validateForm, 
                selectValue, 
                numero, 
                setNumero, 
                valueRadioGroup, 
                setValueRadioGroup, 
                handleChangeRadioGroup
            }}
        >
            {children}
        </InputContext.Provider>
    );
};

export { ContextProviderInput, InputContext };