import React, { useContext, useEffect, useState } from "react";

import { Box, Card, FormControlLabel, Switch } from "@mui/material";
import { MapContextAdv } from "../Context/FuncoesMapa";
import { Api } from "../../../../services/Api"
import './ColunaMapa.css';

export function ColunaMapa(props) {
    const {
        colorsTestadas,
        testadas,
        setCoberturas,
        coberturas,
        configurarCoordenadaCentral,
        configurarCoordenada,
        setCoordenadasPoligono,
        setCoordenadasLoteVizinho,
        setCoordenadasPoligonoTestadas,
        setCoordenadasPoligonoCoberturas,
        setRadioButtonState,
        extractCoordinates,
    } = useContext(MapContextAdv)
    const [state, setState] = useState({
        "lotes": true,
        "testadas": true,
        "lotesVizinhos": true,
        "coberturas": true,
    });

    const handleChange = (event) => {
        const { name, checked } = event.target;
        // Atualizar o estado dos checkboxes
        setState((prevState) => ({ ...prevState, [name]: checked }));
        // Atualizar radioButtonState adicionando ou removendo o nome do checkbox
        setRadioButtonState((prevState) => {
            if (checked) {
                // Adicionar o nome do checkbox se estiver marcado
                return [...prevState, name];
            } else {
                // Remover o nome do checkbox se estiver desmarcado
                return prevState.filter((item) => item !== name);
            }
        });
    };

    useEffect(() => {
        const configurarMapa = async () => {
            try {
                configurarCoordenadaCentral(props.responseAdversidade.pontoCentral[0].geom.coordinates)
                const configCoordenadasLote = props.responseAdversidade.adversidade.geom.coordinates;
                const coordenadasLote = configCoordenadasLote.flatMap((item) => {
                    return item
                })
                const coordendasTestadas = props.responseAdversidade.testadas.flatMap((item) => {
                    return item.geom.coordinates
                })
                const coordendasCoberturas = props.responseAdversidade.coberturas.flatMap((item) => {
                    return item.geom.coordinates
                })
                const configCoordenadasLoteVizinho = props.responseAdversidade.lotesV.flatMap((item) => item.geom.coordinates)

                const coordenadasLoteConfigurada = configurarCoordenada(coordenadasLote)
                const coordenadasLoteVizinhoConfigurada = configurarCoordenada(extractCoordinates(configCoordenadasLoteVizinho));
                const coordendasTestadasConfiguradas = configurarCoordenada(coordendasTestadas)
                const coordendasCoberturasConfiguradas = configurarCoordenada(coordendasCoberturas)
                console.log(coordendasCoberturasConfiguradas);
                setCoordenadasPoligono(coordenadasLoteConfigurada)
                setCoordenadasLoteVizinho([coordenadasLoteVizinhoConfigurada])
                setCoordenadasPoligonoTestadas(coordendasTestadasConfiguradas)
                setCoordenadasPoligonoCoberturas(coordendasCoberturasConfiguradas)
            } catch (error) {
                console.error("Erro ao obter dados de coleta:", error);
            }
        };

        configurarMapa();
    }, []);




    const { mapRef } = useContext(MapContextAdv)

    return (
        <div style={{ width: '100%', flex: 1 }} className="MesmoLote-vectorization-container">
            <div className="MesmoLote-vectorization-header">
                <div className="MesmoLote-vectorization-content">
                    <Box sx={{ display: 'flex', alignItems: 'end', width: '100%', bgcolor: 'background.paper', border: '1px solid black', padding: '10px', color: 'text.secondary', '& svg': { m: 0.9, }, '& hr': { mx: 0.9, }, maxWidth: '400px' }}>
                        <div className="vectorization-switch-container">
                            <h5 className="vectorization-title">GEOMETRIAS IDENTIFICADA NA VETORIZAÇÃO:</h5>
                            <FormControlLabel
                                control={<Switch checked={state.lotes} onChange={handleChange} name="lotes" />}
                                label="Mostrar lotes"
                            />
                            <FormControlLabel
                                control={<Switch checked={state.testadas} onChange={handleChange} name="testadas" />}
                                label="Mostrar testadas"
                            />
                            <FormControlLabel
                                control={<Switch checked={state.lotesVizinhos} onChange={handleChange} name="lotesVizinhos" />}
                                label="Mostrar lotes vizinhos"
                            />
                            <FormControlLabel
                                control={<Switch checked={state.coberturas} onChange={handleChange} name="coberturas" />}
                                label="Mostrar área coberta"
                            />
                        </div>
                    </Box>

                    <div className="MesmoLote-vectorization-info-box">
                        <div style={{ background: 'rgba(19,100,181, 1)' }} />
                        <h5>Lote</h5>
                    </div>
                    <div className="MesmoLote-vectorization-info-box">
                        <div style={{ background: 'rgba(166,166,166, 1)' }} />
                        <h5>Lotes Vizinhos</h5>
                    </div>

                    {testadas.map((item, index) => {
                        return (
                            <div className="AdvMapa-vectorization-info-box" key={item.id}>
                                <div style={{ background: colorsTestadas[index], height: '20px', width: '20px' }} />
                                <h5>{item.logradouronome}</h5>
                            </div>
                        )
                    })}

                </div>
                <Card variant="outlined" sx={{ width: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <div style={{ width: '100%' }}>
                            <div className="MesmoLote-vectorization-map-container">
                                <div ref={mapRef} className="MesmoLote-vectorization-map" />
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>

    )
}
