import React, { useRef, useEffect, useState } from 'react';
import { ModalLote } from '../ModalLote/ModalLote.jsx';
import { Api } from '../../../../services/Api.js';
import ManegerVectorLayer from '../../../../Map/Component/VectorManager.js';
import { Button, Divider } from '@mui/material';
import { FunctionAlocar } from '../Sidebar/FunctionAlocar.jsx';
import { Legenda } from '../Sidebar/Legenda.jsx';
import { SetoresFiltros } from '../Sidebar/SetoresFiltros.jsx';
import { Filtros } from '../Sidebar/Filtros.jsx';
import { EixosInputs } from '../Sidebar/EixosInputs.jsx';
import { ModalColeta } from '../ModalColeta/ModalColeta.jsx';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { configurarCoordenadaCentral, calcularCentroide } from '../../../../Map/Hooks/FormaterCoordinates.jsx';

const MapaColetas = ({ toggleEstadoModal, camadas }) => {
    const mapManger = useRef(null);
    const vectorManeger = useRef();
    const [onClose, setOnClose] = useState(false);
    const [dadosQuadra, setDadosQuadra] = useState(null);
    const [checkBox, setCheckBox] = useState([]);
    const [loteResult, setLoteResult] = useState([]);
    const [current, setCurrent] = useState('Setor');

    const editColor = (item) => {
        if (item.flag === 1) { return "rgb(19,100,181, 0.8)" }
        if (item.flag === 2) { return "rgba(114,178,137, 0.8)" }
        else { return "rgb(229, 115, 115, 0.8)" }
    }

    useEffect(() => {
        const fetch = async () => {
            try {
                const response = await Api.getMarchaUrbana();
                const resultResponse = response.data.manchaUrbana
                const coordendaCentral = configurarCoordenadaCentral(resultResponse.ponto_central.coordinates)
                if (!vectorManeger.current) {
                    vectorManeger.current = new ManegerVectorLayer(coordendaCentral);
                    vectorManeger.current.setMapElement(mapManger.current);
                }
                const ArrayDadosMancha = { coordenadas: [resultResponse.geom.coordinates], id: { manchaCod: resultResponse.geo_id } }
                // Colocado Eixo por que é geometria por Line
                vectorManeger.current.iniciarVectorLayer('Eixo', ArrayDadosMancha, null, (item) => 'red');
            } catch (error) { console.log('Erro ao buscar mancha:', error) }
        }
        fetch()
        requestSetores()
    }, []);

    const requestSetores = async () => {
        try {
            const response = await Api.getMapaColeta();
            const resultResponse = response.data.setores.map((item) => {
                return { coordenadas: item.geom.coordinates, id: { cod: item.cod } }
            });
            vectorManeger.current.iniciarVectorLayer('Setor', resultResponse, (item) => requestQuadras(item.ID.cod));
        } catch (error) {
            console.log('Erro ao buscar lotes:', error);
        }

        // Atualiza o estado apenas se a checkbox ainda não estiver marcada
        setCheckBox((prev) => {
            if (!prev.some(item => item.checked)) {
                return [...prev, { checked: true }];
            }
            return prev; // Não altera se já houver uma checkbox marcada
        });
        setCurrent("Setor");
    };
    const requestQuadras = async (setorCod) => {
        setCurrent("Quadra")
        vectorManeger.current.removerVectorLayer('Setor')
        const fuctionQuadra = (item) => {
            if (item.ID.flag > 0) {
                requestEixos(item.ID.id)
                requestLotes(item.ID.id)
                setCurrent("Lote")
                setDadosQuadra(item.ID)
            } else {
                setDadosQuadra(item.ID)
                setCurrent("QuadraSelect")
            }
        }
        try {
            const response = await Api.getQuadrasCod(setorCod);
            const resultResponse = response.data.quadras.map((item) => {
                return {
                    coordenadas: item.geom.coordinates, id: {
                        id: item.id, flag: item.flag,
                        cod: item.cod, setorCod: setorCod
                    }
                }
            });
            vectorManeger.current.setCenterElement(configurarCoordenadaCentral(calcularCentroide(resultResponse[0].coordenadas[0][0])))
            vectorManeger.current.setZoomElement(14)
            vectorManeger.current.iniciarVectorLayer('Quadra', resultResponse, fuctionQuadra, editColor);
        } catch (error) { console.log('Erro ao buscar lotes:', error) }
    };

    const requestLotes = async (idQuadra) => {
        vectorManeger.current.removerVectorLayer('Quadra')
        try {
            const response = await Api.getLotesForQuadra(idQuadra);
            const resultResponse = response.data.lotes;
            if (resultResponse.length === 0) {
                alert("Lotes não encontrado!");
            }

            const dados = resultResponse.map((item) => { return { coordenadas: item.geom.coordinates, id: { id: item.id, flag: item.flag, geom: item.geom.coordinates } } })
            vectorManeger.current.setCenterElement(configurarCoordenadaCentral(calcularCentroide(dados[0].coordenadas[0][0])))
            vectorManeger.current.setZoomElement(16)
            const callLote = (item) => {
                setLoteResult(item)
                setOnClose(true)
            }
            vectorManeger.current.iniciarVectorLayer('Lote', dados, callLote, editColor);
        } catch (error) { console.log('Erro ao buscar lotes:', error) }
    };

    const requestEixos = async (idQuadra) => {
        vectorManeger.current.removerVectorLayer('Quadra')
        try {
            const response = await Api.getEixosForQuadra(idQuadra);
            const resultResponse = response.data.eixos;
            const dadods = resultResponse.map((item) => { return { coordenadas: [item.geom.coordinates], id: item.id } })
            vectorManeger.current.iniciarVectorLayer('Eixo', dadods, null, () => 'black');
        } catch (error) { console.log('Erro ao buscar lotes:', error) }
    };

    // Função para alterar o estado do checkbox
    const handleCheckboxChange = (index) => {
        if (checkBox[index].checked === true) { vectorManeger.current.removerVectorLayer('Setor') }
        else { vectorManeger.current.adicionarVectorLayer('Setor') }
        setCheckBox((prev) => prev.map((item, i) => (i === index ? { ...item, checked: !item.checked } : item)));
    };

    const toggleLayer = () => {
        if (current === 'Quadra') {
            vectorManeger.current.setZoomElement(11)
            vectorManeger.current.removerVectorLayer('Lote')
            vectorManeger.current.removerVectorLayer('Eixo')
            vectorManeger.current.removerVectorLayer('Quadra')
            vectorManeger.current.adicionarVectorLayer('Setor')
            setCurrent('Setor')
        }
        if (current === 'QuadraSelect') {
            setCurrent('Quadra')
        }
        if (current === 'Lote') {
            vectorManeger.current.setZoomElement(16)
            vectorManeger.current.removerVectorLayer('Lote')
            vectorManeger.current.removerVectorLayer('Eixo')
            vectorManeger.current.adicionarVectorLayer('Quadra')
            setCurrent('Quadra')
        }
    }

    const resetMapQuadra = (setorCod) => {
        vectorManeger.current.removerVectorLayer('Lote')
        vectorManeger.current.removerVectorLayer('Eixo')
        vectorManeger.current.removerVectorLayer('Quadra')
        requestQuadras(setorCod)
    }
    return (
        <div style={{ display: 'flex', width: '100%', height: '100%' }}>
            <div style={{ width: 300, height: '100%', overflowY: 'auto' }}>
                <div> {!(current === 'Setor') && <Button style={{ marginTop: 10, marginInline: 10 }} onClick={toggleLayer}>voltar</Button>} </div>
                <Divider style={{ marginBlock: 10, marginInline: 10 }} />

                {current === 'Setor' && <>
                    Selecione o Setor
                    <SetoresFiltros indiceSetores={checkBox.length} checkBox={checkBox} handleCheckboxChange={handleCheckboxChange} />
                    <Divider style={{ marginBlock: 10, marginInline: 10 }} />
                </>}
                {!(current === 'Setor') && <Legenda />}
                {current === "Quadra" && <>Selecione a Quadra</>}
                {(current === 'QuadraSelect' && dadosQuadra) && <div style={{ height: '50%' }}> <ModalColeta dadosQuadra={dadosQuadra} resetMapQuadra={resetMapQuadra} /> </div>}

                {current === "Lote" && <>Selecione o Lote</>}
                {(current === 'Lote' && dadosQuadra) && <div style={{ height: '50%' }}> <ModalColeta dadosQuadra={dadosQuadra} resetMapQuadra={resetMapQuadra} /> </div>}

                {/* <FunctionAlocar /> */}

                {/* Passar o estado e a função de alteração */}
                {/* <Filtros /> */}

            </div>
            <div style={{ display: 'flex', width: '100%', height: '100%' }} ref={mapManger} />
            {onClose && <ModalLote open={onClose} onClose={() => setOnClose(false)} returnLoteColeta={loteResult} />}
        </div>
    );
};

export default MapaColetas;
