
import { React, useState } from "react";
import "./Inconsistencias.css";
import { jsPDF } from "jspdf";
import {
  TextField,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fab,
  Modal,
  Box,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faSpinner,
  faPrint,
  faEnvelope,
  faCheck,
  faQuestion,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

const Inconsistencias = () => {
  const [selectedMunicipio, setSelectedMunicipio] = useState("");
  const [selectedRegra, setSelectedRegra] = useState("");
  const [showBairro, setShowBairro] = useState(false);
  const [selectedBairro, setSelectedBairro] = useState("");
  const [showIDLote, setShowIDLote] = useState(false);
  const [selectedID, setSelectedID] = useState("");
  const [showInscricaoImovel, setShowInscricaoImovel] = useState(false);
  const [selectedInscricao, setSelectedInscricao] = useState("");
  const [showAlteracaoAreaTotal, setShowAlteracaoAreaTotal] = useState(false);
  const [searching, setSearching] = useState(false);
  const [showData, setShowData] = useState(false);
  const [usuariosSelecionados, setUsuariosSelecionados] = useState([]);
  const [showHelp, setShowHelp] = useState(false);
  const [areaMaior, setAreaMaior] = useState("");
  const [areaMenor, setAreaMenor] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [selecionar, setSelecionar] = useState(false);

  const dados = [
    {
      nome: "João da Silva",
      logradouro: "Rua das Margaridas",
      bairro: "Centro",
      id: "9876",
      inscricao: "62548193",
      regra: "Alteração da área total",
      tamanho: "28",
      novo_tamanho: "23",
    },
    {
      nome: "Carla Oliveira",
      logradouro: "Rua dos Lírios",
      bairro: "Pinheirinho",
      id: "5432",
      inscricao: "75312468",
      regra: "Alteração da área total",
      tamanho: "20",
      novo_tamanho: "24",
    },
    {
      nome: "Fernando Costa",
      logradouro: "Rua dos Girassóis,",
      bairro: "Cruzeiro",
      id: "1111",
      inscricao: "48261359",
      regra: "Nova unidade imobiliária",
      tamanho: "19",
      novo_tamanho: "-",
    },
    {
      nome: "Patrícia Santos",
      logradouro: "Rua das Hortênsias",
      bairro: "Estiva",
      id: "9999",
      inscricao: "94683520",
      regra: "Nova unidade imobiliária",
      tamanho: "21",
      novo_tamanho: "-",
    },
    {
      nome: "Lucas Souza",
      logradouro: "Rua das Orquídeas",
      bairro: "Boa Vista",
      id: "7777",
      inscricao: "31892745",
      regra: "Alteração da área total",
      tamanho: "29",
      novo_tamanho: "-",
    },
    {
      nome: "Ana Oliveira",
      logradouro: "Rua das Rosas",
      bairro: "Centro",
      id: "4567",
      inscricao: "68123147",
      regra: "Alteração da área total",
      tamanho: "11",
      novo_tamanho: "-",
    },
    {
      nome: "Rodrigo Lima",
      logradouro: "Rua das Azaléias",
      bairro: "Pinheirinho",
      id: "6543",
      inscricao: "20971548",
      regra: "Nova unidade imobiliária",
      tamanho: "24",
      novo_tamanho: "-",
    },
    {
      nome: "Mariana Silva",
      logradouro: "Rua das Begônias",
      bairro: "Cruzeiro",
      id: "2222",
      inscricao: "57281436",
      regra: "Nova unidade imobiliária",
      tamanho: "21",
      novo_tamanho: "-",
    },
    {
      nome: "Pedro Almeida",
      logradouro: "Rua das Violetas",
      bairro: "Estiva",
      id: "3333",
      inscricao: "72519384",
      regra: "Alteração da área total",
      tamanho: "20",
      novo_tamanho: "29",
    },
    {
      nome: "Camila Santos",
      logradouro: "Rua das Tulipas",
      bairro: "Boa Vista",
      id: "8888",
      inscricao: "41283756",
      regra: "Nova unidade imobiliária",
      tamanho: "30",
      novo_tamanho: "-",
    },
    {
      nome: "Gustavo Oliveira",
      logradouro: "Rua dos Cravos",
      bairro: "Centro",
      id: "5555",
      inscricao: "93647281",
      regra: "Nova unidade imobiliária",
      tamanho: "15",
      novo_tamanho: "-",
    },
    {
      nome: "Isabela Lima",
      logradouro: "Rua das Camélias",
      bairro: "Cruzeiro",
      id: "4444",
      inscricao: "62839475",
      regra: "Alteração da área total",
      tamanho: "22",
      novo_tamanho: "27",
    },
    {
      nome: "Rafaela Costa",
      logradouro: "Rua das Dálias",
      bairro: "Estiva",
      id: "6666",
      inscricao: "15937284",
      regra: "Alteração da área total",
      tamanho: "20",
      novo_tamanho: "27",
    },
    {
      nome: "Thiago Rodrigues",
      logradouro: "Rua das Bromélias",
      bairro: "Boa Vista",
      id: "7770",
      inscricao: "73518294",
      regra: "Nova unidade imobiliária",
      tamanho: "22",
      novo_tamanho: "-",
    },
    {
      nome: "Vanessa Silva",
      logradouro: "Rua das Orquídeas",
      bairro: "Centro",
      id: "9990",
      inscricao: "48261937",
      regra: "Alteração da área total",
      tamanho: "23",
      novo_tamanho: "30",
    },
    {
      nome: "Henrique Oliveira",
      logradouro: "Rua dos Girassóis",
      bairro: "Cruzeiro",
      id: "1110",
      inscricao: "72639481",
      regra: "Nova unidade imobiliária",
      tamanho: "22",
      novo_tamanho: "-",
    },
    {
      nome: "Aline Santos",
      logradouro: "Rua das Margaridas",
      bairro: "Boa Vista",
      id: "2220",
      inscricao: "83947261",
      regra: "Nova unidade imobiliária",
      tamanho: "21",
      novo_tamanho: "-",
    },
    {
      nome: "Marcelo Souza",
      logradouro: "Rua das Azaléias",
      bairro: "Pinheirinho",
      id: "3330",
      inscricao: "51829463",
      regra: "Alteração da área total",
      tamanho: "20",
      novo_tamanho: "25",
    },
    {
      nome: "Juliana Ferreira",
      logradouro: "Rua das Margaridas",
      bairro: "Centro",
      id: "01234",
      inscricao: "54871236",
      regra: "Alteração da área total",
      tamanho: "18",
      novo_tamanho: "-",
    },
    {
      nome: "Marcos Oliveira",
      logradouro: "Rua dos Lírios",
      bairro: "Pinheirinho",
      id: "2468",
      inscricao: "36985214",
      regra: "Nova unidade imobiliária",
      tamanho: "13",
      novo_tamanho: "-",
    },
    {
      nome: "Aline Santos",
      logradouro: "Rua das Violetas",
      bairro: "Estiva",
      id: "1357",
      inscricao: "78542136",
      regra: "Alteração da área total",
      tamanho: "11",
      novo_tamanho: "-",
    },
    {
      nome: "Renato Silva",
      logradouro: "Rua dos Cravos",
      bairro: "Boa Vista",
      id: "5793",
      inscricao: "12369874",
      regra: "Nova unidade imobiliária",
      tamanho: "19",
      novo_tamanho: "-",
    },
    {
      nome: "Tatiana Lima",
      logradouro: "Rua das Tulipas",
      bairro: "Cruzeiro",
      id: "8023",
      inscricao: "98745632",
      regra: "Alteração da área total",
      tamanho: "20",
      novo_tamanho: "25",
    },
    {
      nome: "Felipe Costa",
      logradouro: "Rua das Azaléias",
      bairro: "Pinheirinho",
      id: "1597",
      inscricao: "65432178",
      regra: "Nova unidade imobiliária",
      tamanho: "19",
      novo_tamanho: "-",
    },
    {
      nome: "Larissa Rodrigues",
      logradouro: "Rua das Orquídeas",
      bairro: "Centro",
      id: "3579",
      inscricao: "32147896",
      regra: "Alteração da área total",
      tamanho: "21",
      novo_tamanho: "17",
    },
    {
      nome: "Gabriel Oliveira",
      logradouro: "Rua das Begônias",
      bairro: "Estiva",
      id: "8025",
      inscricao: "98765432",
      regra: "Nova unidade imobiliária",
      tamanho: "18",
      novo_tamanho: "-",
    },
    {
      nome: "Laura Lima",
      logradouro: "Rua das Bromélias",
      bairro: "Boa Vista",
      id: "2469",
      inscricao: "98765432",
      regra: "Nova unidade imobiliária",
      tamanho: "8",
      novo_tamanho: "-",
    },
    {
      nome: "Bruno Costa",
      logradouro: "Rua dos Girassóis",
      bairro: "Cruzeiro",
      id: "1358",
      inscricao: "45678921",
      regra: "Alteração da área total",
      tamanho: "30",
      novo_tamanho: "28",
    },
    {
      nome: "Mariana Oliveira",
      logradouro: "Rua das Dálias",
      bairro: "Centro",
      id: "8021",
      inscricao: "12348765",
      regra: "Nova unidade imobiliária",
      tamanho: "22",
      novo_tamanho: "-",
    },
    {
      nome: "Ricardo Santos",
      logradouro: "Rua das Camélias",
      bairro: "Estiva",
      id: "5790",
      inscricao: "98761234",
      regra: "Alteração da área total",
      tamanho: "20",
      novo_tamanho: "-",
    },
    {
      nome: "Cristina Silva",
      logradouro: "Rua dos Cravos",
      bairro: "Pinheirinho",
      id: "1599",
      inscricao: "65432187",
      regra: "Nova unidade imobiliária",
      tamanho: "21",
      novo_tamanho: "-",
    },
    {
      nome: "Diego Rodrigues",
      logradouro: "Rua das Tulipas",
      bairro: "Boa Vista",
      id: "3589",
      inscricao: "32148765",
      regra: "Alteração da área total",
      tamanho: "15",
      novo_tamanho: "20",
    },
    {
      nome: "Fernanda Oliveira",
      logradouro: "Rua das Azaléias",
      bairro: "Pinheirinho",
      id: "8135",
      inscricao: "12345678",
      regra: "Nova unidade imobiliária",
      tamanho: "19",
      novo_tamanho: "-",
    },
    {
      nome: "Henrique Lima",
      logradouro: "Rua das Violetas",
      bairro: "Estiva",
      id: "9111",
      inscricao: "98765432",
      regra: "Alteração da área total",
      tamanho: "27",
      novo_tamanho: "24",
    },
  ];

  //lógicas para mostrar os inputs e alteração de sets
  const municipioChange = (event) => {
    setSelectedMunicipio(event.target.value);

    switch (event.target.value) {
      case "bairro":
        setShowBairro(true);
        setShowIDLote(false);
        setShowInscricaoImovel(false);
        break;
      case "idLote":
        setShowIDLote(true);
        setShowBairro(false);
        setShowInscricaoImovel(false);
        break;
      case "inscricaoImovel":
        setShowInscricaoImovel(true);
        setShowBairro(false);
        setShowIDLote(false);
        break;
      default:
        setShowBairro(false);
        setShowIDLote(false);
        setShowInscricaoImovel(false);
    }
  };

  const regraChange = (event) => {
    setSelectedRegra(event.target.value);

    switch (event.target.value) {
      case "Alteração da área total":
        setShowAlteracaoAreaTotal(true);
        break;
      default:
        setShowAlteracaoAreaTotal(false);
    }
  };

  const bairroChange = (event) => {
    setSelectedBairro(event.target.value);
    setSelectedID("");
    setSelectedInscricao("");
  };

  const IDChange = (event) => {
    setSelectedID(event.target.value);
    setSelectedBairro("");
    setSelectedInscricao("");
  };

  const InscricaoChange = (event) => {
    setSelectedInscricao(event.target.value);
    setSelectedBairro("");
    setSelectedID("");
  };

  const areaMaiorChange = (event) => {
    const value = event.target.value;
    setAreaMaior(value);
  };

  const areaMenorChange = (event) => {
    const value = event.target.value;
    setAreaMenor(value);
  };

  const selecionarTodos = () => {
    setIsChecked(!isChecked);
  };

  const ajuda = () => {
    setShowHelp(true);
  };

  //função para armazenar os usuarios
  const checkBox = (id) => {
    const usuarioSelecionado = dados.find((usuario) => usuario.id === id);

    if (usuarioSelecionado) {
      if (usuariosSelecionados.some((usuario) => usuario.id === id)) {
        setUsuariosSelecionados((prevState) => {
          const updatedUsuarios = prevState.filter(
            (usuario) => usuario.id !== id
          );
          localStorage.setItem(
            "usuariosSelecionados",
            JSON.stringify(updatedUsuarios)
          );
          return updatedUsuarios;
        });
      } else {
        setUsuariosSelecionados((prevState) => {
          const updatedUsuarios = [...prevState, usuarioSelecionado];
          localStorage.setItem(
            "usuariosSelecionados",
            JSON.stringify(updatedUsuarios)
          );
          return updatedUsuarios;
        });
      }
    } else {
      console.error("Usuário não encontrado com o ID fornecido.");
    }
  };

  //controle dos icones dos botões e para mostrar os dados
  const submit = () => {
    setSearching(true);
    setShowData(true);
    setTimeout(() => {
      setSearching(false);
    }, 1000);
  };

  //função para gerar pdf
  const imprimirPDF = (valor) => {
    const now = new Date();
    const dataHora = `${now.getDate()}/${
      now.getMonth() + 1
    }/${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;

    if (usuariosSelecionados.length > 0) {
      const doc = new jsPDF();
      let y = 10;
      let x = 120;
      let usuariosPorPagina = 0;
      let nomePDF = "";
      let tituloPDF;

      const configurarDocumento = () => {
        doc.setFont("normal", "bold");
        doc.setFontSize(10);
        doc.text("PREFEITURA DE ITAJUBÁ", 13, y + 7);
        doc.text(`Emitido em: ${dataHora} `, x + 30, y + 7);
        doc.setFont("normal", "normal");
        doc.setFontSize(10);
        doc.line(10, y + 10, 200, y + 10);
        y += 20;
      };

      const dadosUsuarios = (usuario) => {
        doc.text(`Nome: ${usuario.nome}`, 10, y + 5);
        doc.text(`Logradouro: ${usuario.logradouro}`, 10, y + 10);
        doc.text(`Bairro: ${usuario.bairro}`, 10, y + 15);
        doc.text(`ID: ${usuario.id}`, 10, y + 20);
        doc.text(`Inscrição: ${usuario.inscricao}`, 10, y + 25);
      };

      const titulo = () => {
        if (tituloPDF === "NOVAS UNIDADES IMIBILIÁRIAS") {
          y += 10;
          doc.setFont("normal", "bold");
          doc.setFontSize(8);
          doc.text(tituloPDF, x - 40, y - 3);
          doc.setFont("normal", "normal");
          doc.line(10, y, 200, y);
          y += 5;
        } else if (tituloPDF === "ALTERAÇÃO DA ÁREA TOTAL") {
          y += 10;
          doc.setFont("normal", "bold");
          doc.setFontSize(8);
          doc.text(tituloPDF, x - 40, y - 3);
          doc.setFont("normal", "normal");
          doc.line(10, y, 200, y);
          y += 3;
        }
      };

      if (valor === "notificacao") {
        configurarDocumento();
        nomePDF = "relatorio";
        usuariosSelecionados.forEach((usuario, index) => {
          if (usuariosPorPagina >= 5) {
            doc.addPage();
            y = 10;
            usuariosPorPagina = 0;
            configurarDocumento();
          }
          if (usuario.regra === "Nova unidade imobiliária") {
            tituloPDF = "NOVAS UNIDADES IMIBILIÁRIAS";
            titulo();
            dadosUsuarios(usuario);
            doc.line(10, y + 30, 200, y + 30);
            y += 30;
            usuariosPorPagina++;
          } else if (usuario.regra === "Alteração da área total") {
            tituloPDF = "ALTERAÇÃO DA ÁREA TOTAL";
            titulo();
            dadosUsuarios(usuario);
            doc.text(`Área antiga: ${usuario.tamanho}`, 10, y + 30);
            doc.text(`Área nova: ${usuario.novo_tamanho}`, 10, y + 35);
            doc.line(10, y + 40, 200, y + 40);
            y += 40;
            usuariosPorPagina++;
          } else {
            alert("Erro na notificação!");
          }
        });
      } else if (valor === "carta") {
        configurarDocumento();
        nomePDF = "notificação";
        usuariosSelecionados.forEach((usuario, index) => {
          if (usuariosPorPagina === 1) {
            doc.addPage();
            y = 10;
            usuariosPorPagina = 0;
            configurarDocumento();
          }
          doc.text("Dados gerais", x - 110, y - 3);
          doc.text("NOTIFICAÇÃO", x + 40, y - 3);
          doc.line(10, y - 1, 200, y);
          y += 5;
          dadosUsuarios(usuario);
          doc.line(10, y + 30, 200, y + 30);
          y += 30;

          doc.text(`Prezado(a) ${usuario.nome}, `, 10, y + 5);
          doc.text(
            `notificamos a atualização do cadastro imobiliário do Município de Itajubá por meio
          de técnicas de geoprocessamento.
          Após a atualização, foram adicionadas novas unidades imobiliárias ao imóvel de
          cadastro nº ${usuario.inscricao}. Caso o proprietário discorde
          das informações apresentadas, deverá apresentar documentos comprobatórios no
          Setor de Cadastro Imobiliário, localizado na Secretaria de Obras,
          no endereço xxxxxxxxxxxxxxxxxxxxxx, ou enviar os documentos para o
          e-mail xxxx@xxxx.mg.gov.br no prazo de 30 (trinta) dias +para análise.
          Após a análise dos documentos, o proprietário será informado da decisão
          no prazo de 30 (trinta) dias.`,
            11,
            y + 9
          );

          y += 80;

          doc.text("NOVAS UNIDADES IMOBILIARIAS", x - 50, y - 1);
          doc.text(`ÁREA ANTIGA ${usuario.tamanho}`, x - 70, y + 6);
          doc.text(`ÁREA NOVA ${usuario.novo_tamanho}`, x + 1, y + 6);

          y += 55;
          usuariosPorPagina++;
        });
      } else {
        alert("Nenhum usuário selecionado para impressão.");
      }
      doc.save(`${nomePDF}.pdf`);
      setUsuariosSelecionados([]);
      setSelecionar(!selecionar)
      localStorage.removeItem("usuariosSelecionados");
      localStorage.removeItem("idsSelecionados");
    }
  };

  // cabeçalho da tabela
  const TableHeader = () => {
    if (isChecked) {
      setSelecionar(!selecionar);
    } else {
      setSelecionar(selecionar);
    }

    return (
      <TableHead>
        <TableRow>
          <TableCell className="titulo-inconsistencias-tabela">
            <Checkbox
              checked={selecionar}
              onChange={() => selecionarTodos()}
              inputProps={{ "aria-label": "Selecionar item" }}
              color="primary"
            />
          </TableCell>
          <TableCell className="titulo-inconsistencias-tabela">Regra</TableCell>
          <TableCell className="titulo-inconsistencias-tabela">
            N° de inscrição
          </TableCell>
          <TableCell className="titulo-inconsistencias-tabela">
            Proprietário
          </TableCell>
          <TableCell className="titulo-inconsistencias-tabela">
            Logradouro
          </TableCell>
          <TableCell className="titulo-inconsistencias-tabela">
            Bairro
          </TableCell>
          <TableCell className="titulo-inconsistencias-tabela"></TableCell>
        </TableRow>
      </TableHead>
    );
  };

  //  linhas da tabela
  const TableRowItem = ({ item, index, checkBox }) => {
    let isChecked3 = usuariosSelecionados.some(
      (usuario) => usuario.id === item.id
    );

    const guardarId = JSON.parse(localStorage.getItem("idsSelecionados")) || [];
    let updatedIds;

    if (isChecked) {
      // adc o id caso ele não esteja no array
      if (!guardarId.includes(item.id)) {
        updatedIds = [...guardarId, item.id];
        checkBox(item.id);
      } else {
        updatedIds = guardarId;
      }
      setIsChecked(!isChecked);
    } else {
      // remove caso já tenha sido escolhido novamente
      updatedIds = guardarId.filter((id) => id !== item.id);
    }

    localStorage.setItem("idsSelecionados", JSON.stringify(updatedIds));

    return (
      <TableRow key={index}>
        <TableCell sx={{ fontSize: "11px", maxWidth: "180px" }}>
          {" "}
          {isChecked === true ? (
            <Checkbox
              checked={selecionar}
              onChange={() => checkBox(item.id)}
              inputProps={{ "aria-label": "Selecionar item" }}
              color="primary"
            />
          ) : (
            <Checkbox
              checked={isChecked3}
              onChange={() => checkBox(item.id)}
              inputProps={{ "aria-label": "Selecionar item" }}
              color="primary"
            />
          )}
        </TableCell>
        <TableCell sx={{ fontSize: "11px", maxWidth: "180px" }}>
          {item.regra}
        </TableCell>
        <TableCell sx={{ fontSize: "11px", maxWidth: "30px" }}>
          {item.inscricao}
        </TableCell>
        <TableCell sx={{ fontSize: "11px", maxWidth: "180px" }}>
          {item.nome}
        </TableCell>
        <TableCell sx={{ fontSize: "11px", maxWidth: "30px" }}>
          {item.logradouro}
        </TableCell>
        <TableCell sx={{ fontSize: "11px", maxWidth: "180px" }}>
          {item.bairro}
        </TableCell>
      </TableRow>
    );
  };

  // modal ajuda
  const ModalHelp = () => {
    return (
      <Modal className="container-help" open={showHelp}>
        <Box className="help">
          <Typography className="header-help">
            <div className="icon-help">
              <span className="span-help">
                <FontAwesomeIcon icon={faQuestion} />
              </span>
              <span className="span-help"> Perguntas e respostas</span>
            </div>
            <button className="close-buttom" onClick={() => setShowHelp(false)}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </Typography>
          <Typography className="textos-help">
            <span>Titulo</span>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
              laudantium necessitatibus, natus aliquid, error accusamus odio,
              quasi similique molestiae tempora mollitia. Ad esse unde
              earum?Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Enim vitae veritatis, accusantium a sint tenetur hic laudantium
              quas consequuntur, ut similique deserunt modi expedita, cupiditate
              officia aut nesciunt facere. Hic obcaecati consequatur possimus
              dolorem nisi.
            </span>
          </Typography>
        </Box>
      </Modal>
    );
  };

  return (
    <div className="container-principal">
      <div className="container-inconsistencias">
        <div>
          <div className="inconsistencias-inputs">
            <TextField
              className="texto-inputs"
              label="Todo Município"
              select
              value={selectedMunicipio}
              onChange={municipioChange}
            >
              <MenuItem value="municipio">
                <em>Todo Município</em>
              </MenuItem>
              <MenuItem value="bairro">Bairro</MenuItem>
              <MenuItem value="idLote">Identificador do Lote</MenuItem>
              <MenuItem value="inscricaoImovel">Inscrição do Imóvel</MenuItem>
            </TextField>
          </div>
          {showBairro && (
            <div className="inconsistencias-inputs">
              <TextField
                className="texto-inputs"
                label={
                  selectedMunicipio === "bairro" ? "" : "Selecione um bairro"
                }
                value={selectedBairro}
                onChange={bairroChange}
                select
              >
                <MenuItem value="bairro">
                  <em>Selecione um bairro</em>
                </MenuItem>
                {[...new Set(dados.map((item) => item.bairro))].map(
                  (bairro) => (
                    <MenuItem key={bairro} value={bairro}>
                      {bairro}
                    </MenuItem>
                  )
                )}
              </TextField>
            </div>
          )}
          {showIDLote && (
            <div className="inconsistencias-inputs">
              <TextField
                className="texto-inputs"
                label={selectedMunicipio === "idLote" ? "" : " ID do lote"}
                value={selectedID}
                onChange={IDChange}
              ></TextField>
            </div>
          )}
          {showInscricaoImovel && (
            <div className="inconsistencias-inputs">
              <TextField
                className="texto-inputs"
                label={
                  selectedRegra === "inscricaoImovel" ? " " : "N° de inscrição"
                }
                value={selectedInscricao}
                onChange={InscricaoChange}
              >
                Numero da inscrição
              </TextField>
            </div>
          )}
          <div className="inconsistencias-inputs">
            <TextField
              className="texto-inputs"
              label="Selecione uma regra"
              select
              value={selectedRegra}
              onChange={regraChange}
            >
              <MenuItem value="regra">
                <em>Selecione uma regra</em>
              </MenuItem>
              <MenuItem value="Nova unidade imobiliária">
                Novas unidades imobiliarias
              </MenuItem>
              <MenuItem value="Alteração da área total">
                Alteração na área total das edificações
              </MenuItem>
            </TextField>
            <div className="container-inputs-alteracao">
              {showAlteracaoAreaTotal && (
                <div className="altera-area-inputs">
                  <TextField
                    className="area-inputs"
                    value={areaMaior}
                    onChange={areaMaiorChange}
                    label={
                      selectedRegra === "Alteração da área total"
                        ? "Considerar áreas maiores que: "
                        : " "
                    }
                  ></TextField>
                </div>
              )}
              {showAlteracaoAreaTotal && (
                <div className="altera-area-inputs">
                  <TextField
                    className="area-inputs"
                    value={areaMenor}
                    onChange={areaMenorChange}
                    label={
                      selectedRegra === "Alteração da área total"
                        ? "Considerar áreas menores que: "
                        : " "
                    }
                  ></TextField>
                </div>
              )}
            </div>
          </div>
          <div className="tabela-regras">
            <TableContainer sx={{ height: "100%" }}>
              <Table>
                <TableHeader />
                {showData && (
                  <TableBody>
                    {dados
                      .filter(
                        (item) =>
                          (item.bairro === selectedBairro ||
                            item.id === selectedID ||
                            item.inscricao === selectedInscricao) &&
                          (selectedRegra === "Alteração da área total"
                            ? item.regra === selectedRegra &&
                              parseInt(item.tamanho, 10) >=
                                (parseInt(areaMaior), 10) &&
                              (parseInt(item.tamanho), 10) <=
                                (parseInt(areaMenor), 10) &&
                              item.novo_tamanho !== "-"
                            : item.regra === selectedRegra)
                      )
                      .map((item, index) => (
                        <TableRowItem
                          key={index}
                          item={item}
                          index={index}
                          checkBox={checkBox}
                        />
                      ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
          {showHelp && <ModalHelp />}
        </div>
        <div className="barra-direita-inconsistencias">
          <Fab
            className="botao-alterar-inconsistencias"
            sx={{ margin: "5px", marginBottom: "-10px", fontSize: "20px" }}
            onClick={submit}
            title="Pesquisar"
          >
            {searching ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            )}
          </Fab>
          <Fab
            className="botao-alterar-inconsistencias"
            sx={{ margin: "5px", marginBottom: "-10px", fontSize: "20px" }}
            onClick={() => imprimirPDF("notificacao")}
            title="Gerar notificação"
          >
            {searching ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <FontAwesomeIcon icon={faPrint} />
            )}
          </Fab>
          <Fab
            className="botao-alterar-inconsistencias"
            sx={{ margin: "5px", marginBottom: "-10px", fontSize: "20px" }}
            onClick={() => imprimirPDF("carta")}
            title="Gerar carta"
          >
            {searching ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <FontAwesomeIcon icon={faEnvelope} />
            )}
          </Fab>
          <Fab
            className="botao-alterar-inconsistencias"
            sx={{ margin: "5px", marginBottom: "-10px", fontSize: "20px" }}
            // onClick={imprimir()}
          >
            {searching ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <FontAwesomeIcon icon={faCheck} />
            )}
          </Fab>
          <Fab
            className="botao-alterar-inconsistencias"
            sx={{ margin: "5px", marginBottom: "-10px", fontSize: "20px" }}
            onClick={() => ajuda()}
          >
            {searching ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <FontAwesomeIcon icon={faQuestion} />
            )}
          </Fab>
        </div>
      </div>
    </div>
  );
};

export default Inconsistencias;
