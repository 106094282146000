import React, { useState, } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFile, faHouse, faLocationDot, faMap, faPrint } from "@fortawesome/free-solid-svg-icons";
import { Button, } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import "../ModalInfo.css";

const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
        backgroundColor: '#1890ff',
    },
    "& .MuiBox-root": {
        padding: "0px",
    },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontSize: 12,
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    '&:hover': {
        color: '#40a9ff',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#1890ff',
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },
}));

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{ height: '40vh' }}

        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TabsInfo({ indice, infoImovel }) {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    const inputs = {
        //GERAL
        proprietario: infoImovel.imoveis[indice].proprietario.nome || "Sem resultados!",
        inscricaoReduzida: infoImovel.imoveis[indice].unidade.id || "Sem resultados!",
        unidadeCod: infoImovel.imoveis[indice].unidade.unidadeCod || "Sem resultados!",
        logradouro: infoImovel.imoveis[indice].logradouro.nome || "Sem resultados!",
        numero: infoImovel.imoveis[indice].endereco.numero,
        bairro: infoImovel.imoveis[indice].endereco.bairro || "Sem resultados!",
        complemento: "Sem resultados!" || "Sem resultados!",
        setor: infoImovel.imoveis[indice].unidade.setorCod || "Sem resultados!",
        quadra: infoImovel.imoveis[indice].unidade.quadraCod || "Sem resultados!",
        lote: infoImovel.imoveis[indice].unidade.loteCod || "Sem resultados!",

        //EDIFICAÇÃO
        areaTotal: "Sem resultados!",
        areaPrincipal: "Sem resultados!",
        areaDependentes: "Sem resultados!",
        tipo: infoImovel.imoveis[indice].unidade.tipo || "Sem resultados!",
        posicao: infoImovel.imoveis[indice].unidade.posicao || "Sem resultados!",
        estrutura: infoImovel.imoveis[indice].unidade.estrutura || "Sem resultados!",
        cobertura: infoImovel.imoveis[indice].unidade.cobertura || "Sem resultados!",
        conservacao: infoImovel.imoveis[indice].unidade.conservacao || "Sem resultados!",
        categoria: infoImovel.imoveis[indice].unidade.instSanitaria || "Sem resultados!",
        instalacaoSanitaria: infoImovel.imoveis[indice].unidade.instSanitaria || "Sem resultados!",
        instalacaoEletrica: infoImovel.imoveis[indice].unidade.instEletrica || "Sem resultados!",
        piso: "Sem resultados!",
        revestimento: infoImovel.imoveis[indice].unidade.revestimento || "Sem resultados!",
        fachada: "Sem resultados!",
        situacao: "Sem resultados!",
        forro: "Sem resultados!",

        //TERRENO
        areaTotalTerreno: infoImovel.imoveis[indice].lote.areaTerreno.toFixed(2) || "Sem resultados!",
        fracaoIdeal: infoImovel.imoveis[indice].lote.fracaoIdealInfo || "Sem resultados!",
        testadaPrincipal: infoImovel.testadaPrincipal[0].extensao.toFixed(2) || "Sem resultados!",
        ocupacao: infoImovel.imoveis[indice].lote.ocupacao || "Sem resultados!",
        patrimonio: infoImovel.imoveis[indice].lote.patrimonio || "Sem resultados!",
        topografia: infoImovel.imoveis[indice].lote.topografia || "Sem resultados!",
        pedologia: infoImovel.imoveis[indice].lote.pedologia || "Sem resultados!",
        utilizacao: infoImovel.imoveis[indice].lote.utilizacao || "Sem resultados!",
        frentes: "faltando" || "Sem resultados!",
        muro: infoImovel.imoveis[indice].lote.muros || "Sem resultados!",
        passeio: "faltando" || "Sem resultados!",
        arvores: "faltando" || "Sem resultados!",
        conservacao: infoImovel.imoveis[indice].unidade.conservacao || "Sem resultados!",
        lado: "faltando" || "Sem resultados!",
    };

    const formataNumInscricao = (unidadeCod, setor, quadra, lote) => {
        const formataUnidadeCod = String(unidadeCod).padStart(2, '0');
        const formataSetor = String(setor).padStart(2, '0');
        const formataQuadra = String(quadra).padStart(3, '0');
        const formataLote = String(lote).padStart(4, '0');
        const valorFormatado = `${formataUnidadeCod}.${formataSetor}.${formataQuadra}.${formataLote}.001.001`;
        return valorFormatado;
    };
    console.log(infoImovel)
    return (
        <>
            <div className="ModalInfo-box ModalInfo-scrollbar ModalInfo-linha">
                <Box>
                    <AntTabs value={value} onChange={handleChange} variant="fullWidth" >
                        <AntTab icon={<FontAwesomeIcon icon={faLocationDot} />} iconPosition="start" label="Geral"  {...a11yProps(0)} />
                        <AntTab icon={<FontAwesomeIcon icon={faHouse} />} iconPosition="start" label="Edificação"  {...a11yProps(1)} />
                        <AntTab icon={<FontAwesomeIcon icon={faMap} />} iconPosition="start" label="Terreno"  {...a11yProps(2)} />
                        <AntTab icon={<FontAwesomeIcon icon={faFile} />} iconPosition="start" label="Arquivos"  {...a11yProps(3)} />
                    </AntTabs>

                    {/* GERAL */}
                    <CustomTabPanel value={value} index={0}>
                        <div className="ModalInfo-form-group">
                            <label className="ModalInfo-label">
                                Proprietário
                            </label>
                            <input
                                className="ModalInfo-input disabled"
                                type="text"
                                name="proprietario"
                                disabled
                                value={inputs.proprietario}
                            />
                        </div>

                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group" style={{ width: '60%' }}>
                                <label className="ModalInfo-label">
                                    Inscrição cadastral
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="inscricaoCadastral"
                                    disabled
                                    value={formataNumInscricao(inputs.unidadeCod, inputs.setor, inputs.quadra, inputs.lote)}
                                />
                            </div>

                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Nº inscr. reduzido
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="inscricaoReduzido"
                                    disabled
                                    value={inputs.inscricaoReduzida}
                                />
                            </div>
                        </div>

                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group" style={{ width: '75%' }}>
                                <label className="ModalInfo-label">
                                    Logradouro
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="logradouro"
                                    disabled
                                    value={inputs.logradouro}
                                />
                            </div>

                            <div className="ModalInfo-form-group" style={{ width: '25%' }}>
                                <label className="ModalInfo-label">
                                    Número
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="numero"
                                    disabled
                                    value={inputs.numero}
                                />
                            </div>
                        </div>

                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group" style={{ width: '60%' }}>
                                <label className="ModalInfo-label">
                                    Bairro
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="bairro"
                                    disabled
                                    value={inputs.bairro}
                                />
                            </div>

                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Complemento
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="complemento"
                                    disabled
                                    value={inputs.complemento}
                                />
                            </div>
                        </div>

                        <div className="ModalInfo-form-row" >
                            <div className="ModalInfo-form-group" style={{ width: '30%' }} >
                                <label className="ModalInfo-label">
                                    Setor
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="setor"
                                    disabled
                                    value={inputs.setor}
                                />
                            </div>

                            <div className="ModalInfo-form-group" style={{ width: '30%' }}>
                                <label className="ModalInfo-label">
                                    Quadra
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="quadra"
                                    disabled
                                    value={inputs.quadra}
                                />
                            </div>

                            <div className="ModalInfo-form-group" style={{ width: '30%' }}>
                                <label className="ModalInfo-label">
                                    Lote
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="lote"
                                    disabled
                                    value={inputs.lote}
                                />
                            </div>
                        </div>
                    </CustomTabPanel>

                    {/* EDIFICAÇÃO */}
                    <CustomTabPanel value={value} index={1}>
                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Área total
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="areaTotal"
                                    disabled
                                    value={inputs.areaTotal}
                                />
                            </div>

                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Área principal
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="areaPrincipal"
                                    disabled
                                    value={inputs.areaPrincipal}
                                />
                            </div>
                        </div>

                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Área das dependentes
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="areaDependentes"
                                    disabled
                                    value={inputs.areaDependentes}
                                />
                            </div>
                        </div>

                        <div className="ModalInfo-linha" style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }} />

                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Tipo
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="tipo"
                                    disabled
                                    value={inputs.tipo}
                                />
                            </div>

                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Posição
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="posicao"
                                    disabled
                                    value={inputs.posicao}
                                />
                            </div>
                        </div>

                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Estrutura
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="estrutura"
                                    disabled
                                    value={inputs.estrutura}
                                />
                            </div>

                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Cobertura
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="cobertura"
                                    disabled
                                    value={inputs.cobertura}
                                />
                            </div>
                        </div>

                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Conservação
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="conservacao"
                                    disabled
                                    value={inputs.conservacao}
                                />
                            </div>

                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Categoria
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="categoria"
                                    disabled
                                    value={inputs.categoria}
                                />
                            </div>
                        </div>

                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Instalação sanitária
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="instalacaoSanitaria"
                                    disabled
                                    value={inputs.instalacaoSanitaria}
                                />
                            </div>

                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Instalação elétrica
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="instalacaoEletrica"
                                    disabled
                                    value={inputs.instalacaoEletrica}
                                />
                            </div>
                        </div>

                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Piso
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="piso"
                                    disabled
                                    value={inputs.piso}
                                />
                            </div>

                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Revestimento
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="revestimento"
                                    disabled
                                    value={inputs.revestimento}
                                />
                            </div>
                        </div>

                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Fachada
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="fachada"
                                    disabled
                                    value={inputs.fachada}
                                />
                            </div>

                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Situação
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="situacao"
                                    disabled
                                    value={inputs.situacao}
                                />
                            </div>
                        </div>

                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Forro
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="forro"
                                    disabled
                                    value={inputs.forro}
                                />
                            </div>
                        </div>
                    </CustomTabPanel>

                    {/* TERRENO */}
                    <CustomTabPanel value={value} index={2}>
                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group ModalInfo-row-column" >
                                <label className="ModalInfo-label">
                                    Área total
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="areaTotalTerreno"
                                    disabled
                                    value={inputs.areaTotalTerreno}
                                />
                            </div>

                            <div className="ModalInfo-form-group ModalInfo-row-column" >
                                <label className="ModalInfo-label">
                                    Fração ideal
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="fracaoIdeal"
                                    disabled
                                    value={inputs.fracaoIdeal}
                                />
                            </div>
                        </div>

                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Testada Principal
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="testadaPrincipal"
                                    disabled
                                    value={inputs.testadaPrincipal}
                                />
                            </div>
                        </div>

                        <div className="ModalInfo-linha" style={{ width: '100%', marginTop: '5px', marginBottom: '5px' }} />

                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Ocupação
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="ocupacao"
                                    disabled
                                    value={inputs.ocupacao}
                                />
                            </div>
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Patrimônio
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="patrimonio"
                                    disabled
                                    value={inputs.patrimonio}
                                />
                            </div>
                        </div>
                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Topografia
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="topografia"
                                    disabled
                                    value={inputs.topografia}
                                />
                            </div>
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Pedologia
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="pedologia"
                                    disabled
                                    value={inputs.pedologia}
                                />
                            </div>
                        </div>
                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Utilização
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="utilizacao"
                                    disabled
                                    value={inputs.utilizacao}
                                />
                            </div>
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Frentes
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="frentes"
                                    disabled
                                    value={inputs.frentes}
                                />
                            </div>
                        </div>
                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Muro
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="muro"
                                    disabled
                                    value={inputs.muro}
                                />
                            </div>
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Passeio
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="passeio"
                                    disabled
                                    value={inputs.passeio}
                                />
                            </div>
                        </div>
                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Árvores
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="arvore"
                                    disabled
                                    value={inputs.arvores}
                                />
                            </div>
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Conservação
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="conservacao"
                                    disabled
                                    value={inputs.conservacao}
                                />
                            </div>
                        </div>

                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group ModalInfo-row-column">
                                <label className="ModalInfo-label">
                                    Lado
                                </label>
                                <input
                                    className="ModalInfo-input disabled"
                                    type="text"
                                    name="lado"
                                    disabled
                                    value={inputs.lado}
                                />
                            </div>
                        </div>
                    </CustomTabPanel>

                    {/* ARQUIVOS */}
                    <CustomTabPanel value={value} index={3}>
                        <div className="ModalInfo-form-row">
                            <div className="ModalInfo-form-group ModalInfo-row-column" style={{ marginBottom: 0 }}>
                                <label className="ModalInfo-label">
                                    Arquivo
                                </label>

                            </div>
                            <div className="ModalInfo-form-group ModalInfo-row-column" style={{ marginBottom: 0 }}>
                                <label className="ModalInfo-label">
                                    Tipo
                                </label>
                            </div>
                        </div>
                        <div className="ModalInfo-linhaAzul" />

                        {infoImovel.arquivos.map((arquivos, index) => (
                            <div key={index} className="ModalInfo-form-row">
                                <div className="ModalInfo-form-group ModalInfo-row-column" style={{ marginTop: '5px', width: '60%' }}>
                                    <label className="ModalInfo-label">
                                        Imagem
                                    </label>
                                </div>
                                <div className="ModalInfo-form-group ModalInfo-row-column" style={{ marginTop: '5px' }}>
                                    <div className="ModalInfo-row" style={{ justifyContent: 'flex-start', gap: '50%' }}>
                                        <label className="ModalInfo-label">
                                            {arquivos.tipo}
                                        </label>
                                        <Button variant="outlined" disabled style={{ minWidth: '30px' }} >
                                            <FontAwesomeIcon icon={faDownload} style={{ fontSize: '12px' }} />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </CustomTabPanel>
                </Box>
            </div>

            <div className="ModalInfo-row-group" style={{ paddingTop: '16px' }} >
                <Button variant="contained" disabled>
                    <FontAwesomeIcon icon={faPrint} style={{ marginRight: '0.5em' }} />Imprimir
                </Button>
            </div>
        </>
    );
}