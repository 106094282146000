import { createContext, useState, useEffect, useRef, useContext } from 'react';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import { Fill, Stroke, Style } from 'ol/style.js';
import { OSM, Vector as VectorSource } from 'ol/source.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import proj4 from 'proj4';

const MapContextAdv = createContext();

const MapProviderAdv = ({ children }) => {
  const [testadas, setTestadas] = useState([]);
  const [radioButtonState, setRadioButtonState] = useState(['lotes', 'testadas']);
  const [currentZoom, setCurrentZoom] = useState(5);
  const [coordenadasPoligono, setCoordenadasPoligono] = useState([]);
  const [coordenadasPoligonoTestadas, setCoordenadasPoligonoTestadas] = useState([]);
  const [pontoCentralMap, setPontoCentralMap] = useState([]);
  const mapRef = useRef(null);
  const vectorLayerRef = useRef(null);
  const vectorLayerRefTestada = useRef(null);
  const [newMap, setNewMap] = useState(null);

  const colorsLotes = [
    "rgb(19,100,181)",
  ];
  const colorsTestadas = [
    "rgb(23, 126, 137)",
    "rgb(219, 58, 52)",
    "rgb(255, 200, 87)",
    "rgb(8, 76, 97)",
    "rgb(84, 13, 110)",
    "rgb(238, 66, 102)",
    "rgb(14, 173, 105)",
    "rgb(228, 193, 249)",
    "rgb(152, 82, 119)",
    "rgb(0, 20, 39)",
  ];
  const colorsCoberturas = [
    "rgb(174, 199, 232, 0.5)",
    "rgb(255, 127, 14, 0.5)",
    "rgb(44, 160, 44, 0.5)",
    "rgb(152, 223, 138, 0.5)",
    "rgb(214, 39, 40, 0.5)",
    "rgb(255, 152, 150, 0.5)",
    "rgb(148, 103, 189, 0.5)",
    "rgb(197, 176, 213, 0.5)",
    "rgb(140, 86, 75, 0.5)",
    "rgb(196, 156, 148, 0.5)",
    "rgb(227, 119, 194, 0.5)",
    "rgb(247, 182, 210, 0.5)",
    "rgb(127, 127, 127, 0.5)",
    "rgb(199, 199, 199, 0.5)",
    "rgb(188, 189, 34, 0.5)",
    "rgb(219, 219, 141, 0.5)",
    "rgb(23, 190, 207, 0.5)",
    "rgb(158, 218, 229, 0.5)",
  ];
  const colorsCoberuturaStroke = [
    "rgb(174, 199, 232)",
    "rgb(255, 127, 14)",
    "rgb(44, 160, 44)",
    "rgb(152, 223, 138)",
    "rgb(214, 39, 40)",
    "rgb(255, 152, 150)",
    "rgb(148, 103, 189)",
    "rgb(197, 176, 213)",
    "rgb(140, 86, 75)",
    "rgb(196, 156, 148)",
    "rgb(227, 119, 194)",
    "rgb(247, 182, 210)",
    "rgb(127, 127, 127)",
    "rgb(199, 199, 199)",
    "rgb(188, 189, 34)",
    "rgb(219, 219, 141)",
    "rgb(23, 190, 207)",
    "rgb(158, 218, 229)",
  ]

  const configurarCoordenadaCentral = (coordenadas) => {
    const utmProjection = '+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
    const latLngProjection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
    const coordenadasLimpo = coordenadas;
    const latLngCoordinates = proj4(utmProjection, latLngProjection, coordenadasLimpo);

    setPontoCentralMap(latLngCoordinates)
  };

  const configurarCoordenada = (coordenadasPoligonoNaoConfiguradas) => {
    const utmProjection = '+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
    const latLngProjection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';

    const convertedCoordinates = coordenadasPoligonoNaoConfiguradas.map((polygon) => {
      return polygon.map((coordinate) => {
        return proj4(utmProjection, latLngProjection, coordinate);
      });
    });
    return (convertedCoordinates);
  };

  const createGeoJsonData = (coordinatesArray, tipo) => {
    return {
      "type": "FeatureCollection",
      "features": coordinatesArray.map((coordinates, index) => ({
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [coordinates]
          ]
        },
        "properties": {
          "indexFeature": index,
        }
      }))
    };
  };

  const geoJsonData = createGeoJsonData(coordenadasPoligono);
  const geoJsonDataTestadas = createGeoJsonData(coordenadasPoligonoTestadas);


  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    const map = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        })
      ],
      view: new View({
        center: [pontoCentralMap[0], pontoCentralMap[1]],
        zoom: 20,
        projection: 'EPSG:4326',
      }),
    });
    setNewMap(map)

    return () => {
      map.dispose();
    };
  }, [mapRef, pontoCentralMap, currentZoom, coordenadasPoligono, coordenadasPoligonoTestadas]);




  useEffect(() => {
    vectorLayerRefTestada.current = new VectorLayer({
      source: new VectorSource({
        features: new GeoJSON().readFeatures(geoJsonDataTestadas, {
          featureProjection: 'EPSG:4326',
        }),
      }),
      style: (feature, resolution) => {
        const index = feature.get('indexFeature');
        const rgbColor = colorsTestadas[index];
        const rgbaColor = `rgba(${rgbColor.substring(4, rgbColor.length - 1)}, 0.3)`;

        return new Style({
          fill: new Fill({
            color: rgbaColor,
          }),
          stroke: new Stroke({
            color: colorsTestadas[index],
            width: 4,
          }),
        });
      },
      Type: 'testadas',
    })
    vectorLayerRef.current = new VectorLayer({
      source: new VectorSource({
        features: new GeoJSON().readFeatures(geoJsonData, {
          featureProjection: 'EPSG:4326',
        }),
      }),
      style: (feature, resolution) => {
        const index = feature.get('indexFeature');
        const rgbColor = colorsLotes[index];
        const rgbaColor = `rgba(${rgbColor.substring(4, rgbColor.length - 1)}, 0.3)`;

        return new Style({
          fill: new Fill({
            color: rgbaColor,
          }),
          stroke: new Stroke({
            color: rgbaColor,
            width: 2,
          }),
        });
      },
      Type: 'lotes',
    })

    if (!mapRef.current) {
      return;
    }

    // Verificar se 'lotes' ou 'testadas' estão no array radioButtonState
    const hasLotes = radioButtonState.includes('lotes');
    const hasTestadas = radioButtonState.includes('testadas');

    if (hasLotes) {
      newMap.addLayer(vectorLayerRef.current);
    }
    if (hasTestadas) {
      newMap.addLayer(vectorLayerRefTestada.current);
    }

    return () => {
      if (hasLotes && vectorLayerRef.current) {
        newMap.removeLayer(vectorLayerRef.current);
      }
      if (hasTestadas && vectorLayerRefTestada.current) {
        newMap.removeLayer(vectorLayerRefTestada.current);
      }
    };

  }, [radioButtonState, newMap]);



  return (
    <MapContextAdv.Provider
      value={{
        mapRef,
        colorsTestadas,
        colorsCoberturas,
        colorsCoberuturaStroke,
        setCurrentZoom,
        coordenadasPoligono,
        setCoordenadasPoligono,
        pontoCentralMap,
        setPontoCentralMap,
        configurarCoordenada,
        configurarCoordenadaCentral,
        setTestadas,
        testadas,
        setCoordenadasPoligonoTestadas,
        setRadioButtonState
      }}
    >
      {children}
    </MapContextAdv.Provider>
  );
};

export { MapProviderAdv, MapContextAdv };
