import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";

import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";

import Sidebar from "../Sidebar/Sidebar";
import "../Header/Header.css";
import Logo from "../../assets/img/logo_saolourenco.png";

import { Tabs, Tab } from "@mui/material";

import { ContextMap } from "../../ContextMap/ContextMap";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faMagnifyingGlass,
  faSpinner,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../services/Api";
import proj4 from "proj4";
import { styled } from "@mui/system"; // Importação correta para styled

const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: "white",
    borderLeft: "2px solid #1976D2",
    borderRight: "2px solid #1976D2",
    height: 6,
    transition: "none",
    webkitTransition: "none",
  },
  ".MuiButtonBase-root.Mui-selected": {
    borderRadius: "4px",
    border: "2px solid #1976D2",
    borderBottom: "2px solid white",
    height: "7.5vh",
  },
}));

function Header({
  setOpenTelaLogin,
  toggletelaSidebar,
  estadoLogin,
  nullEstadoLogin,
}) {
  const [showSidebar, setShowSidebar] = useState(false);
  const [opcaoSelecionada, setOpcaoSelecionada] = useState("");
  const [searching, setSearching] = useState(false);
  const [ValorTemp, setValorTemp] = useState("");
  const [ValorTempNumero, setValorTempNumero] = useState("");
  const [error, setError] = useState(false);
  const { opcao } = useContext(ContextMap);
  const { alterarTipoMapa } = useContext(ContextMap);

  const [valueTabs, setValueTabs] = useState(0);

  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  function handleOpcaoSelecionada(event) {
    setValorTemp("");
    setValorTempNumero("");
    setOpcaoSelecionada(event.target.value);
  }

  useEffect(() => {
    toggletelaSidebar();
  }, [showSidebar]);

  const [value, setValue] = React.useState("1");

  const { searchBuscaImovel } = useContext(ContextMap);
  function altera(event) {
    setValorTemp(event.target.value);
  }
  const alteraNumInscricao = (event) => {
    const numero = event.target.value;
    const valorFormatado = formataNumInscricao(numero);
    setValorTemp(valorFormatado);
  };
  const formataNumInscricao = (valor) => {
    valor = valor.replace(/\D/g, "");
    let valorFormatado = "";
    for (let i = 0; i < valor.length; i++) {
      if (i === 2 || i === 4 || i === 7 || i === 11 || i === 14) {
        valorFormatado += ".";
      }
      valorFormatado += valor[i];
      if (i >= 16) {
        break;
      }
    }
    return valorFormatado;
  };

  function alteraInput(event) {
    setValorTempNumero(event.target.value);
  }

  async function enviarInscricao() {
    try {
      if (ValorTemp !== "") {
        setError(false);
        setSearching(true);
        const valor = {
          inscricaoImovel: ValorTemp,
        };
        const response = await Api.searchNumInscricao(valor);

        // Remova o prefixo "POINT(" e o sufixo ")"
        const coordenadasLimpo = response.pontoCentral[0].geom.coordinates;
        const utmProjection =
          "+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs";
        const latLngProjection =
          "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
        const coordenadasCentral = proj4(
          utmProjection,
          latLngProjection,
          coordenadasLimpo
        );
        const coordenadas = response.lote.geom.coordinates[0][0];
        searchBuscaImovel(
          18,
          coordenadasCentral,
          coordenadas,
          coordenadasLimpo
        );
        setSearching(false);
        setValorTemp("");
      } else {
        setError(true);
      }
    } catch (error) {
      alert("Número de inscrição não encontrado");
      setSearching(false);
    }
  }

  async function enviarInscricaoReduzida() {
    try {
      if (ValorTemp !== "") {
        const intNum = parseInt(ValorTemp, 10);
        setError(false);
        setSearching(true);
        const valor = {
          cod: intNum,
        };
        const response = await Api.searchInscricaoReduzida(valor);

        // Remova o prefixo "POINT(" e o sufixo ")"
        const coordenadasLimpo = response.pontoCentral[0].geom.coordinates;
        const utmProjection =
          "+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs";
        const latLngProjection =
          "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
        const coordenadasCentral = proj4(
          utmProjection,
          latLngProjection,
          coordenadasLimpo
        );
        const coordenadas = response.lote.geom.coordinates[0][0];
        searchBuscaImovel(
          18,
          coordenadasCentral,
          coordenadas,
          coordenadasLimpo
        );
        setSearching(false);
        setValorTemp("");
      } else {
        setError(true);
      }
    } catch (error) {
      alert("Código do Lote/Imovel não encontrado");
      setSearching(false);
    }
  }
  async function enviarEndereco() {
    try {
      if (ValorTemp !== "" && ValorTempNumero !== "") {
        const ValorTempFilter = ValorTemp.toUpperCase();
        setError(false);
        setSearching(true);
        const valor = {
          logradouro: ValorTempFilter,
          numero: ValorTempNumero,
        };
        const response = await Api.searchEndereco(valor);
        const coordenadasLimpo = response.pontoCentral[0].geom.coordinates;
        const utmProjection =
          "+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs";
        const latLngProjection =
          "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
        const coordenadasCentral = proj4(
          utmProjection,
          latLngProjection,
          coordenadasLimpo
        );
        const coordenadas = response.imoveis[0].lote.geom.coordinates[0][0];
        searchBuscaImovel(
          18,
          coordenadasCentral,
          coordenadas,
          coordenadasLimpo
        );
        setSearching(false);
        setValorTemp("");
        setValorTempNumero("");
      } else {
        setError(true);
      }
    } catch (error) {
      alert("Endereço não encontrado");
      setSearching(false);
    }
  }
  function renderizarCampoDeEntrada() {
    switch (opcaoSelecionada) {
      case "inscricao":
        return (
          <a
            className="input-group"
            style={{ display: "flex", position: "fixed", left: "200px" }}
          >
            <TextField
              className="form-control"
              label="Inscrição Cadastral"
              required
              variant="outlined"
              size="small"
              value={ValorTemp}
              onChange={alteraNumInscricao}
              error={error}
              autoComplete="off"
            />
            <Button
              variant="contained"
              size="small"
              onClick={enviarInscricao}
              sx={{
                minWidth: 0,
                width: "30px",
              }}
            >
              {searching ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              )}
            </Button>
          </a>
        );
      case "n-reduzido":
        return (
          <a
            className="input-group"
            style={{ display: "flex", position: "fixed", left: "200px" }}
          >
            <TextField
              className="form-control"
              label="Nº inscr. reduzido"
              required
              variant="outlined"
              size="small"
              value={ValorTemp}
              onChange={altera}
              error={error}
              autoComplete="off"
            />
            <Button
              variant="contained"
              size="small"
              onClick={enviarInscricaoReduzida}
              sx={{
                minWidth: 0,
                width: "30px",
              }}
            >
              {searching ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              )}
            </Button>
          </a>
        );
      case "endereco":
        return (
          <a
            className="input-group"
            style={{ display: "flex", position: "fixed", left: "200px" }}
          >
            <TextField
              className="form-control"
              label="Logradouro"
              required
              variant="outlined"
              size="small"
              value={ValorTemp}
              onChange={altera}
              error={error}
              autoComplete="off"
            />
            <TextField
              className="form-control"
              label="Número"
              required
              variant="outlined"
              size="small"
              value={ValorTempNumero}
              onChange={alteraInput}
              error={error}
              autoComplete="off"
            />
            <Button
              variant="contained"
              size="small"
              onClick={enviarEndereco}
              sx={{
                minWidth: 0,
                width: "30px",
              }}
            >
              {searching ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              )}
            </Button>
          </a>
        );
      default:
        return null;
    }
  }

  //avatar
  // const nomeUsuario = sessionStorage.getItem("infoUsuarioNome");
  const [showList, setShowList] = useState(false);

  function handleLogoutClick() {
    nullEstadoLogin();
    setShowList(!showList);
  }

  function handleButtonClick() {
    setShowList(!showList);
  }

  //Ao clicar fora do box-logout ele fecha-o
  let avatarRef = useRef();
  useEffect(() => {
    let handleClickOutside = (event) => {
      if (avatarRef.current && !avatarRef.current.contains(event.target))
        setShowList(false);
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { adicionarObjeto, removerObjeto, listaCamadas, removerTodosObjetos } =
    useContext(ContextMap);

  // function setOrtofoto() {
  //     adicionarObjeto(
  //         {
  //             id: 'Ortofoto',
  //             label: 'Ortofoto',
  //             url: 'SaoLourenco',
  //             layer: 'SaoLourenco:Imagem_Satelite',
  //             ativo: true
  //         }
  //     )
  // };

  // function LimpaCamadas() {
  //     removerObjeto('Ortofoto')
  //     alterarTipoMapa(true);
  // };

  // function setSatelite() {
  //     alterarTipoMapa(false);
  // }

  return (
    <div className="header">
      <a href="#" className="btn">
        {estadoLogin ? (
          <FontAwesomeIcon
            onClick={toggleSidebar}
            size="xl"
            icon={showSidebar ? faXmark : faBars}
            className={`sidebar-icon ${showSidebar ? "open" : "closed"}`}
          />
        ) : null}
      </a>
      {estadoLogin ? <Sidebar showSidebar={showSidebar} /> : null}
      <a className="logo">
        {/* <img src={Logo} alt="logo" style={{ width: '30vh' }}></img> */}
      </a>

      {estadoLogin ? (
        opcao === "home" ? (
          <a className="input-group">
            <FormControl
              sx={{ minWidth: 150, position: "fixed", left: "50px" }}
              size="small"
            >
              <InputLabel sx={{ fontSize: "0.7rem" }}>Busca Imóvel</InputLabel>
              <Select
                value={opcaoSelecionada}
                label="Busca Imóvel"
                onChange={handleOpcaoSelecionada}
                sx={{ fontSize: "0.7rem" }}
              >
                <MenuItem value="inscricao" sx={{ fontSize: "0.7rem" }}>
                  Inscrição Cadastral
                </MenuItem>
                <MenuItem value="n-reduzido" sx={{ fontSize: "0.7rem" }}>
                  Nº inscr. reduzido
                </MenuItem>
                <MenuItem value="endereco" sx={{ fontSize: "0.7rem" }}>
                  Endereço
                </MenuItem>
              </Select>
            </FormControl>
          </a>
        ) : null
      ) : null}
      {opcao === "home" ? renderizarCampoDeEntrada() : null}
      <div className="header-right">
        {!estadoLogin ? (
          <a
            className="btn-Login"
            onClick={setOpenTelaLogin}
            style={{ cursor: "pointer" }}
          >
            Login
          </a>
        ) : (
          <div className="container-avatar">
            {/* <div className="container-name">Olá, </div>  */}

            {showList && (
              <div className="container-BoxLogout" ref={avatarRef}>
                <List component="nav" aria-label="mailbox folders">
                  <ListItem
                    button
                    onClick={handleLogoutClick}
                    sx={{ padding: "0px 60px 5px 10px" }}
                  >
                    <ListItemText primary="Logout" />
                  </ListItem>
                </List>
              </div>
            )}
            <Avatar
              alt="Nome do Usuário"
              src="/caminho/para/imagem.png"
              onClick={handleButtonClick}
              className="avatar"
              style={{
                backgroundColor: "rgb(19, 100, 181)",
                position: "fixed",
                right: "10px",
                top: "13px",
              }}
            >
              <PersonIcon />
            </Avatar>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
