import { Fill, Stroke, Style } from "ol/style";
import LayerObserver from "./LayerObserver";
import MapManager from "./MapManager";
import { Feature } from "ol";
import { LineString, MultiPolygon } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Select from "ol/interaction/Select";
import { click } from "ol/events/condition.js";
import { formaterCoordinatesPolygon } from "../Hooks/FormaterCoordinates";
import { Colors } from "./Colors";

class VectorLayerManeger {
  constructor(type, datas, funcao, functionEdit) {
    this.features = null;

    this.layer = null;
    this.interaction = null;

    this.type = type;
    this.datas = formaterCoordinatesPolygon(datas);

    if (type === "Setor") this.index = 1;
    if (type === "Quadra") this.index = 2;
    if (type === "Eixo") this.index = 3;
    if (type === "LotesVizinhos") this.index = 4;
    if (type === "Lote") this.index = 5;
    if (type === "Cobertura") this.index = 6;
    if (type === "Testada") this.index = 7;

    this.function = funcao;

    this.coordenadas = null;

    this.colors = "rgb(177, 208, 143, 0.5)";
    this.style = null;
    this.arrayPolygons = [];

    this.colorsConfig = new Colors(this.type);

    this.functionEdit = functionEdit;

    this.arrayDados = [];
  }

  configEditColor(dadosEdit) {
    this.colorsConfig.editArrayColors(this.functionEdit, dadosEdit);
  }

  configColor(color) {
    this.colorsConfig.serRgbColor(color);
  }

  configInteracition() {
    if (this.function) {
      // Crie a nova interação com as propriedades personalizadas
      this.interaction = new Select({
        condition: click,
        style: this.styleSelect,
        layers: [this.layer],
      });

      this.interaction.TYPE = this.type;

      this.interaction.on("select", (e) => {
        if (e.selected.length > 0) {
          e.color = e.selected[0].values_.COLOR;
          e.id = e.selected[0].values_.ID;
          this.function(e.selected[0].values_);
          this.interaction.getFeatures().clear(); // Remove a seleção atual
        }
      });
    }
  }

  configFeature() {
    this.features = this.datas.map((dadoLayer, index) => {
      this.configEditColor(dadoLayer.id);
      this.colors = this.colorsConfig.fillColor(index);
      this.arrayDados.push({ dados: dadoLayer.id, color: this.colors });
      let feature;
      if (this.type !== "Eixo") {
        feature = new Feature({
          geometry: new MultiPolygon(dadoLayer.coordenadas),

          COLOR: this.colors,
          ID: dadoLayer.id,
        });
      } else {
        feature = new Feature({
          geometry: new LineString(dadoLayer.coordenadas[0][0]),

          COLOR: this.colors,
          ID: dadoLayer.id,
        });
      }

      return feature;
    });
  }

  configVector() {
    this.layer = new VectorLayer({
      source: new VectorSource({
        features: this.features,
      }),
      style: (feature) => {
        if (this.type === "Eixo") {
          return new Style({
            fill: new Fill({
              color: feature.get("COLOR"),
            }),
            stroke: new Stroke({
              color: feature.get("COLOR"),
              width: 4,
            }),
          });
        } else {
          if (this.type === "Testada") {
            return new Style({
              fill: new Fill({
                color: feature.get("COLOR"),
              }),
              stroke: new Stroke({
                color: feature.get("COLOR"),
                width: 4,
              }),
            });
          } else {
            return new Style({
              fill: new Fill({
                color: feature.get("COLOR"),
              }),
              stroke: new Stroke({
                color: "#000000",
                width: 2,
              }),
            });
          }
        }
      },
    });

    // Configuração para filtragem
    this.layer.TYPE = this.type;
    this.layer.DADOS = this.arrayDados;

    // Configuração para ordenar as camadas corretamente
    this.layer.setZIndex(this.index);
  }

  initLayer() {
    this.configEditColor();

    this.configFeature();
    this.configVector();

    this.configInteracition();
  }

  getLayer() {
    return this.layer;
  }

  getInteraction() {
    return this.interaction;
  }
}

export default VectorLayerManeger;
