import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Api } from "../../../../services/Api.js";
import "../ModalPontoInteresse/ModalPontoInteresse.css"

function ModalPontoInteresse({ atualizar, onClose, pontoInteresse, respostaApi }) {
    const options = [
        { value: "Áreas públicas", label: "Áreas públicas" },
        { value: "Associações", label: "Associações" },
        { value: "Banco", label: "Banco" },
        { value: "Cemitério", label: "Cemitério" },
        { value: "Clube de serviço/social", label: "Clube de serviço/social" },
        { value: "Delegacia", label: "Delegacia" },
        { value: "Educação", label: "Educação" },
        { value: "Hotel/Pousada", label: "Hotel/Pousada" },
        { value: "Indústria", label: "Indústria" },
        { value: "Instalações da administração pública", label: "Instalações da administração pública" },
        { value: "Instalação militar", label: "Instalação militar" },
        { value: "Loja maçônica", label: "Loja maçônica" },
        { value: "Religioso", label: "Religioso" },
        { value: "Saúde", label: "Saúde" },
        { value: "Sindicato", label: "Sindicato" },
    ];

    const [objPontoInteresse, setObjPontoInteresse] = useState({
        nome: pontoInteresse.nome || "",
        tipo: pontoInteresse.tipo,

    });

    const [searching, setSearching] = useState(false);
    const [resultReturned, setResultReturned] = useState(false);


    const InputChange = (event) => {
        const name = event.target.name;
        const valor = event.target.value;
        setObjPontoInteresse(valores => ({ ...valores, [name]: valor }));
    };

    const submitForm = async (event) => {
        event.preventDefault();
        setSearching(true);

        if (Object.keys(pontoInteresse).length > 0) {
            const valor = {
                id: pontoInteresse.id,
                tipo: objPontoInteresse.tipo,
                nome: objPontoInteresse.nome,
            };
            const response = await Api.updatePontoInteresse(valor);
            if (!response.error) {
                setResultReturned(true);
            }
            setSearching(false);
        } else {
            const valor = {
                nome: objPontoInteresse.nome,
                tipo: objPontoInteresse.tipo,
                unidadeId: respostaApi.unidade.id
            };
            const response = await Api.createPontoInteresse(valor);
            if (!response.error) {
                setResultReturned(true);
                const totalUsuarios = parseInt(localStorage.getItem("infoTotalPontoInteresse")) + 1;
                localStorage.setItem("infoTotalPontoInteresse", totalUsuarios);
            }
            setSearching(false);
        }
        atualizar();
    };


    return (

        <div className="modalPontoInteresse" onClick={onClose}>
            <div className="modalPontoInteresse-content" onClick={(e) => e.stopPropagation()}>
                <div className="modalPontoInteresse-close-bnt">
                    <Button
                        aria-label="Fechar"
                        style={{ padding: 0, minWidth: 0 }}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </Button>
                </div>

                <h2 className="modalPontoInteresse-titulo" >Ponto de Interesse </h2>
                <form onSubmit={submitForm}>

                    <div className="modalPontoInteresse-form-group">
                        <label htmlFor="pontoInteresse" className="modalPontoInteresse-label">
                            Ponto de Interesse:
                        </label>
                        <select
                            className="modalPontoInteresse-select"
                            name="tipo"
                            value={objPontoInteresse.tipo}
                            onChange={InputChange}
                            required
                        >
                            {options.map((option) => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="Nome" className="modalPontoInteresse-label">
                            Nome:
                        </label>
                        <input
                            className="modalPontoInteresse-input"
                            type="text"
                            name="nome"
                            value={objPontoInteresse.nome}
                            onChange={InputChange}
                            required
                            autoComplete="off"
                        />
                    </div>

                    <div className="form-row">
                        <Button
                            style={{ width: "100%", marginTop: "10px" }}
                            variant="contained"
                            onClick={submitForm}
                        >
                            {searching ? (
                                <>
                                    <FontAwesomeIcon
                                        icon={faSpinner} spin
                                        style={{ marginRight: "0.5em" }}
                                    />
                                    Carregando...
                                </>
                            ) : (
                                resultReturned ? (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            style={{ marginRight: "0.5em" }}
                                        />
                                        Salvo
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faFloppyDisk}
                                            style={{ marginRight: "0.5em" }}
                                        />
                                        Salvar
                                    </>
                                )
                            )}

                        </Button>
                    </div>

                </form>
            </div>
        </div>

    );
};

export default ModalPontoInteresse;
