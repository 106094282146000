import React, { useContext, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { ContextMap } from "../../ContextMap/ContextMap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import '../FloatingButton/FloatingButton.css'

const itensCheckbox = [
    {
        id: 'Hidrografia_de_Sao_Lourenco', //titulo
        label: 'Hidrografia de São Lourenço',
        subOptions: [
            {
                id: 'Corrego_abencoada ', //subtitulo das opções
                label: 'Córrego Abençoada',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Corrego Abencoada',
                ativo: true
            },
            {
                id: 'Cachoeirinha',
                label: 'Córrego Cachoerinha',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Cachoeirinha',
                ativo: true
            },
            {
                id: 'corrego_beco901',
                label: 'Córrego do Beco 901',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:corrego_beco901',
                ativo: true
            },
            {
                id: 'corregoPocos',
                label: 'Córrego dos Poços',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:corregoPocos',
                ativo: true
            },
            {
                id: 'corrego_esperanca',
                label: 'Córrego Esperança',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:corrego_esperanca',
                ativo: true
            },
            {
                id: 'Ipe',
                label: 'Córrego Ipê',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Ipe',
                ativo: true
            },
            {
                id: 'corrego_jardim',
                label: 'Córrego Jardim',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:corrego_jardim',
                ativo: true
            },
            {
                id: 'Miguela',
                label: 'Córrego Miguela',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Miguela',
                ativo: true
            },
            {
                id: 'Tijuco_preto',
                label: 'Córrego Tijuco Preto',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Tijuco_preto',
                ativo: true
            },
            {
                id: 'RibSL_H',
                label: 'Rib São Lourenço',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:SaoLourenco:RibSL_H',
                ativo: true
            },
            {
                id: 'Taboa_Rosario',
                label: 'Rib Taboão/Rosário',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Taboa_Rosario',
                ativo: true
            },
            {
                id: 'rio_verde',
                label: 'Rio Verde',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:rio_verde',
                ativo: true
            },
        ],
    },
    {
        id: 'Mancha_urbana', //titulo
        label: 'Mancha Urbana de São Lourenço',
        subOptions: [
            {
                id: 'Mancha_urbana_SL', //subtitulo das opções
                label: 'Mancha Urbana de São Lourenço',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Mancha_urbana_SL',
                ativo: true
            },
            // ERRO
            // {
            //     id: 'Pluvial',
            //     label: 'Estações Pluviais',
            //     url: 'SaoLourenco',
            //     layer: 'SaoLourenco:est_pluviometricas_propostas',
            //     ativo: true
            // },
        ],
    },
    {
        id: 'Postos_monitoramento', //titulo
        label: 'Postos monitoramento hidrológico hidráulico propostos',
        subOptions: [
            {
                id: 'Perimetro_urbano', //subtitulo das opções
                label: 'Estações Fluviometricas Propostas',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:est_fluviometricas_propostas',
                ativo: true
            },
            {
                id: 'Pluvial',
                label: 'Estações Pluviometricas Propostas',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:est_pluviometricas_propostas',
                ativo: true
            },
        ],
    },
    {
        //CAMADA = TITULO
        id: 'Arruamento',
        label: 'Arruamento São Lourenço',
        subOptions: [
            {
                id: 'Arruamento',
                label: 'Arruamento São Lourenço',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Arruamento SL',
                ativo: true
            },
        ],
    },
    {
        id: 'Bacias_hidrograficas',
        label: 'Bacias hidrográficas',
        subOptions: [
            {
                id: 'Corrego Abencoada_BH',
                label: 'BH Córrego da Abençoada',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Corrego Abencoada_BH',
                ativo: true
            },
            {
                id: 'Cachoeirinha_BH',
                label: 'BH Córrego da Cachoeirinha',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Cachoeirinha_BH',
                ativo: true
            },
            {
                id: 'esperanca_BH',
                label: 'BH Córrego da Esperança',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:esperanca_BH',
                ativo: true
            },
            {
                id: 'Miguela_BH',
                label: 'BH Córrego da Miguela',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Miguela_BH',
                ativo: true
            },
            {
                id: 'corrego_beco901_BH',
                label: 'BH Córrego do Beco 901',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:corrego_beco901_BH',
                ativo: true
            },
            {
                id: 'Ipe_BH',
                label: 'BH Córrego do Ipê',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Ipe_BH',
                ativo: true
            },
            {
                id: 'Tijuco_preto_BH',
                label: 'BH Córrego do Tijuco Preto',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Tijuco_preto_BH',
                ativo: true
            },
            {
                id: 'BH_corrego_dos_pocos',
                label: 'BH Córrego dos Poços',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:corregoPocos_BH',
                ativo: true
            },
            {
                id: 'corrego_jardim_BH',
                label: 'BH Córrego Jardim',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:corrego_jardim_BH',
                ativo: true
            },
            {
                id: 'RibSL_BH',
                label: 'BH Ribeirão São Lourenço',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:RibSL_BH',
                ativo: true
            },
            {
                id: 'Taboa_Rosario_BH',
                label: 'BH Riberão Taboão/Rosário',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Taboa_Rosario_BH',
                ativo: true
            },
        ],
    },
    {
        //CAMADA = TITULO
        id: 'Limitess_municipio',
        label: 'Limites Municípios de São Lourenço',
        subOptions: [
            {
                id: 'Limitess_municipio',
                label: 'Limites Municípios de São Lourenço',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Limitess_municipio',
                ativo: true
            },
        ]
    },
    {
        id: 'Localizacao_das_obras',
        label: 'Localização das obras(reservatórios)',
        subOptions: [
            {
                id: 'Reservatorio_esperanca_1',
                label: 'Resenvatório esperança 1',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Reservatorio_esperanca_1',
                ativo: true
            },
            {
                id: 'Reservatorio_esperanca_2',
                label: 'Resenvatório esperança 2',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Reservatorio_esperanca_2',
                ativo: true
            },
            {
                id: 'Reservatorio_helena',
                label: 'Resenvatório Helena',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Reservatorio_helena',
                ativo: true
            },
            {
                id: 'Reservatorio_miguela',
                label: 'Resenvatório Miguela',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Reservatorio_miguela',
                ativo: true
            },
            {
                id: 'Reservatorio_Pulmao_Esperanca1',
                label: 'Resenvatório Pulmão Esperança 1',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Reservatorio_Pulmao_Esperanca1',
                ativo: true
            },
            {
                id: 'Reservatorio_Pulmao_Esperanca2',
                label: 'Resenvatório Pulmão Esperança 2',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Reservatorio_Pulmao_Esperanca2',
                ativo: true
            },
        ],
    },
    {
        id: 'Localizacao_postos_existentes',
        label: 'Localização postos existentes',
        subOptions: [
            {
                id: 'est_fluviometricas_existentes',
                label: 'Estações Fluviometricas existentes',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:est_fluviometricas_existentes',
                ativo: true
            },
            {
                id: 'est_pluviometricas_existentes',
                label: 'Estações Pluviometricas existentes',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:est_pluviometricas_existentes',
                ativo: true
            },
        ],
    },
    {
        id: 'Manchas_de_inundacao',
        label: 'Manchas de inundação',
        subOptions: [
            {
                id: 'Beco_901',
                label: 'Beco 901',
                url: 'SaoLourenco',
                layer: [
                    'SaoLourenco:Beco_901_MI_TR5',
                    'SaoLourenco:Beco_901_MI_TR10',
                    'SaoLourenco:Beco_901_MI_TR25',
                    'SaoLourenco:Beco_901_MI_TR50',
                    'SaoLourenco:Beco_901_MI_TR100'
                ],
                ativo: true
            },
            {
                id: 'Corrego_cachoeirinha',
                label: 'Córrego Cachoeirinha',
                url: 'SaoLourenco',
                layer: [
                    'SaoLourenco:Corrego_cachoeirinha_MI_TR5',
                    'SaoLourenco:Corrego_cachoeirinha_MI_TR10',
                    'SaoLourenco:Corrego_cachoeirinha_MI_TR25',
                    'SaoLourenco:Corrego_cachoeirinha_MI_TR50',
                    'SaoLourenco:Corrego_cachoeirinha_MI_TR100'
                ],
                ativo: true
            },
            {
                id: 'Corrego_dos_Pocos',
                label: 'Córrego dos Poços',
                url: 'SaoLourenco',
                layer: [
                    'SaoLourenco:Corrego_pocos_MI_TR5',
                    'SaoLourenco:Corrego_pocos_MI_TR10',
                    'SaoLourenco:Corrego_pocos_MI_TR25',
                    'SaoLourenco:Corrego_pocos_MI_TR50',
                    'SaoLourenco:Corrego_pocos_MI_TR100'
                ],
                ativo: true
            },
            {
                id: 'Corrego_esperanca',
                label: 'Córrego Esperança',
                url: 'SaoLourenco',
                layer: [
                    'SaoLourenco:Corrego_esperanca_MI_TR5',
                    'SaoLourenco:Corrego_esperanca_MI_TR10',
                    'SaoLourenco:Corrego_esperanca_MI_TR25',
                    'SaoLourenco:Corrego_esperanca_MI_TR50',
                    'SaoLourenco:Corrego_esperanca_MI_TR100',
                ],
                ativo: true
            },
            {
                id: 'Corrego_ipe',
                label: 'Córrego Ipê',
                url: 'SaoLourenco',
                layer: [
                    'SaoLourenco:Corrego_Ipe_MI_TR5',
                    'SaoLourenco:Corrego_Ipe_MI_TR10',
                    'SaoLourenco:Corrego_Ipe_MI_TR25',
                    'SaoLourenco:Corrego_Ipe_MI_TR50',
                    'SaoLourenco:Corrego_Ipe_MI_TR100',
                ],
                ativo: true
            },
            {
                id: 'Corrego_jardim',
                label: 'Córrego Jardim',
                url: 'SaoLourenco',
                layer: [
                    'SaoLourenco:Corrego_Jardim_MI_TR5',
                    'SaoLourenco:Corrego_Jardim_MI_TR10',
                    'SaoLourenco:Corrego_Jardim_MI_TR25',
                    'SaoLourenco:Corrego_Jardim_MI_TR50',
                    'SaoLourenco:Corrego_Jardim_MI_TR100',
                ],
                ativo: true
            },
            {
                id: 'Corrego_miguela',
                label: 'Córrego Miguela',
                url: 'SaoLourenco',
                layer: [
                    'SaoLourenco:Corrego_Miguela_MI_TR5',
                    'SaoLourenco:Corrego_Miguela_MI_TR10',
                    'SaoLourenco:Corrego_Miguela_MI_TR25',
                    'SaoLourenco:Corrego_Miguela_MI_TR50',
                    'SaoLourenco:Corrego_Miguela_MI_TR100',
                ],
                ativo: true
            },
            {
                id: 'Corrego_tijuco_preto',
                label: 'Córrego Tijuco Preto',
                url: 'SaoLourenco',
                layer: [
                    'SaoLourenco:Corrego_Tijuco_Preto_MI_TR5',
                    'SaoLourenco:Corrego_Tijuco_Preto_MI_TR10',
                    'SaoLourenco:Corrego_Tijuco_Preto_MI_TR25',
                    'SaoLourenco:Corrego_Tijuco_Preto_MI_TR50',
                    'SaoLourenco:Corrego_Tijuco_Preto_MI_TR100',
                ],
                ativo: true
            },
            {
                id: 'Ribeirao_sao_lourenco',
                label: 'Ribeirão São Lourenço',
                url: 'SaoLourenco',
                layer: [
                    'SaoLourenco:Ribeirao_Sao_Lourenco_MI_TR5_2024',
                    'SaoLourenco:Ribeirao_Sao_Lourenco_MI_2024_TR10',
                    'SaoLourenco:Ribeirao_Sao_Lourenco_MI_2024_TR25',
                    'SaoLourenco:Ribeirao_Sao_Lourenco_MI_2024_TR50',
                ],
                ativo: true
            },
            {
                id: 'Riberao_Taboao_Rosario',
                label: 'Riberão Taboão/Rosário',
                url: 'SaoLourenco',
                layer: [
                    'SaoLourenco:Riberao_Taboao_Rosario_MI_TR5',
                    'SaoLourenco:Riberao_Taboao_Rosario_MI_TR10',
                    'SaoLourenco:Riberao_Taboao_Rosario_MI_TR25',
                    'SaoLourenco:Riberao_Taboao_Rosario_MI_TR50',
                    'SaoLourenco:Riberao_Taboao_Rosario_MI_TR100',
                ],
                ativo: true
            },
        ],
    },
    {
        id: 'Pedologia',
        label: 'Pedologia',
        subOptions: [
            {
                id: 'Pedologia',
                label: 'Pedologia',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Pedologia_P',
                ativo: true
            },
        ],
    },
    {
        id: 'Uso_e_ocupacao',
        label: 'Uso e ocupação',
        subOptions: [
            {
                id: 'Usos_BH_Abencoada_UO',
                label: 'Usos BH Abençoada',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Usos_BH_Abencoada_UO',
                ativo: true
            },
            {
                id: 'Usos_BH_Beco_901_UO',
                label: 'Usos BH Beco 901',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Usos_BH_Beco_901_UO',
                ativo: true
            },
            {
                id: 'Usos_BH_Cachoeirinha_UO',
                label: 'Usos BH Cachoeirinha',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Usos_BH_Cachoeirinha_UO',
                ativo: true
            },
            // {
            //     id: 'Usos_BH_Esperanca ',
            //     label: 'Usos BH Esperança ',
            //     url: 'SaoLourenco',
            //     layer: '',
            //     ativo: true
            // },
            {
                id: 'Usos_BH_Ipe_UO',
                label: 'Usos BH Ipe',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Usos_BH_Ipe_UO',
                ativo: true
            },
            {
                id: 'Usos_BH_Jardim_UO',
                label: 'Usos BH Jardim',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Usos_BH_Jardim_UO',
                ativo: true
            },
            {
                id: 'Usos_BH_Miguela_UO',
                label: 'Usos BH Miguela',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Usos_BH_Miguela_UO',
                ativo: true
            },
            // {
            //     id: 'Usos_BH_pocos ',
            //     label: 'Usos BH Poços',
            //     url: 'SaoLourenco',
            //     layer: 'SaoLourenco:Usos_BH_pocos ',
            //     ativo: true
            // },
            // {
            //     id: 'Usos_BH_sao_lourenco ',
            //     label: 'Usos BH São Lourenço',
            //     url: 'SaoLourenco',
            //     layer: 'SaoLourenco:Usos_BH_sao_lourenco ',
            //     ativo: true
            // },
            {
                id: 'Usos_BH_Taboao_Rosario_UO',
                label: 'Usos BH Taboão/Rosario',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Usos_BH_Taboao_Rosario_UO',
                ativo: true
            },
            {
                id: 'Usos_BH_Tijuco_Preto_UO',
                label: 'Usos BH Tijuco Preto',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:Usos_BH_Tijuco_Preto_UO',
                ativo: true
            },
            // {
            //     id: 'Usos_municipio_de_Sao_lourenco ',
            //     label: 'Usos município de São Lourenço',
            //     url: 'SaoLourenco',
            //     layer: 'SaoLourenco:Usos_municipio_de_Sao_lourenco ',
            //     ativo: true
            // },
        ],
    },
    {
        id: 'Zoneamento_planicie_inundacao',
        label: 'Zoneamento Planicie Inundação',
        subOptions: [
            // {
            //     id: 'Apendice_A',
            //     label: 'Apêndice A',
            //     url: 'SaoLourenco',
            //     layer: 'SaoLourenco:Apendice_A',
            //     ativo: true
            // },
            {
                id: 'Beco_901_ZPI_APP_beco901',
                label: 'Beco 901',
                url: 'SaoLourenco',
                layer: [
                    // 'SaoLourenco:Beco_901_ZPI_APP_beco901',
                    'SaoLourenco:Beco_901_ZPI_ZRI_Beco901',
                    'SaoLourenco:Beco_901_ZPI_beco901_extensao',
                    'SaoLourenco:Beco_901_ZPI_ZPC_Beco901',
                    'SaoLourenco:Beco_901_ZPI_ZPCO_beco',
                    'SaoLourenco:Beco_901_ZPI_beco901',
                ],
                ativo: true
            },
            {
                id: 'Cachoeirinha',
                label: 'Cachoeirinha',
                url: 'SaoLourenco',
                layer: [
                    'SaoLourenco:Cachoeirinha_ZPI_ZPC_Cacho',
                    'SaoLourenco:Cachoeirinha_ZPC_ZRI_Cacho'
                ],
                ativo: true
            },
            {
                id: 'Esperanca',
                label: 'Esperança',
                url: 'SaoLourenco',
                layer: [
                    'SaoLourenco:Esperanca_ZPI_ManchaTR100_Esperanca',
                    'SaoLourenco:Esperanca_ZPC_ManchaTR100Esperanca',
                    'SaoLourenco:Esperanca_ZPC_ManchaTR5Esperanca',
                    'SaoLourenco:Esperanca_ZPC_ZPC_desenhada_esperanca',
                    'SaoLourenco:Esperanca_ZPC_ZPCO_Esperanca',
                    'SaoLourenco:Esperanca_ZPC_ZRI_desenhada_esperanca',
                ],
                ativo: true
            },
            // {
            //     id: 'Mapas_PNG',
            //     label: 'Mapas PNG',
            //     url: 'SaoLourenco',
            //     layer: 
            //     ativo: true
            // },
            {
                id: 'Jardim',
                label: 'Jardim',
                url: 'SaoLourenco',
                layer: [
                    'SaoLourenco:Jardim_ZPI_App_Jardim',
                    'SaoLourenco:Jardim_ZPI_ZPC_Jardim',
                    'SaoLourenco:Jardim_ZPI_ZPC_Jardim_desenhada',
                    'SaoLourenco:Jardim_ZPI_ZPC_Jardim_smooth',
                    'SaoLourenco:Jardim_ZPI_ZPCO_Jardim',
                    'SaoLourenco:Jardim_ZPI_ZRI_Jardim',
                ],
                ativo: true
            },
            {
                id: 'Miguela',
                label: 'Miguela',
                url: 'SaoLourenco',
                layer: [
                    'SaoLourenco:Miguela_ZPI_TR100',
                    'SaoLourenco:Miguela_ZPI_TR5',
                    'SaoLourenco:Miguela_ZPI_app_corMiguela',
                    'SaoLourenco:Miguela_ZPI_APP_Miguela',
                    'SaoLourenco:Miguela_ZPI_ManchaTR100_Miguela',
                    'SaoLourenco:Miguela_ZPI_ManchaTR100_Miguela_desenhada',
                    'SaoLourenco:Miguela_ZPI_ManchaTR5_Miguela',
                    'SaoLourenco:Miguela_ZPI_ManchaTR5_Miguela_desenhada',
                    'SaoLourenco:Miguela_ZPI_Miguela',
                    'SaoLourenco:Miguela_ZPI_reach',
                    'SaoLourenco:Miguela_ZPI_ZPCO_Miguela',
                ],
                ativo: true
            },
            // {
            //     id: 'Pocos',
            //     label: 'Poços',
            //     url: 'SaoLourenco',
            //     layer: '',
            //     ativo: true
            // },
            {
                id: 'RiberaoSl',
                label: 'Riberao Sl',
                url: 'SaoLourenco',
                layer: [
                    'SaoLourenco:RiberaoSl_ZPI_BH_Ribeirao_SL',
                    'SaoLourenco:RiberaoSl_ZPI_ZPC_desenhada',
                    'SaoLourenco:RiberaoSl_ZPI_ZRI_desenhada'
                ],
                ativo: true
            },
            {
                id: 'RioVerde',
                label: 'Rio Verde',
                url: 'SaoLourenco',
                layer: 'SaoLourenco:RioVerde_ZPI_Rio_Verde',
                ativo: true
            },
            {
                id: 'Taboao',
                label: 'Taboao',
                url: 'SaoLourenco',
                layer: [
                    'SaoLourenco:Taboao_ZPI_RibTaboao',
                    'SaoLourenco:Taboao_ZPI_ZPC_Taboao',
                    'SaoLourenco:Taboao_ZPI_ZPC_Taboao_smooth',
                    'SaoLourenco:Taboao_ZPI_ZRI_taboao',
                    'SaoLourenco:Taboao_ZPI_ZRI_taboao_smooth'
                ],
                ativo: true
            },
            {
                id: 'Tijuco_Preto',
                label: 'Tijuco-Preto',
                url: 'SaoLourenco',
                layer: [
                    'SaoLourenco:Tijuco_Preto_ZPI_App_Tijuco',
                    'SaoLourenco:Tijuco_Preto_ZPI_ZPC_Miguela',
                    'SaoLourenco:Tijuco_Preto_ZPI_ZPCO_Miguela',
                    'SaoLourenco:Tijuco_Preto_ZPI_ZRI_Miguela'
                ],
                ativo: true
            },
        ],
    },
    // {
    //     id: 'Ortofoto',
    //     label: 'Ortofoto',
    //     subOptions: [
    //         {
    //             id: 'Ortofoto',
    //             label: 'Ortofoto',
    //             url: 'SaoLourenco',
    //             layer: 'SaoLourenco:satelinovo',
    //             ativo: true
    //         },
    //     ],
    // },
];



export default function FloatingButton() {
    const [showList, setShowList] = useState(false);
    const [unselectAll, setUnselectAll] = useState(false);

    return (
        <div className="floating-button">
            <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <Fab color="primary" onClick={() => setShowList(!showList)}>
                    <FontAwesomeIcon icon={faLayerGroup} size="xl" />
                </Fab>
            </Box>
          
                <div className={`float-container ${showList ? 'mostrar' : ''}`} >
                    <ul className="float-container-ul">
                        <div className="float-border">
                            <button className="float-button-unselect" onClick={() => setUnselectAll(true)}>Desmarcar tudo</button>
                            {itensCheckbox.map((item, index,) => (
                                <Opcoes
                                    key={item.id}
                                    label={item.label}
                                    subOptions={item.subOptions}
                                    index={index}
                                    length={itensCheckbox.length}
                                    unselectAll={unselectAll}
                                    setUnselectAll={setUnselectAll}
                                />
                            ))}
                        </div>
                    </ul>
                </div>
            
        </div>
    );
};


const Opcoes = ({ label, subOptions, index, length, unselectAll, setUnselectAll }) => {
    const [showSubmenu, setShowSubmenu] = useState(false);
    const { adicionarObjeto, removerObjeto, listaCamadas, removerTodosObjetos } = useContext(ContextMap);

    const obterCheckedCheckboxes = () => {
        const checkboxesInicial = {};
        listaCamadas.forEach(camada => {
            checkboxesInicial[camada.id] = true;
        });
        return checkboxesInicial;
    };

    const [checkedCheckboxes, setCheckedCheckboxes] = useState(obterCheckedCheckboxes());

    function RegisterCheckbox(event) {
        const checkboxId = event.target.name;
        const checked = event.target.checked;
        const checkedItem = itensCheckbox.map(item => item.subOptions).flat().find(subOption => subOption.id === checkboxId);
        setCheckedCheckboxes({ ...checkedCheckboxes, [checkboxId]: checked });
        if (checked) {
            adicionarObjeto(checkedItem);
        }
        else {
            removerObjeto(checkedItem.id);
        }

    };

    // function border(index) {
    //     if (index === 0) {
    //         return 'float-border-first'
    //     }
    //     else if (index === length - 1 && !showSubmenu) {
    //         return 'float-border-last'
    //     }
    // }

    function lastBorder(index, length, subIndex, subLength) {
        if (index === length - 1 && subIndex === subLength - 1) {
            return 'float-border-last'
        }
    }

    function removerTodosCheckboxes() {
        const checkboxesInicial = {};
        listaCamadas.forEach(camada => {
            checkboxesInicial[camada.id] = false;
        });
        setCheckedCheckboxes(checkboxesInicial);
        removerTodosObjetos();
    }

    useEffect(() => {
        if (unselectAll) {
            removerTodosCheckboxes();
            setUnselectAll(false);
        }
    }, [unselectAll])

    return (
        <li>
            <button className={`toggle ${subOptions.some(subOption => checkedCheckboxes[subOption.id]) ? 'checked' : ''}`} onClick={() => setShowSubmenu(!showSubmenu)}>
                {showSubmenu ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
                {label}
            </button>
            {showSubmenu && (
                <ul>
                    {subOptions.map((subOption, subIndex) => (
                        <li key={subOption.id} className={`toggle ${lastBorder(index, length, subIndex, subOptions.length)} ${checkedCheckboxes[subOption.id] ? 'checked' : ''}`}>
                            <input
                                type="checkbox"
                                checked={checkedCheckboxes[subOption.id] || false}
                                onChange={RegisterCheckbox}
                                name={subOption.id}
                                id={subOption.id}
                            />
                            <label htmlFor={subOption.id}>{subOption.label}</label>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
};