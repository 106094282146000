import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { defaults as defaultControls } from "ol/control";
import { transform } from "ol/proj";

class MapManager {
  constructor(center) {
    this.map = new Map({
      layers: [
        new TileLayer({
          preload: Infinity,
          source: new OSM({
            attributions: "",
          }),
        }),
      ],
      view: new View({
        center,
        zoom: 11,
        projection: "EPSG:4326",
      }),
      controls: defaultControls({
        attribution: false,
        zoom: false,
        rotate: false,
      }),
    });

    this.interactionsRemovidas = [];
    this.layersRemovidos = [];
    this.layers = [];
    this.observers = [];
  }

  setTarget(target) {
    this.map.setTarget(target);
  }

  addLayer(layer) {
    this.map.addLayer(layer);
    this.layers.push(layer);
  }

  removeLayer(layer) {
    this.map.removeLayer(layer);
    this.layers = this.layers.filter((l) => l !== layer);
  }

  addObserver(observerLayers) {
    this.observers.push(observerLayers);
  }

  removeObserver(observerLayers) {
    this.observers = this.observers.filter((obs) => obs !== observerLayers);
  }

  // Função para ajustar o ponto central do mapa
  setCenter(center) {
    this.map.getView().setCenter(center);
  }

  // Função para ajustar o nível de zoom
  setZoom(zoom) {
    this.map.getView().setZoom(zoom);
  }
}

export default MapManager;
