import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Radio,
  RadioGroup,
  FormLabel,
  Divider,
  Box,
  Pagination,
  Button,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";

import GeoJSON from "ol/format/GeoJSON.js";
import Map from "ol/Map.js";
import View from "ol/View.js";
import { Fill, Stroke, Style } from "ol/style.js";
import { OSM, Vector as VectorSource } from "ol/source.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer.js";
import proj4 from "proj4";

import { Api } from "../../../services/Api.js";

import "./ModalMesmoLote.css";

const ModalMesmoLote = ({ onClose, idAdversidade }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedValueCodigo, setSelectedValueCodigo] = useState("");
  const [valueTabs, setValueTabs] = useState(0);
  const [valueRadioGroup, setValueRadioGroup] = useState("");
  const [coordendasMap, setCoordendasMap] = useState([]);
  const [valueRadioGroupCobertura, setValueRadioGroupCobertura] = useState("");
  const [valueRadioAssociarCoberturas, setValueRadioAssociarCoberturas] =
    useState("");

  const [isTabDisabled, setIsTabDisabled] = useState(true); //habilitar  e desabilidar o tab

  const handleSubmit = (e) => {
    console.log(filteredData);
  };

  const onCloseClick = () => {
    onClose();
  };

  //Para a chamada da função para sair
  const onModalClick = (e) => {
    e.stopPropagation();
  };

  const [filteredData, setFilteredData] = useState("");
  const [coordenadasPoligono, setCoordenadasPoligono] = useState([]);
  const [pontoCentralMap, setPontoCentralMap] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await Api.getAdversidadesId(idAdversidade);
        // Remover elementos com nome nulo
        const filteredData = data.data.result;
        setFilteredData(filteredData);

        const coordenadas = await Api.getMapaCodigo(filteredData.lote.id);
        const coordendasMap2 = coordenadas.data.lote.geom.coordinates
          .flatMap((subarray) => subarray)
          .flat();
        setCoordenadasPoligono(coordendasMap2);
        setPontoCentralMap(coordenadas.data.pontoCentral[0].geom.coordinates);
        configurarCoordenadaCentral();
        configurarCoordenada(coordenadasPoligono);
      } catch (error) {
        console.log("Deu erro:", error);
      }
    };

    fetchData();
  }, []);

  const [state, setState] = useState(false);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeRadioGroup = (event) => {
    if (valueRadioGroup === event.target.value) {
      setValueRadioGroup("");
    } else setValueRadioGroup(event.target.value);
  };

  const handleChangeRadioGroupCobertura = (event) => {
    if (valueRadioGroupCobertura === event.target.value) {
      setValueRadioGroupCobertura("");
    } else setValueRadioGroupCobertura(event.target.value);
  };

  const handleChangeRadioAssociarCoberturas = (event) => {
    if (valueRadioAssociarCoberturas === event.target.value) {
      setValueRadioAssociarCoberturas("");
    } else setValueRadioAssociarCoberturas(event.target.value);
  };

  const mapRef = useRef(null);
  const [currentZoom, setCurrentZoom] = useState(5);

  //useState coordenadas
  const [coordenadas, setCoordenadas] = useState([]);

  //Configura a lista de acordo com o tamanho da tela
  // useEffect(() => {
  //   if (window.innerHeight > 750) {
  //     const numberPerPage = 12;
  //     setRowsPerPage(numberPerPage)
  //   }
  // }, [])

  const styles = {
    MultiPolygon: new Style({
      stroke: new Stroke({
        color: "blue",
        width: 1,
      }),
      fill: new Fill({
        color: "rgba(255, 255, 0, 0.1)",
      }),
    }),
  };

  const styleFunction = function (feature) {
    return styles[feature.getGeometry().getType()];
  };

  const configurarCoordenada = (coordenadasPoligono) => {
    const utmProjection =
      "+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs";
    const latLngProjection = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";

    const latLngCoordinatesArray = coordenadasPoligono.map((coordenadas) => {
      return proj4(utmProjection, latLngProjection, coordenadas);
    });
    setCoordenadasPoligono(latLngCoordinatesArray);
  };

  const geojsonObject = {
    type: "FeatureCollection",
    crs: {
      type: "name",
      properties: {
        name: "EPSG:4326",
      },
    },
    features: [
      {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: [[coordenadasPoligono]],
        },
      },
    ],
  };

  const vectorSource = new VectorSource({
    features: new GeoJSON().readFeatures(geojsonObject),
  });

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: styleFunction,
  });

  const configurarCoordenadaCentral = () => {
    const utmProjection = '+proj=utm +zone=23 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs';
    const latLngProjection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
    const coordenadasLimpo = pontoCentralMap;
    const latLngCoordinates = proj4(
      utmProjection,
      latLngProjection,
      coordenadasLimpo
    );
    setCoordenadas(latLngCoordinates);
  };

  useEffect(() => {
    console.log(coordenadas[0], coordenadas[1]);
    const newMap = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        vectorLayer,
      ],
      view: new View({
        center: [coordenadas[0], coordenadas[1]],
        zoom: 19,
        projection: "EPSG:4326",
      }),
    });
    return () => {
      newMap.dispose();
    };
  }, [coordenadas, currentZoom]);

  return (
    <div className="ModalMesmoLote-modal" onClick={onCloseClick}>
      <div className="container-mesmoLote">
        <div className="ModalMesmoLote-modal-content" onClick={onModalClick}>
          <div className="ModalMesmoLote-close-bnt">
            <Button aria-label="Fechar" onClick={onCloseClick}>
              <CloseIcon />
            </Button>
          </div>
          <div className="container-mesmoLote">
            {/* Coluna 1 */}
            <Card
              variant="outlined"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <h5 className="titulos-mesmolote">
                IDENTIFICAÇÃO DE UNIDADE IMOBILIÁRIA DENTRO DO MESMO LOTE
              </h5>

              <div style={{ margin: "5px" }}>
                <Divider />
                <Box className="box-mesmoLote">
                  <Box sx={{ my: 3, mx: 2 }}>
                    <h6 color="text.secondary">
                      1 - Selecione as coberturas que fazem parte de cada uma
                      das unidades. A associação entre as coberturas é feita
                      pela cor.
                    </h6>
                    <h6 color="text.secondary" variant="body2">
                      2 - Faça a associação entre as testadas do imóvel e os
                      logradouros correspondentes. Atente-se às cores das
                      testadas.
                    </h6>
                    <h6 color="text.secondary" variant="body2">
                      3 - Selecione qual é a testada principal clicando no
                      checkbox.
                    </h6>
                  </Box>
                </Box>
                <Divider />

                <form onSubmit={handleSubmit}>
                  <FormControl style={{ marginTop: 15, marginLeft: 8 }}>
                    <FormLabel id="demo-controlled-radio-buttons-group">
                      Escolha uma testada principal:
                    </FormLabel>

                    <div style={{ display: "flex", gap: "45px" }}>
                      <div style={{ marginTop: 10 }}>
                        <RadioGroup
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={valueRadioGroup}
                          onClick={handleChangeRadioGroup}
                        >
                          <FormControlLabel
                            value="testada1"
                            control={<Radio />}
                            label="Testada 1"
                          />
                          <div
                            style={{
                              background: "#e57373",
                              height: "20px",
                              width: "20px",
                              marginRight: "15px",
                            }}
                          ></div>

                          <FormControlLabel
                            value="testada2"
                            control={<Radio />}
                            label="Testada 2"
                          />
                          <div
                            style={{
                              background: "#90caf9",
                              height: "20px",
                              width: "20px",
                              marginRight: "15px",
                            }}
                          ></div>
                        </RadioGroup>
                      </div>

                      <RadioGroup
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={valueRadioAssociarCoberturas}
                        onClick={handleChangeRadioAssociarCoberturas}
                      >
                        <FormControlLabel
                          style={{ marginTop: "10px" }}
                          value="associarTodasUnidades"
                          control={<Radio />}
                          label="Associar todas as unidades a todas as coberturas?"
                        />
                      </RadioGroup>
                    </div>
                  </FormControl>

                  <Box
                    sx={{
                      width: 800,
                      border: "1px solid black",
                      padding: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ display: "flex", gap: "45px" }}>
                      <h5 className="titulos-mesmolote">
                        UNIDADE - Nº DE INSCRIÇÃO REDUZIDO{" "}
                      </h5>
                      <RadioGroup
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={valueRadioGroupCobertura}
                        onClick={handleChangeRadioGroupCobertura}
                      >
                        <FormControlLabel
                          value="cobertauraX"
                          control={<Radio />}
                          label="Cobertura "
                        />
                        <div
                          style={{
                            background: "#f48fb1",
                            height: "20px",
                            width: "20px",
                            marginRight: "15px",
                          }}
                        ></div>
                      </RadioGroup>
                    </div>

                    <form>
                      <div className="inputs-container-mesmoLote">
                        <div>
                          <form onSubmit={handleSubmit}>
                            <div className="ModalMesmoLote-form-row">
                              <div className="ModalMesmoLote-form-group">
                                <label
                                  htmlFor="setor"
                                  className="ModalMesmoLote-label"
                                >
                                  Setor:
                                </label>
                                <input
                                  className="ModalMesmoLote-fixed"
                                  id="Setor"
                                  // value={filteredData.unidades[0].setorCod}
                                  value={1}
                                  onChange={(e) =>
                                    setSelectedValue(e.target.value)
                                  }
                                  required
                                />
                              </div>

                              <div className="ModalMesmoLote-form-group">
                                <label
                                  htmlFor="quadra"
                                  className="ModalMesmoLote-label"
                                >
                                  Quadra:
                                </label>
                                <input
                                  className="ModalMesmoLote-fixed"
                                  id="Quadra"
                                  value={1}
                                  required
                                  readOnly
                                />
                              </div>

                              <div className="ModalMesmoLote-form-group">
                                <label
                                  htmlFor="setor"
                                  className="ModalMesmoLote-label"
                                >
                                  Lote:
                                </label>
                                <input
                                  className="ModalMesmoLote-fixed"
                                  id="Setor"
                                  value={1}
                                  onChange={(e) =>
                                    setSelectedValue(e.target.value)
                                  }
                                  required
                                />
                              </div>

                              <div className="ModalMesmoLote-form-group">
                                <label
                                  htmlFor="quadra"
                                  className="ModalMesmoLote-label"
                                >
                                  Unidade:
                                </label>
                                <input
                                  className="ModalMesmoLote-fixed"
                                  id="Quadra"
                                  value={1}
                                  required
                                  readOnly
                                />
                              </div>
                            </div>
                          </form>
                        </div>

                        <div className="ModalMesmoLote-form-row">
                          <div
                            style={{ flex: "1" }}
                            className="ModalMesmoLote-form-group"
                          >
                            <label
                              htmlFor="selector"
                              className="ModalMesmoLote-label"
                            >
                              Bairro:
                            </label>
                            <input
                              className="ModalMesmoLote-fixed"
                              id="Quadra"
                              value={1}
                              required
                              readOnly
                            />
                          </div>
                          <div
                            style={{ flex: "0.4" }}
                            className="ModalMesmoLote-form-group"
                          >
                            <label
                              htmlFor="codigo"
                              className="ModalMesmoLote-label"
                            >
                              Número:
                            </label>
                            <input
                              className="ModalMesmoLote-input"
                              type="text"
                              id="Codigo"
                              value={372}
                              required
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="ModalMesmoLote-form-group">
                          <label
                            htmlFor="selector"
                            className="ModalMesmoLote-label"
                          >
                            Logradouro:
                          </label>
                          <select
                            className="ModalMesmoLote-select"
                            id="select"
                            // value={postesIluminacao}
                            // onChange={(e) => setPostesIluminacao(e.target.value)}
                            required
                          >
                            <option value="">Selecione:</option>
                            <option value="">111</option>
                            <option value="">122</option>
                            <option value="">132</option>
                          </select>
                        </div>
                      </div>
                    </form>

                    <div style={{ display: "flex", gap: "10px" }}>
                      <div className="ModalMesmoLote-form-group">
                        <label htmlFor="setor" className="ModalMesmoLote-label">
                          CPF/CNPJ:
                        </label>
                        <input
                          className="ModalMesmoLote-fixed"
                          id="Setor"
                          value={"111.111.111-11"}
                          required
                        />
                      </div>

                      <div className="ModalMesmoLote-form-group">
                        <label
                          htmlFor="quadra"
                          className="ModalMesmoLote-label"
                        >
                          Nome:
                        </label>
                        <input
                          className="ModalMesmoLote-fixed"
                          id="Quadra"
                          value={"JOSE FERNANDES DA SILVA"}
                          required
                          readOnly
                        />
                      </div>
                    </div>
                  </Box>
                </form>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                  count={1}
                  variant="outlined"
                  color="primary"
                  style={{ marginBottom: "10px" }}
                />
              </div>
            </Card>

            {/* colunas 2 */}

            <div
            className="card-mapa-mesmoLote"
            >
              <Card variant="outlined">
                <div>
                  {!valueTabs && (
                    <div>
                      <h5 className="titulos-mesmolote">
                        GEOMETRIAS IDENTIFICADA NA VETORIZAÇÃO:
                      </h5>
                      <FormControl component="fieldset" variant="standard">
                        <FormGroup
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            margin: "10px",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Switch
                                checked={state.gilad}
                                onChange={handleChange}
                                name="coberturas"
                              />
                            }
                            label="Mostrar coberturas"
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                checked={state.jason}
                                onChange={handleChange}
                                name="lotes"
                              />
                            }
                            label="Mostrar lotes"
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                checked={state.antoine}
                                onChange={handleChange}
                                name="lotesVizinhos"
                              />
                            }
                            label="Mostrar lotes vizinhos"
                          />
                        </FormGroup>
                      </FormControl>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          position: "fixed",
                          margin: 0.7,
                          marginLeft: 5,
                          zIndex: 1,
                          width: "fit-content",
                          border: (theme) =>
                            `2px solid ${theme.palette.divider}`,
                          borderRadius: 1,
                          bgcolor: "background.paper",
                          color: "text.secondary",
                          "& svg": { m: 0.5 },
                          "& hr": { mx: 0.5 },
                        }}
                      >
                        <div
                          style={{
                            background: "#e57373",
                            height: "20px",
                            width: "20px",
                            margin: "15px",
                          }}
                        ></div>
                        <h5 style={{ margin: "15px", marginLeft: "0px" }}>
                          Lote
                        </h5>
                        <Divider orientation="vertical" flexItem />

                        <div
                          style={{
                            background: "#90caf9",
                            height: "20px",
                            width: "20px",
                            margin: "15px",
                          }}
                        ></div>
                        <h5 style={{ margin: "15px", marginLeft: "0px" }}>
                          Lotes vizinhos
                        </h5>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flex: "1",
                          backgroundColor: "#AAD3DF",
                        }}
                      >
                        <div
                          ref={mapRef}
                          style={{ width: "100%", height: "550px" }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Card>
            </div>
          </div>


          <div
            className="ModalMesmoLote-form-row"
            style={{ marginTop: "10px" }}
          >
            <Button
              style={{ width: "100%" }}
              onClick={handleSubmit}
              variant="contained"
            >
              <FontAwesomeIcon
                icon={faFloppyDisk}
                style={{ marginRight: "0.5em" }}
              />
              Salvar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalMesmoLote;
