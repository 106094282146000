import React, { useContext, useEffect, useState } from "react";

import { Box, Button, Card, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { InputComponent, SelectComponent } from './Inputs';
import { MapContextAdv } from "../Context/FuncoesMapa";
import './InputCod.css';
import { InputContext } from "../Context/ContextInputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

export function InputCod(props) {
    const [loading, setLoading] = useState(true);
    const [dataAdversidade, setDataAdversidade] = useState("");
    const [formData, setFormData] = useState({});
    const { colorsTestadas, setTestadas, testadas } = useContext(MapContextAdv)

    const {
        ConsultaLote,
        handleChangeNumero,
        handleChangeSelectLogradouro,
        handleChangeRadioGroup,
        validateForm,
        selectValueLogradouro,
        setNumero,
        numero,
        valueRadioGroup,
        errors,
        valueRadioGroupTestada,
        setValueRadioGroupTestada,
        numUnidades,
        setNumUnidades,
        numCodigo,
        setNumCodigo,
        setor,
        loteCod,
        lote,
        distrito,
        quadra,
        toggleContinue
    } = useContext(InputContext)

    return (

        <form style={{ width: '100%' }}>
            <Box sx={{ border: '1px solid black', padding: '10px', flex: 1 }}>
                <div className="InputCod-flex-1">
                    <h5 className="InputCod-margin-h5">Dados do Lote:</h5>

                    <div className="InputCod-form-row">
                        <InputComponent
                            label="Id/Reduzido"
                            id="Id/Reduzido"
                            value={numCodigo}
                            onChange={(e) => setNumCodigo(e.target.value)}
                        />
                        <InputComponent
                            label="Distrito"
                            id="Distrito"
                            value={distrito}
                            required
                            readOnly
                        />
                        <InputComponent
                            label="Setor"
                            id="Setor"
                            value={setor}
                            required
                            readOnly
                        />
                        <InputComponent
                            label="Quadra"
                            id="Quadra"
                            value={quadra}
                            required
                            readOnly
                        />
                        <InputComponent
                            label="Lote"
                            id="Lote"
                            value={loteCod}
                            required
                            readOnly
                        />

                        <div className="InputCod-form-group">
                            <label htmlFor="Distrito" className="InputCod-label">
                                Consultar Lote:
                            </label>
                            <Button
                                sx={{
                                    height: '28px',
                                    padding: '5px',
                                    minWidth: '100px',
                                    verticalAlign: 'middle',
                                }}
                                onClick={ConsultaLote}
                                variant="contained" >
                                Buscar
                            </Button>
                        </div>
                    </div>
                </div>

                <FormControl >

                    {(testadas.length > 0) && <FormLabel id="demo-controlled-radio-buttons-group" style={{ marginLeft: '10px' }}>Testada Principal:</FormLabel>}
                    <div className="InputCod-flex-container" style={{ marginLeft: 8 }}>

                        {testadas.map((dadosTestada, index) => {
                            return (
                                <RadioGroup key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%' }}
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={valueRadioGroupTestada}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', width: '100%' }}>
                                        <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <FormControlLabel value={`${dadosTestada.id}${index}`} control={<Radio />} label={``} onClick={handleChangeRadioGroup} />
                                            <div style={{ background: colorsTestadas[index], height: '20px', width: '20px', marginLeft: '-15px' }} ></div>
                                            <label style={{ marginLeft: '8px' }}>{dadosTestada.logradouronome}</label>
                                        </div>

                                    </div>
                                </RadioGroup>
                            );
                        })}
                    </div>
                    {errors.testadaPrincipal && <span style={{ marginLeft: '10px' }} className="InputCod-SpanInput">{errors.testadaPrincipal}</span>}
                </FormControl>

                <div style={{ marginRight: '10px', width: '100%' }}>
                    <div className="InputCod-form-row">
                        <SelectComponent
                            label="Logradouro da Testada"
                            id="select"
                            value={selectValueLogradouro}
                            required
                            onChange={handleChangeSelectLogradouro}
                            options={testadas.map((logradouro, index) => ({ value: logradouro.logradouronome, label: logradouro.logradouronome }))}
                            errors={errors.logradouroDaTestada}
                        />
                        <InputComponent
                            label="Nº de Unidade(s)"
                            id="Nº de Unidade(s)"
                            value={numUnidades}
                            onChange={(e) => {
                                const filteredValue = e.target.value.replace(/\D/g, '');
                                const newValue = filteredValue.length > 0 ? parseInt(filteredValue) : 0;
                                setNumUnidades(newValue);
                            }}
                            errors={errors.numUnidades}
                        />
                        <div className="InputCod-form-group" >
                            <label htmlFor="codigo" className="InputCod-label">
                                Clique aqui para continuar:
                            </label>
                            {/* <Button className="advEnd-button" onClick={handleSubmit} variant="contained" > */}
                            <Button
                                onClick={toggleContinue}
                                variant="contained"
                                style={{
                                    height: '28px',
                                    padding: '5px',
                                    minWidth: '100px',
                                    verticalAlign: 'middle',
                                }}>
                                Continuar
                            </Button>
                        </div>
                    </div>
                </div>


            </Box>



        </form >
    )
}
