import { Box, MenuItem, TextField, Typography } from "@mui/material"

export const TabAreaCoberta = ({ key, legenda }) => {
    const options = {
        beiral: ["Atípico", "Cobertura", "Inexistente", "Padrão"],
    };
    return (
        <Box key={key} display="flex" flexDirection="column" >
            <Box display="flex" flexDirection="row" >
                <Box position='relative' sx={{ flexBasis: '30%' }}>
                    <Box sx={{
                        width: 20, height: 20, backgroundColor: legenda.color, position: 'absolute', top: 18, right: 15,
                    }} />
                    <TextField
                        label="Área Coberta"
                        value={legenda?.dados?.id}
                        sx={{ flexBasis: '30%' }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled
                    />
                </Box>
                <TextField label="Andares" fullWidth />
                <TextField select label="Beiral" fullWidth>
                    {options.beiral.map(option => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
        </Box>
    )
}